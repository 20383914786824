import { IContentElement, IScoredResponse } from "src/app/ui-testrunner/models";
import { IContentElementImage } from "../element-render-image/model";

export const passageEditInfo = {
  editExcludeFields: [],
  editTextFields: ['text'],
  editKeyFieldsToShow: ['text']
}
 

export const PASSAGE_LIN_COUNT_INTERVAL_DEFAULT_SYS = 5;

export enum PassageCounterType { 
    LINE = 'LINE',
    PARAGRAPH = 'PARAGRAPH',
    NONE = 'NONE',
}

export interface IContentElementPassage extends IContentElement {
    text: string,
    images?: {
        id:number, 
        el:IContentElementImage,
        alignment?:'left' | 'right' | 'center' | 'none'
    }[],
    paragraphStyles?: {
        id:number, 
        tabs:{sizeEm:number}[]
    }[],
    counterType: PassageCounterType,
    counterAlignment?: 'left' | 'right',
    lineCountInterval: number,
    isLineCountSkipBlank?: boolean,
    textAlignment: 'left' | 'center' | 'right',
}
