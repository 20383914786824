import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { DictionaryEN } from './controllers/dictionary-en';
import { DictionaryFR } from './controllers/dictionary-fr';
import { ThesaurusDataEntry, ThesaurusEntry, WordView } from './model';
import { IMenuTabConfig } from 'src/app/ui-partial/menu-bar/menu-bar.component';

export interface SelectedWord {
  word: string,
  json: any,
  isActive: boolean,
  thesaurus_json?: ThesaurusEntry[];
}

enum DICTIONARY_LOG_TYPES {
  DICTIONARY_SEARCH_SUGGESTION = 'DICTIONARY_SEARCH_SUGGESTION',
  DICTIONARY_SEARCH_KEYWORD = 'DICTIONARY_SEARCH_KEYWORD'
}

@Component({
  selector: 'widget-dictionary',
  templateUrl: './widget-dictionary.component.html',
  styleUrls: ['./widget-dictionary.component.scss']
})
export class WidgetDictionaryComponent implements OnInit, OnDestroy {
  
  @Input() zoom:number;
  @Input() isZoomed:boolean;
  @Input() isShowSynonyms?: boolean;

  wordViews: IMenuTabConfig<WordView>[];

  constructor(
    public lang: LangService,
    private auth: AuthService,
    private route: RoutesService,
  ) { }

  input;
  suggestions: [];
  selectedWord: SelectedWord;
  dictionaryManager: DictionaryEN | DictionaryFR;
  currentWordDefinition;
  activeWordView: WordView;

  ngOnInit() {
    this.selectedWord = {
      word: '',
      json: undefined,
      isActive: false
    }
    
    this.wordViews = [
      {id: WordView.DEFINITION, caption: 'Dictionary'},
    ]
    if (this.isShowSynonyms){
      this.wordViews.push({id: WordView.THESAURUS, caption: 'Thesaurus'});
    }
    this.activeWordView = WordView.DEFINITION;

    switch(this.lang.getCurrentLanguage()){
      case 'en':
        this.dictionaryManager = new DictionaryEN(this.lang, this.route, this.auth);
        return;
      case 'fr':
        this.dictionaryManager = new DictionaryFR(this.lang, this.route, this.auth);
        return;
      default:
        this.dictionaryManager = new DictionaryEN(this.lang, this.route, this.auth);
    } 
  }

  ngAfterViewInit(){}
  ngOnDestroy() {}

  isLoadingSuggestions: boolean = false;
  showSearchInput: boolean = false;
  currentUserInput: string = '';
  onUserInput(e: KeyboardEvent){

    let userData = (<HTMLInputElement>e.target).value; //user enetered data
    this.currentUserInput = userData;

    if (e.key && e.key == 'Enter'){
      this.getSuggestions(userData)
    }

    if(!userData || userData == ''){
      this.showSearchInput = false;
    }
  }

  getSuggestions(userInput?: string){
    this.dictionaryManager.selectedWord = undefined;
    this.suggestions = [];

    if(!userInput || userInput === ''){
      this.isLoadingSuggestions = false;
      this.showSearchInput = false;
      return;
    }

    const searchWord = userInput ? userInput : this.currentUserInput;
    this.isLoadingSuggestions = true;
    this.showSearchInput = true;

    this.dictionaryManager.getSuggestions(searchWord).then((res => {
      this.logDictionarySearch(DICTIONARY_LOG_TYPES.DICTIONARY_SEARCH_SUGGESTION, searchWord, res);
      this.isLoadingSuggestions = false;
      this.suggestions = res.map(suggestion => suggestion.word);
      const searchWordCandidates = res.filter(suggestion => suggestion.is_searched).map(suggestion => suggestion.word); // not ideal
      const actualSearchWord = searchWordCandidates.filter(word => word === searchWord).length > 0 ? searchWord : searchWordCandidates[0] || searchWord;
      const exactWordMatch = this.suggestions.filter(suggestion => suggestion === actualSearchWord)[0];
      if (exactWordMatch){
        this.selectWord(exactWordMatch);
        return;
      }
      
    })).catch((error)=>{
      this.isLoadingSuggestions = false;
      console.error('Error retrieving data');
    })
  }

  selectWord(suggestion: string){
    this.dictionaryManager.getWordData(suggestion).then(res => {
      try {
        this.logDictionarySearch(DICTIONARY_LOG_TYPES.DICTIONARY_SEARCH_KEYWORD, suggestion, res);
        const processedJSON = this.dictionaryManager.preprocessResponseRawJSON(res[0].raw_json);
        const wordDictData_json = JSON.parse(processedJSON)
        this.dictionaryManager.selectedWord = {
          word: suggestion,
          json: wordDictData_json,
          isActive: true,
          thesaurus_json: res[0].thesaurus_raw_json ? JSON.parse(res[0].thesaurus_raw_json) : undefined
        }

        this.showSearchInput = false;
        this.currentWordDefinition = this.dictionaryManager.getCurrentWordDefinition();
      } catch (error) {
        console.log(error)
      }
    })
  }


  selectWordView(view: WordView) {
    this.activeWordView = view;
  }

  isViewingDefinition(){
    return this.activeWordView === WordView.DEFINITION;
  }

  isViewingThesaurus(){
    return this.activeWordView === WordView.THESAURUS;
  }

  getGrammaticalCategories(word_thesaurus_entry: ThesaurusEntry){
    return word_thesaurus_entry.data.grammaticalCategories
  }

  getPhraseGroups(word_thesaurus_entry: ThesaurusEntry){
    return word_thesaurus_entry.data.phraseGroups
  }

  isActiveId(id:string){ return this.activeWordView === id; }

  currentSelectedWordHasThesaurus(){
    const thesaurusData = this.dictionaryManager.selectedWord.thesaurus_json;

    return thesaurusData 
    && ((thesaurusData.filter(thesaurusDataEntry => thesaurusDataEntry.data.grammaticalCategories && thesaurusDataEntry.data.grammaticalCategories.filter(entry => entry.senseCategories != undefined || entry.phraseGroups != undefined).length > 0).length > 0)
    || (thesaurusData.filter(thesaurusDataEntry => thesaurusDataEntry.data.phraseGroups && thesaurusDataEntry.data.phraseGroups.filter(entry => entry.phrase != undefined).length > 0).length > 0))
  }

  getDefPhrase_FR(def){
    if (def['ZONE-ADRESSE-LOCUTION'] && def['ZONE-ADRESSE-LOCUTION']['ADRESSE-LOCUTION']){
      let phrase = def['ZONE-ADRESSE-LOCUTION']['ADRESSE-LOCUTION'];
      phrase = phrase.replace(/,\s*$/, "");
      return phrase;
    }

    return undefined;
  }

  logDictionarySearch(logType: DICTIONARY_LOG_TYPES, searchWord: string, result: string){
    this.auth.apiCreate(this.route.LOG, {
      slug: logType,
      data: {
        uid: this.auth.getUid(),
        search_word: searchWord,
        dictionary_type: this.lang.getCurrentLanguage() == 'en' ? 'Collins' : 'Larousse',
        responses: result
      }
    })
  }
}
