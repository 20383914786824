<div *ngIf="isLoaded" class="chart">
  <div *ngIf="!isDrillDown; else drilldown" class="graph-title">Class Performance</div>
  <ng-template class="graph-title" #drilldown class="graph-title">{{category}}</ng-template>
  <div>
    <button class="button" *ngIf="isDrillDown" (click)="showDefaultChart()">Back</button>
  </div>
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [yaxis]="chartOptions.yaxis"
      [legend]="chartOptions.legend"
      [fill]="chartOptions.fill"
      [stroke]="chartOptions.stroke"
      [tooltip]="chartOptions.tooltip"
      [xaxis]="chartOptions.xaxis"
    ></apx-chart>
  </div>
  