import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { PageModalController, PageModalService } from 'src/app/ui-partial/page-modal.service';
import { GridApi } from 'ag-grid-community';
import { LoginGuardService } from '../../api/login-guard.service';
import { RoutesService } from '../../api/routes.service';
import { mtz } from 'src/app/core/util/moment';
import { LangService } from 'src/app/core/lang.service';

interface IDataFrame {
  records: any[],
  title: string,
  slug: string,
  retrievalLog?: {started_on:string}[],
  gridOptions: {
    columnDefs: IColumnDef[];
    defaultColDef: IDefaultColDef;
    api?:any // added by ag-grid
  }
}
interface IColumnDef {
  headerName: string;
  field: string;
  width?: number;
  checkboxSelection?: boolean;
}
interface IDefaultColDef {
  filter: boolean;
  sortable: boolean;
  resizable: boolean;
}

@Component({
  selector: 'panel-tw-summary',
  templateUrl: './panel-tw-summary.component.html',
  styleUrls: ['./panel-tw-summary.component.scss']
})
export class PanelTwSummaryComponent implements OnInit {

  @Input() testWindowId:number; // this is only used to track changes, central services is the source of truth so this never actually gets fed in anywhere
  @Input() roleContext:any;

  constructor(
    private auth:AuthService,
    private loginGuard: LoginGuardService,
    private routes: RoutesService,
    private lang: LangService,
  ) { }

  dataFrames:IDataFrame[]
  dfInitMap:Map<string, boolean> = new Map();
  gridApi;
  
  isLoading = true
  isLoadingInProgress = false
  selectedDf:string;

  ngOnInit(): void {
    this.loadData();
  }

  getFilteredRowCount(df:any) {
    if (df.gridOptions.api){
      let count = df.gridOptions.api.getDisplayedRowCount();
      console.log('count', count)
      return count + ' records';
    }
  }

  isDfSelectedOrInited(slug:string){
    return this.isDfSelected(slug) || this.dfInitMap.get(slug);
  }

  isDfSelected(slug:string){
    return (this.selectedDf === slug);
  }

  selectDf(slug:string){
    this.selectedDf = slug;
    this.dfInitMap.set(slug, true);
  }

  getExportStartDate(df:IDataFrame){
    if (df.retrievalLog){
      const retrievalLogFirst = df.retrievalLog[0];
      return retrievalLogFirst.started_on;
    }
    return ''
  }
  getExportFormattedDate(df:IDataFrame){
    const started_on = this.getExportStartDate(df);
    if (started_on){
      return mtz(started_on).format(this.lang.tra('datefmt_timestamp'))
    }
    return ''
  }

  exportCsv(df:IDataFrame){
    df.gridOptions.api.exportDataAsCsv(df.title);
  }

  ngOnChanges(changes){
    if(changes.testWindowId){
      this.loadData();
    }
  }

  dataLoadOptions = {
    includeAsmtSample: false,
    focusAsmtSwapRisk: false,
  }

  isStatsReloadTriggering:boolean
  isStatsReloadTriggered:boolean
  async updateTwStats(){
    this.isStatsReloadTriggered = false
    this.isStatsReloadTriggering = true
    this.auth.apiCreate('public/data-exporter/data-export', {
      testWindowId: this.testWindowId, 
      jobName: 'tc-attempts'
    }, {
      query: {
        ... this.roleContext,
      }
    })
    this.isStatsReloadTriggering = false
    this.isStatsReloadTriggered = true
    
  }

  async loadData(){
    if (!this.isLoadingInProgress ){
      this.isLoading = true;
      this.isLoadingInProgress = true;
      const prefDfSelected = this.selectedDf;
      this.selectedDf = null;
      this.dfInitMap = new Map();
      this.auth.apiGet(this.routes.TEST_CTRL_SUMMARY, this.testWindowId, {
        query: {
          includeAsmtSample:  this.dataLoadOptions.includeAsmtSample ? '1' : '',
          focusAsmtSwapRisk:  this.dataLoadOptions.focusAsmtSwapRisk ? '1' : '',
          ... this.roleContext,
        }
      }).then((dataFrames) => {
        this.dataFrames = dataFrames;
        this.isLoading = false;
        this.isLoadingInProgress = false;
        if (prefDfSelected){
          this.selectDf(prefDfSelected)
        }
      }).catch((e)=>{
        if(e.message == "GROUP_ROLE_REQ_FOR_ROUTE"){
          this.loginGuard.quickPopup("This account no longer has permission for this operation. Please contact support.")
        }
        this.isLoading = false;
        this.isLoadingInProgress = false;
        if (prefDfSelected){
          this.selectDf(prefDfSelected)
        }
      })
    }
  }
}
