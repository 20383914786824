<ngx-charts-bar-vertical
        *ngIf="isLoaded && colorScheme; else noColorScheme"
        [scheme]="colorScheme"
        [view]="width && height ? [width, height] : undefined"
        [results]="data"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="false"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [barPadding]="32"
        (select)="onSelect($event)"
        [roundEdges]="false">
        <ng-template #tooltipTemplate let-model="model">
                <div>
                        <span>{{ ((model.value / sumValue) * 100).toFixed(2) }}% of Students </span>
                </div>
        </ng-template>
</ngx-charts-bar-vertical>
<ng-template #noColorScheme>
        <ngx-charts-bar-vertical
                *ngIf="isLoaded"
                [view]="width && height ? [width, height] : undefined"
                [results]="data"
                [gradient]="gradient"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legend]="false"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [barPadding]="32"
                (select)="onSelect($event)"
                [roundEdges]="false">
                <ng-template #tooltipTemplate let-model="model">
                        <div>
                                <span>{{ ((model.value / sumValue) * 100).toFixed(2) }}% of Students </span>
                        </div>
                </ng-template>
        </ngx-charts-bar-vertical>
</ng-template>



