import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import feathers, { HookContext } from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import { BehaviorSubject, Subject } from 'rxjs';
import Sockette from 'sockette';
import { AccountType } from '../constants/account-types';
import { WhitelabelService } from '../domain/whitelabel.service';
import { API_ADDRESS_QC1 } from './api-addr/l';
import * as moment from 'moment-timezone';


const auth = require('@feathersjs/authentication-client');

export const F_ERR_MSG__FAILED_TO_FETCH = 'Failed to fetch'; // if feather is unable to connect to the server
export const F_ERR_MSG__INVALID_LOGIN = 'Invalid login';
export const F_ERR_MSG__REAUTH_NO_TOKEN = 'No accessToken found in storage';
export const F_ERR_MSG__RELOGIN = 'jwt expired';

const TOKEN_REFRESH_INTERVAL = 10 * 60 * 1000;

interface IAuthRes {
  accessToken: string,
  user?: IUserInfoCore,
  authentication?: {
    payload: IUserInfoCore
  }
}
interface IApiFindQuery {
  query?: {
    $limit?: number,
    [key: string]: any;
  }
  [key: string]: any;
}
interface IUploadResponse {
  success: boolean,
  filePath?: string,
  url?: string,
  urls? : string[]
}

export interface AccountTypeRecord {
  display_order   : number,
  route_template  : string,
  caption         : string,
  color           : string,
  group_id        : string,
  account_type    : string,
  s_name?         : string,
  s_foreign_id?   : string,
  sd_name?        : string,
  sd_foreign_id?  : string,
  route?          : string,
  ur_id?          : string,
  is_conf_req?    : string,
  is_confirmed?   : string
}
export interface IUserInfoCore {
  email: string,
  uid: number,
  accountType: AccountType,
  accountTypes: AccountTypeRecord[],
  accountId: number,
  accountInfo: {
    institutionId?: number // test admins only
  },
  // roles?: string[],
  firstName: string,
  lastName: string,
  showComments?: boolean;
  imgURL?:string,
  isTotpUser: string
}

export interface IUserInfo extends IUserInfoCore {
  accessToken: string,
  lang?: string,
  dashboardType?: string,
  sch_class_group_id?: number,
  sch_class_id?: number,
  sch_class_group_type?: string,
  linear?: number,
  assistive_tech?: string,
  test_window_id?: number,
  assessmentSlug?: string,
  first_name?: string,
  middle_name?: string,
  last_name?: string,
  studentOenOrSasn?: string,
  isSasnLogin?: number,
  assessmentType?: string
}

export const DB_TIMEZONE = 'Z';

export const getFrontendDomain = () => {
  return window.location.origin + '/';
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private api = <any>feathers();
  private restClient = <any>rest(this.whitelabel.getApiAddress());
  private _user:IUserInfo;
  public userSub:BehaviorSubject<IUserInfo> = new BehaviorSubject(null);
  private reauthCompletedSub:BehaviorSubject<boolean> = new BehaviorSubject(false);
  private apiNetFail:BehaviorSubject<boolean> = new BehaviorSubject(false); // gets overridden upon registration
  private apiAuthExpire:Subject<boolean> = new Subject(); // if you want to use BehaviorSubject, need to be diligent about setting this to false whenever the user is re-authenticated
  private isLoggedIn:boolean = false;
  public hasAutoLoggedOut: boolean = false;
  public isLoggingIn:boolean;
  public isLoggingInAs:BehaviorSubject<boolean> = new BehaviorSubject(false);
  public totpAuthenticationFailed: boolean = false;
  private credentials: Array<string> = [];
  public credentialsSub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private QRCodeDataURL: Array<string> = [];
  public activeKioskPassword:string; // unclean
  private jwtExpired = false;
  public userSwitched: BehaviorSubject<boolean> = new BehaviorSubject(false);

  authWebSocket: any;
  private readonly authWebSocketURI: string = 'wss://mnomt58qw8.execute-api.ca-central-1.amazonaws.com/production';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private whitelabel: WhitelabelService,
  ) {
    this.api.configure(this.restClient.fetch(window['fetch']));
    this.api.configure(auth({ storage: window['localStorage'] }));
    this.api.service('authentication').hooks({
      'before': {
        'all': [async (context: HookContext) => {
          context.params = this.populateUid(context.params)
          return context;
        }],
      },
    })
    this.reauth();
    setInterval(this.refreshRefreshToken, TOKEN_REFRESH_INTERVAL);
    if(!this.activeKioskPassword){
      this.activeKioskPassword = this.getItemWithExpiration('kioskPassword');
    }
    // this.clearLocalSession()
  }

  ngOnDestroy() {
    if (this.authWebSocket) this.authWebSocket.close();
  }

  public user() {
    return this.userSub;
  }
  
  public userSwitchSub(){
    return this.userSwitched
  }
  
  public resetSwitchSub(){
    this.userSwitched.next(false)
  }

  public getCredentialsSub()
  {
    return this.credentialsSub;
  }

  public getQRCodeDataURL(): Array<string>
  {
    return this.QRCodeDataURL;
  }

  public setQRCodeDataURL(QRCodeDataURL: Array<string>): void
  {
    this.QRCodeDataURL = QRCodeDataURL;
  }

  public getCredentials(): Array<string>
  {
    return this.credentials;
  }

  public setCredentials(credentials: Array<string>): void
  {
    this.credentials = credentials;
  }

  public getJwtExpired(): boolean
  {
    return this.jwtExpired;
  }

  public formatDateOnly(dateTime: string, inputDateTimeTZ: string = null, lang: string = 'en'): string{
    if(dateTime == null || dateTime == ""){
      return "";
    }
    const inputDateTime = inputDateTimeTZ == null ? moment(dateTime).utc() : moment.tz(dateTime, inputDateTimeTZ);
    const displayTZ = this.whitelabel.getTimeZone();

    return inputDateTime.tz(displayTZ).format("YYYYMMDD");
  }

  public formatDateForWhitelabel(dateTime: string, inputDateTimeTZ: string = null, lang: string = 'en'): string
  {
    // todo:generalize 
    
    // dateTime is in UTC time if inputDateTimeTZ is null
    if (dateTime == null || dateTime == "")
    {
      return "";
    }

    const inputDateTime = inputDateTimeTZ == null ? moment(dateTime).utc() : moment.tz(dateTime, inputDateTimeTZ);
    const displayTZ = this.whitelabel.getTimeZone();
    let displayTZAbbr = this.whitelabel.getTimezoneAbbr();
    if(this.whitelabel.isABED() && lang === "fr"){
     displayTZAbbr = "HNR"
    }
    return inputDateTime.tz(displayTZ).format(this.whitelabel.isABED() && lang === "fr" ? "D MMMM YYYY H [h] m" : "MMMM Do, YYYY h:mm A") + " " + displayTZAbbr;
  }

  public convertUTCToWhitelabelTZ(UTCDateTime: string): moment.Moment 
  {
    // UTCDateTime comes straight from the DB
    // takes DT as string input, returns converted moment
    const inputDTMoment = moment(UTCDateTime).utc();
    const whiteLabelTZ = this.whitelabel.getTimeZone();
    const convertedMoment = inputDTMoment.tz(whiteLabelTZ);
    return convertedMoment;
  }

  public formatDateTimeForLocalTimezone(dateTime: moment.Moment): string
  {
    if (dateTime == null)
    {
      return "";
    }

    return dateTime.tz(this.getUserLocalTimeZone()).format("MMMM Do, YYYY h:mm A") 
    + " " + this.getUserLocalTimezoneAbb();
  }

  public getUserLocalTimeZone(): string
  {
    // e.g. returns America/Vancouver, America/Edmonton, America/Toronto
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public getUserLocalTimezoneAbb(): string
  {
    // e.g. returns MST, EST, PST
    return new Date().toLocaleTimeString('en-us', {timeZoneName:'short'}).split(' ')[2];
  }


  getDashboardRoute(lang:string){
    if (this._user && this._user.accountType){
      if (this._user.accountType === 'educator'){
        return `/${lang}/${this._user.accountType}/classrooms`
      }
      if (this._user.accountType === AccountType.MINISTRY_ADMIN || this._user.accountType === AccountType.BC_FSA_MINISTRY_ADMIN) {
        return `/${lang}/ministry-admin/bc-fsa/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_GRAD_MINISTRY_ADMIN) {
        return `/${lang}/ministry-admin/bc-grad/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_GRAD_SCHOOL_ADMIN) {
        return `/${lang}/school-admin/bc-grad/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_FSA_DIST_ADMIN) {
        return `/${lang}/dist-admin/bc-fsa/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_FSA_SCHOOL_ADMIN) {
        return `/${lang}/school-admin/bc-fsa/dashboard`;
      }
      if (this._user.accountType === AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY) {
        return `/${lang}/school-admin/bc-fsa/dashboard`;
      }
      return `/${lang}/${this._user.accountType}/dashboard`
    }
    return `/`
  }

  getTimezone() {
    return 'America/Toronto';
  }

  public userIsStudent() {
    return this._user?.accountType === AccountType.STUDENT;
  }

  public checkUserAccountType(accountType: AccountType): boolean {
    if (!this._user) return false;
    return this._user.accountType === accountType;
  }

  public getDomain() {
    return window.location.origin;
  }

  public getReauthCompletedSub() {
    return this.reauthCompletedSub;
  }

  getApiAuthExpire() {
    this.apiAuthExpire;
  }

  public checkLoggedIn() {
    return this.userSub.value && this.isLoggedIn ? true : false;
  }

  u() {
    return this.user().value;
  }

  public isQcBranch() {
    return (this.whitelabel.getApiAddress() === API_ADDRESS_QC1)
  }

  /**
   * This function is update so that not only does it set the kiosk password 
   * as a local variable and stores it in local storage for 10 hours. 
   * With that we minimize the chances of losing track of the KIOSK password
   * @param kioskPassword The KIOSK password 
   */
  public setKioskPassword(kioskPassword: string) {
    this.activeKioskPassword = kioskPassword;
    const tenHours = 10 * 60 * 60 * 1000; // 10 hours in milliseconds
    const expirationTime = new Date().getTime() + tenHours; //10 hours from now
    const itemToStore = {
      value: kioskPassword,
      expiration: expirationTime
    };
    localStorage.setItem('kioskPassword', JSON.stringify(itemToStore));
  }

  /**
   * This function returns the active KIOSK password. 
   * And if it doesn't find it in the activeKioskPassword it checks the local storage
   * @returns KIOSK password
   */
  public getKioskPassword() {
    return this.activeKioskPassword;
  }

  /**
   * This function checks the local storage and confirms that 
   * the local storage item is only active for as long as we need it
   * If the function gets triggered after the item is supposed to expire, 
   * it removes it from the local storage and returns null
   * @param key the key of the local storage item'
   * @returns the value of the localstorage item if it is not expired
   */
  private getItemWithExpiration(key: string) {
    const item = localStorage.getItem(key);
    if (item) {
      const parsedItem = JSON.parse(item);
      const currentTime = new Date().getTime();
      if (currentTime < parsedItem.expiration) {
        return parsedItem.value;
      } else {
        // The item has expired, so remove it
        localStorage.removeItem(key);
      }
    }
    return null;
  }

  public registerNoApiNetSub(apiNetFail: BehaviorSubject<boolean>) {
    apiNetFail.next(this.apiNetFail.getValue()); // usually this first one will only be around for a fraction of a second. in any case, it is private so nothing outside of this calss should be subscribing to it becaus it will be wiped out
    this.apiNetFail = apiNetFail;
  }
  private clearNetworkError = (res: any) => {
    this.apiNetFail.next(false);
    return res;
  }
  private catchNetworkError = (e) => {
    // console.log('catch net error', e.message)

    if (e.code === 500) {
      this.apiNetFail.next(true);
    }
    else if (e.message === F_ERR_MSG__RELOGIN) {
      this.apiAuthExpire.next(true); // not being used at the moment
      this.clearUser();
      this.isLoggedIn = false;
      this.jwtExpired = true;
    }
    else if (e.message === F_ERR_MSG__FAILED_TO_FETCH) {
      this.apiNetFail.next(true);
    }
    else if (e.message === F_ERR_MSG__FAILED_TO_FETCH) {
      this.apiNetFail.next(true);
    }
    throw e;
  }

  private clearLocalSession(){
    this.api.authentication.removeAccessToken();
  }

  public async getLatestUserRoles(){
    if (this._user){
      this._user.accountTypes = await this.apiFind('public/auth/my-role-types')
    }
  }

  private refreshUserInfo = async (res: IAuthRes, isSilent: boolean = false, reloadUserRoles: boolean = false) => {
    const newUserPayload = (res.user || res.authentication.payload);
    let isNewAuth = false;
    if (!this._user || !newUserPayload || (this._user.uid != newUserPayload.uid) ){
      isNewAuth = true;
    }
    this._user = {
      ... newUserPayload,
      accessToken: res.accessToken,
    }
        if (isNewAuth || reloadUserRoles){
      await this.getLatestUserRoles()
    }

    if (!isSilent) {
      this.userSub.next(this._user)
    }
    
    this.storeRefreshInfo(res);
   
    return res;
  }

  // temp: wrong place, just rushing
  isStudentIntervalInitialized
  initStudentInterval() {
    if (!this.isStudentIntervalInitialized) {
      this.isStudentIntervalInitialized = true;
      setInterval(this.refreshStudentData, 5 * 1000);
      this.refreshStudentData();
    }
  }
  refreshStudentData = () => {
    return this.apiCreate('public/educator/students', { type: 'STUDENT_INTERVAL' }).then()
  }

  private getToken = (res: IAuthRes) => {
    return res.accessToken;
  }

  getDisplayName(){
    if (this._user){
      return this.renderName(this._user.firstName, this._user.lastName);
    }
    return 'Not Logged In';
  }

  renderName(firstName, lastName) {
    return firstName+' '+lastName
  }

  getUid(){
    return this._user?.uid || -1;
  }
  getFirstName(){
    if (this._user){
      return this._user.firstName
    }
    return 'Not Logged In';
  }

  getLastName(){
    if (this._user){
      return this._user.lastName
    }
    return 'Not Logged In';
  }
  
  myUID() { // to deprecate
    if(this._user) {
      return this._user.uid;
    }
    else{
      return 0;
    }
  }

  myAccountType() {
    if(this._user) {
      return this._user.accountType;
    }
    else{
      return '';
    }
  }
  


  uploadFile(file: File | Blob, filename: string, purpose: string = '_general', isPermaLink: boolean = false): Promise<IUploadResponse> {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';
    formData.append('form_upload', file, filename);
    formData.append('uid', '' + uid);
    console.log("started uploading file with user id", uid);
    formData.append('purpose', purpose);
    formData.append('isPermaLink', isPermaLink ? '1' : '0');
    formData.append('jwt', jwt);
    return this.httpClient
      .post(this.whitelabel.getApiAddress() + '/upload', formData, { headers: this.populateUid()['headers']})
      .toPromise()
      .then((res) => {
        console.log(res)
        return <IUploadResponse>res
      } );
  }

  excelToJson(file: File) {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';

    formData.append('form_upload', file);
    formData.append('uid', '' + uid);
    formData.append('jwt', jwt);
    return this.httpClient
      .post(this.whitelabel.getApiAddress() + '/convert-xlsx-to-json', formData, { headers: this.populateUid()['headers']})
      .toPromise()
  }


  public refreshToken = (): Promise<any> => {
    return this.api
      .reAuthenticate(true)
      .then(userInfo => this.refreshUserInfo(userInfo, true))
      .then(function (result) {
        console.log('reAuth success', result)
        return result;
      }).catch(function (err) {
        console.log('reAuth err', err);
        throw err;
      })
  }

  reportFilePath(path: string, raw: string, filename: string) {
    return this.whitelabel.getApiAddress() + '/' + encodeURIComponent(filename) + '.xlsx?uid=' + this._user?.uid + '&path=' + path + '&rawParams=' + encodeURIComponent(raw) + '&jwt=' + this._user?.accessToken;
  }

  textFilePath(path: string, raw: string, filename: string, fileExtension: string) {
    return this.whitelabel.getApiAddress() + '/' + encodeURIComponent(filename) + '.' + fileExtension + '?uid=' + this._user?.uid + '&path=' + path + '&rawParams=' + encodeURIComponent(raw) + '&jwt=' + this._user?.accessToken;
  }

  public refreshRefreshToken = () : Promise<any> => {
    if (this.hasAutoLoggedOut || !this.isLoggedIn) return null;
    return this.api
    .authenticate({
      strategy: 'local',
      action: "refresh",
      refresh_token: this.getRefreshToken()
    })
    .then((res)=> {
      if(res.user){
        const prevUid = this._user?.uid;
        const currentUid = res.user.uid;
        const currentAccountType = res.user.accountType;   
        if(prevUid !== currentUid && currentAccountType !== 'student'){
          this.refreshUserInfo(res);
          this.userSwitched.next(true);
        }
      }
      this.storeRefreshInfo(res)
      this.isLoggedIn = true;
      this.jwtExpired = false;
    })
    .catch(this.catchNetworkError)
  }
  
  private getRefreshToken(){
    return localStorage.getItem('refresh-token')
  }

  private storeRefreshInfo(res){
    if (res && res.refreshToken) {
      localStorage.setItem('refresh-token',res.refreshToken);
    } 
  }


  dataFilePath(path:string, options:{[key:string]: string | number}){
    let optionStr = '';
    if (options) {
      Object.keys(options).forEach(param => optionStr += '&' + param + '=' + options[param]);
    }
    return this.whitelabel.getApiAddress() + '/data-frame.csv?uid=' + this._user?.uid + '&path=' + path + optionStr + '&jwt=' + this._user?.accessToken;
  }
  
  async unauthenticatedGet(endpoint:string, options?:any){
    return this.httpClient.get(this.whitelabel.getApiAddress() + '/' + endpoint,{
      params: (options || {}).params,
      headers: this.populateUid()['headers']
    }).toPromise();
  }

  jsonToExcel(records: any[], fileName) {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';

    formData.append('uid', '' + uid);
    formData.append('jwt', jwt);
    const jsonRecords = JSON.stringify(records);
    const recordsBlob = new Blob([jsonRecords], { type: 'application/json' });
    formData.append('form_upload', recordsBlob);
    formData.append('fileName', fileName)
    return this.httpClient.post(this.whitelabel.getApiAddress() + '/upload-json-as-xlsx', formData, {
      headers: this.populateUid()['headers']
    }).toPromise();
  }

  getCsvData(jsonData) {
    let csv = '';
    // Get the headers
    let headers = Object.keys(jsonData[0]);
    csv += headers.join(',') + '\n';
    // Add the data
    jsonData.forEach(function (row) {
        let data = headers.map(header => {
          let val = row[header]
          if (val === null){
            return ''
          }
          return JSON.stringify(val)
        }).join(','); // Add JSON.stringify statement
        csv += data + '\n';
    });
    return csv;
  }

  async jsonToCsv(route: string, query: {}, fileName: string, nullToBlank = true){
    // todo:SECURITY get/sent scoring window group id
    return await this.apiFind(route, {query: query}).then(jsonData => {
      let csvData = this.getCsvData(jsonData); // Add .items.data
      // Create a CSV file and allow the user to download it
      let blob = new Blob([csvData], { type: 'text/csv' });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.csv`;
      document.body.appendChild(a);
      a.click();
    }).catch((err) => {
      throw new Error(err);
    });
  }


  // public refreshToken = () =>{
  // if (this._user && !this.isLoggingIn){
  //   this.apiFind('/rest/auth/refresh-token', {})
  //     .then(refreshed => {
  //       this.api.authentication.setAccessToken(refreshed[0]);
  //       // setTimeout(()=>{
  //       //   console.log(['refreshToken', refreshed[0], this.api.authentication.getAccessToken()])
  //       // }, 1000)
  //     })
  //     .catch(()=>{
  //       console.log('soft fail')
  //       return this.api
  //         .reAuthenticate(true)
  //         .then(res => this.refreshUserInfo(res, true))
  //     })
  //     .catch(e =>{
  //       console.log('hard fail')
  //       return this.clearUser(e);
  //     }
  // }
  // }

  clearUser = (e?: any) => {
    this.userSub.next(null);
    this._user = null;
  }

  public reauth(): Promise<any> {
    return this.api
      .reAuthenticate()
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => this.isLoggedIn = true)
      .then(() => this.jwtExpired = false)
      .then( res => { this.reauthCompletedSub.next(true); return res })
      .then( () => {
       // this.initAuthWebSocket();
      })
      .catch(e => { this.reauthCompletedSub.next(true); return e })
      .catch(this.catchNetworkError)
      .catch(e => { if (e.message !== F_ERR_MSG__REAUTH_NO_TOKEN) { throw e; } }) // no token is not really an error since we are not checking for it in the first place
  }

  public loginWithKey(key: string): Promise<any> {
    this.isLoggingIn = true;
    this.hasAutoLoggedOut = false;

    return this.api.authenticate({
      strategy: 'loginMarkerKey',
      secret_key: key
    }).then(this.clearNetworkError)
    .then(this.refreshUserInfo)
    .then(() => this.isLoggedIn = true)
    .then(() => this.jwtExpired = false)
    .then(() => this.isLoggingIn = false)
    .then(()=> {
      this.isLoggingIn = false;
      //this.initAuthWebSocket();
    })
    .catch(this.catchNetworkError)
    .catch( err => { this.isLoggingIn = false; throw err; })
  }

  public login(email:string, password:string, totpToken?: string) : Promise<any> {
    this.isLoggingIn = true;
    this.hasAutoLoggedOut = false;
    let authStrategy: string;
    
    if (!totpToken)
    {
      authStrategy = "local"; 
    }

    else 
    {
      authStrategy = "totp";
    }

    let authRes = this.api
      .authenticate({
        strategy: authStrategy,
        email,
        password,
        totpToken
      });
      return authRes
      //.then((authInfo) => console.log(authInfo))
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => this.isLoggedIn = true)
      .then(() => this.jwtExpired = false)
      .then(()=> 
      {
        this.isLoggingIn = false;
        return authRes;
        //this.initAuthWebSocket();
      })
      .catch(this.catchNetworkError)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  public loginStudentGrad(stage: 1 | 2, studentNumber: string, accessCode: string, districtSlug?: string, schoolSlug?: string): Promise<any> {
    this.isLoggingIn = true;
    if(this.whitelabel.getSiteFlag('IS_BCED')){
        if (stage === 1) {
          return this.apiCreate('public/student/name-initial', {
            studentNumber,
            accessCode,
            districtSlug,
            schoolSlug,
            assessmentType: 'grad',
          }, {
            query: {
              action: 'get last name',
            }
          }).then(data => {
            return data;
          });
        } else {
          return this.api
            .authenticate({
              strategy: 'loginSessionKey',
              stage,
              studentNumber,
              accessCode,
              districtSlug,
              schoolSlug,
              assessmentType: 'grad',
            })
            .then(this.clearNetworkError)
            .then(this.refreshUserInfo)
            .catch(this.catchNetworkError)
            .catch(err => { this.isLoggingIn = false; throw err; })
        }
    } else{
      this.hasAutoLoggedOut = false;

      return this.api
        .authenticate({
          strategy: 'loginKey',
          studentNumber,
          accessCode
        })
        .then(this.clearNetworkError)
        .then(this.refreshUserInfo)
        .then(() => this.isLoggedIn = true)
        .then(() => this.jwtExpired = false)
        .then(() => this.isLoggingIn = false)
        .catch(this.catchNetworkError)
        .catch( err => { this.isLoggingIn = false; throw err; })
    } 
  }

  public loginStudentFsa(stage: 1 | 2, studentNumber: string, accessCode: string, districtSlug?: string, schoolSlug?: string): Promise<any> {
    this.isLoggingIn = true;

    if (stage === 1) {
      return this.apiCreate('public/student/name-initial', {
        studentNumber,
        accessCode,
        districtSlug,
        schoolSlug,
        assessmentType: 'fsa',
      }, {
        query: {
          action: 'get names',
        }
      }).then(data => {
        return data;
      });
    } else {
      return this.api
        .authenticate({
          strategy: 'loginSessionKey',
          stage,
          studentNumber,
          accessCode,
          districtSlug,
          schoolSlug,
          assessmentType: 'fsa',
        })
        .then(this.clearNetworkError)
        .then(this.refreshUserInfo)
        .catch(this.catchNetworkError)
        .catch(err => { this.isLoggingIn = false; throw err; })
    }

  }

  public loginStudent(studentNumber:string, accessCode:string, isSasnLogin = 0) : Promise<any> {
    this.isLoggingIn = true;
    this.hasAutoLoggedOut = false;

    return this.api
      .authenticate({
        strategy: 'loginKey',
        studentNumber,
        accessCode,
        isSasnLogin,
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => this.isLoggedIn = true)
      .then(() => this.jwtExpired = false)
      .then(() => this.isLoggingIn = false)
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }

  public reLoginStudent(studentNumber:string, accessCode:string, isSasnLogin = 0) : Promise<any> {
    this.isLoggingIn = true;
    this.hasAutoLoggedOut = false;

    return this.api
      .authenticate({
        strategy: 'loginKey',
        studentNumber,
        accessCode,
        isSasnLogin,
      })
      .then(this.clearNetworkError)
      .then(userInfo => this.refreshUserInfo(userInfo, true))
      .then(() => this.isLoggedIn = true)
      .then(() => this.jwtExpired = false)
      .then(() => this.isLoggingIn = false)
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }

  //new login strategy since abed students are splitting into multiple types.
  public selfRegStudent(registrationPayload:{first_name:string, last_name:string, dob:string, accessCode:string, studentNumber:string,}) : Promise<any> {
    this.isLoggingIn = true;
    return this.api
      .authenticate({
        strategy: 'loginABEDKey',
        isSelfReg: true,
        registrationPayload,
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => this.isLoggedIn = true)
      .then(() => this.jwtExpired = false)
      .then(() => this.isLoggingIn = false)
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }

  public abedLoginStudent(studentNumber:string, accessCode:string, isSasnLogin = 0, dob:string) : Promise<any> {
    this.isLoggingIn = true;
    this.hasAutoLoggedOut = false;

    return this.api
      .authenticate({
        strategy: 'loginABEDKey',
        studentNumber,
        accessCode,
        isSasnLogin,
        abed_dob: dob
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => this.isLoggedIn = true)
      .then(() => this.jwtExpired = false)
      .then(() => this.isLoggingIn = false)
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }
  
  public abedReLoginStudent(studentNumber:string, accessCode:string, isSasnLogin = 0, dob:string) : Promise<any> {
    this.isLoggingIn = true;
    this.hasAutoLoggedOut = false;

    return this.api
      .authenticate({
        strategy: 'loginABEDKey',
        studentNumber,
        accessCode,
        isSasnLogin,
        abed_dob: dob
      })
      .then(this.clearNetworkError)
      .then(userInfo => this.refreshUserInfo(userInfo, true))
      .then(() => this.isLoggedIn = true)
      .then(() => this.jwtExpired = false)
      .then(() => this.isLoggingIn = false)
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }


  

  public loginAlias(id:string, secret:string) : Promise<any> {
    this.isLoggingIn = true;
    return this.api
      .authenticate({
        strategy: 'alias',
        id,
        secret
      })
      .then(this.clearNetworkError)
      .then(userInfo => this.refreshUserInfo(userInfo, false, true))
      .then(() => this.isLoggedIn = true)
      .then(() => this.jwtExpired = false)
      .then(() => this.isLoggingIn = false)
      .then(() => this.isLoggingInAs.next(true))
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }
  public resetLoginAs(){
    this.isLoggingInAs.next(false)
  }
  public loginFieldTest(studentNumber: string, accessCode: string, asmtSlug: string, selectedSchoolId: string, selectedDistrictId: string): Promise<any> {
    this.isLoggingIn = true;
    this.hasAutoLoggedOut = false;

    return this.api
      .authenticate({
        strategy: 'loginFieldTest',
        studentNumber,
        accessCode,
        asmtSlug,
        selectedSchoolId,
        selectedDistrictId,
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => this.isLoggedIn = true)
      .then(() => this.jwtExpired = false)
      .then(() => this.isLoggingIn = false)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }

  private persistPresence(userInfo) {

    return userInfo;
  }

  public getJWT(email: string, password: string): Promise<any> {
    return this.api
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then(this.getToken)
      .catch(err => { this.isLoggingIn = false; throw err; })
  }

  public logout(autoLogoutFlag?: boolean) : Promise<any> {

    if(autoLogoutFlag) this.hasAutoLoggedOut = true;
    if(this.authWebSocket) this.authWebSocket.close();
    return this.api
      .logout()
      .then(this.clearNetworkError)
      .then(this.clearUser())
      .then(() => this.isLoggedIn = false)

      .catch(e => { this.clearLocalSession(); throw e; })
      .catch(this.catchNetworkError)
  }

  private populateUid(params?: any): any{
    if (this.isLoggingIn) {
      return {
        ...params,
        headers: {
          "X-Amzn-Trace-Id": `vea-login-user=${this.getUid()}`,
        }
      }
    }

    return {
      ...params,
      headers: {
        "X-Amzn-Trace-Id": `vea-uid=${this.getUid()}`,
      }
    }
  }

  public apiGet(route: string, id: string | number, params?: any): Promise<any> {
    return this.api
      .service(route)
      .get(id, this.populateUid(params))
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiFind(route: string, params?: any): Promise<any> {
    return this.api
      .service(route)
      .find(this.populateUid(params))
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiCreate(route: string, data: any, params?: any): Promise<any> {
    return this.api
      .service(route)
      .create(data, this.populateUid(params))
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiPatch(route: string, id: string | number, data: any, params?: any): Promise<any> {
    return this.api
      .service(route)
      .patch(id, data, this.populateUid(params))
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiUpdate(route: string, id: string | number, data: any, params?: any): Promise<any> {
    return this.api
      .service(route)
      .update(id, data, this.populateUid(params))
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiRemove(route: string, id: string | number, params?: any): Promise<any> {
    return this.api
      .service(route)
      .remove(id, this.populateUid(params))
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }


  initAuthWebSocket() {
    this.authWebSocket = new Sockette(this.authWebSocketURI, {
      timeout: 10e3,
      maxAttempts: 10,
      onopen: e => {
        this.onOpenAuthWS(e);
      },
      onmessage: e => {
        // console.log("Received message: ", e);
        this.onMessageAuthWS(e);
      },
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: e => this.onCloseAuthWS(e),
      onerror: e => console.log('Error:', e)
    });
  }

  onOpenAuthWS(e) {
    if(!this.getCookie('veaSession')) {
      this.setCookie('veaSession', this.generateToken(), 1);
    }
    console.log("connecting with " + this.getCookie('veaSession'));
    this.authWebSocket.json({action: "authConnect", data:{uid: this.u().uid, veaSession:this.getCookie('veaSession')}});
  }

  onCloseAuthWS(e) {
    this.authWebSocket.json({ action: "authDisconnect" });
  }

  onMessageAuthWS(e) {
    let data;
    try {
      data = JSON.parse(e.data);
    } catch (e) {
    }

    if (!data && e.data == "KICK") {
      console.warn("Someone else may have logged into this account.");
      // alert("Someone else logged into this account. You will now be logged out.");
      // this.logout();
    }
  }

  isSchoolAdmin(accountType: AccountType): boolean {
    return [
      AccountType.BC_FSA_SCHOOL_ADMIN,
      AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY,
      AccountType.BC_GRAD_SCHOOL_ADMIN,
      AccountType.SCHOOL_ADMIN,
    ].includes(accountType);
  }

  isScoreEntrySchoolAdmin(accountType: AccountType): boolean {
    return accountType === AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY;
  }

  isScoreEntryDistrictAdmin(accountType: AccountType): boolean {
    return accountType === AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY;
  }

  isDistrictAdmin(accountType: AccountType): boolean {
    return [
      AccountType.DIST_ADMIN,
      AccountType.BC_FSA_DIST_ADMIN,
      AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY,
      AccountType.BC_GRAD_DIST_ADMIN,
    ].includes(accountType);
  }

  isMinistryAdmin(accountType: AccountType): boolean {
    return [
      AccountType.MINISTRY_ADMIN,
      AccountType.BC_GRAD_MINISTRY_ADMIN,
      AccountType.BC_FSA_MINISTRY_ADMIN,
    ].includes(accountType);
  }

  setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  } 
  getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for(var i=0;i < ca.length;i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') c = c.substring(1,c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
  }
  deleteCookie(name){
    this.setCookie(name, '', -1);
  }

  public generateToken(): string {
    let token = `${this.S4()}${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`;
    return token;
  }

  private S4(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }


  async xlsxToJSON(file: File) {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';

    formData.append('form_upload', file);
    formData.append('uid', ''+uid);
    formData.append('jwt', jwt);
    return await <any> this.httpClient
    .post(this.whitelabel.getApiAddress()+'/convert-xlsx-to-json', formData, { headers: this.populateUid()['headers'] })
    .toPromise()
    
  }

  
  async xlsxToInvitationWave(file: File, markingWindowId:number) {
    let resp = await this.xlsxToJSON(file);

    return await this.apiCreate('public/scor-lead/invite-wave', resp, { query: { confirmSpreadsheet:true, markingWindowId }});
  }

}
