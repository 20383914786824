<div *ngIf="testState && !testState.isSubmitted" class="screen-warning">
    <tra slug="error_small_screen"></tra>
</div>

<div *ngIf="!testState.isSubmitted && !isPrintMode" class="question-content-frame" [class.results-intro]="pageMode===PageMode.RESULTS_INTRO" [class.is-hi-contrast]="isHighContrast">    

    <div *ngIf="!isTestNavExpanded">
        <button class="button" (click)="toggleTestNav()">
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
    </div>
    <div class="test-nav"
        [style.font-size.em]="(getZoomValue()/defaultZoomLevel)/getScreenShrink()"
        [class.is-expanded]="isTestNavExpanded" 
        [class.is-onTop]="isTestNavOnTop"
        *ngIf="!isLeftBarDisabled()"
        [style.backgroundColor]="getFillColor()"
    >
        <div>
            <!-- <div class="collapser">
                <button class="button" (click)="toggleTestNav()">
                    <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                </button>
            </div> -->
            <div class="support" *ngIf="false && isIssueReportingEnabled">
                <button (click)="reportIssue()" audio-slug [slug]="getReportIssueSlug()">
                    <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/red-flag/1666377976032/red-flag.png">
                    <tra [slug]="getReportIssueSlug()"></tra>
                </button>
            </div>

            <div *ngIf="isExitEnabled">
                <button class="button is-small is-fullwidth" (click)="editItem()">
                    <tra slug="tr_edit_item"></tra>
                </button>
            </div>
            
            <div *ngIf="isExitEnabled">
                <button class="button is-small is-fullwidth  is-danger has-icon" (click)="textToSpeech.hi_contrast_off();exit.emit()">
                    <span class="icon"><i class="fa fa-times"></i></span>
                    <tra slug="abed_exit_preview"></tra>
                </button>
            </div>
            
            <div class="identity">
                <div class="space-between">
                    <div class="identity-name">{{testTakerName}}</div>
                    <button class="button" (click)="toggleTestNav()" *ngIf="!checkTag(KNOWN_TEST_RUNNER_TAGS.NO_LEFT_COLLAPSE)">
                        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                    </button>
                </div>
                <div *ngIf="attemptKey" class="identity-key">
                <tra slug="txt_dummy_attempt_key"></tra>: {{attemptKey}}</div>
            </div>
            
            <div *ngIf="getLogo()" class="logo-container" audio-slug [slug]="getEqaoLogoSlug()">
                <img [src]="getLogoSafe()" [alt]="lang.tra(getEqaoLogoSlug())">
            </div>
            <div *ngIf="showFPS" class="fps-frame" [class.fps-good]="currentFpsStatus === FPSStatus.GOOD" [class.fps-adequate]="currentFpsStatus === FPSStatus.ADEQUATE" [class.fps-poor]="currentFpsStatus === FPSStatus.POOR">
                <tra slug = 'sdc_const_fps'></tra>: {{currentFPS}} ({{currentFpsStatusText}}) 
            </div>
            <!-- <div class="test-title">
                <tra slug="brand_long"></tra>
            </div> -->
            
            <div *ngIf="isSectionNavDropdownEnabled && !isShowingResults" class="test-section">
                <div class="section-navigation-dropdown">
                    <button 
                        id="section-navigation-dropdown-trigger"
                        class="dropdown-trigger" 
                        (click)="toggleNavigationDropdown()" 
                        [disabled]="questionsForNavigation.length === 0"
                    >
                        <span>{{getNavDdownLabel()}}</span>
                        <i class="icon fas fa-angle-down" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-content" [class.is-collapsed]="!isSectionNavDropdownOpen">
                        <button class="dropdown-item" *ngFor="let question of questionsForNavigation" (click)="onQuestionNavigation(question)">
                            {{getNavQueAnchorLabel(question)}}
                        </button>
                    </div>

                </div>
            </div>
            <div class="test-section">
                <div *ngIf="getSidebarThumbnail()" [style.max-width.%]="isFullWidthThumbnail() ? 100 : 70"><img [src]="getSidebarThumbnail()"></div>
                <div *ngIf="showSectionDropdown() && !isShowingResults">
                    <div class="select is-fullwidth">
                        <select [(ngModel)]="sectionIdDropdownSelection" (change)="onSectionDropdownChange(sectionIdDropdownSelection)">
                            <option *ngFor="let section of testRunnerSections; let sectionIndex=index" [value]="sectionIndex">
                                {{section.caption}}
                                <!-- {{getSectionCaption()}} -->
                            </option>
                        </select>
                    </div>
                </div>
                <div 
                    *ngIf="testRunnerSections.length > 1 && !isShowingResults && !frameWorkTagsRef.get('NO_STAGE_REF') && !showSectionDropdown()" 
                    [ngSwitch]="!!useSectionCaptions()"
                >
                    <!-- <div>Frame rate: {{renderFps()}} </div> -->
                    <span *ngSwitchCase="true">
                        {{getCurrentSectionCaption()}}
                    </span>
                    <span *ngSwitchCase="false">
                        <tra audio-slug [slug]="getSectionTitleSlug()"></tra>
                        {{" "}}
                        <span audio-slug [slug]="getNumberSlug(getCurrentSectionIndex() + 1)">{{(getCurrentSectionIndex() + 1)}}</span>
                        {{" "}}
                        <tra audio-slug slug="of_enum"></tra>
                        {{" "}}
                        <span audio-slug [slug]="getNumberSlug(testRunnerSections.length)">{{testRunnerSections.length}}</span>
                    </span>
                </div>
                <div *ngIf="isShowingResults" audio-slug [slug]="getResultTitleSlug()">
                    <tra [slug]="getResultTitleSlug()"></tra>
                </div>
                <!-- style="margin-left: 2em;" -->
                <div *ngIf="hasPreambleContent() && !usingPreambleList()" style="margin-top: 0.5em;">
                    <a *ngIf="useImagePreamble() && !isShowingResults" (click)="showSectionInfo()">
                        <!--<img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/Essential-question-Unselected/1611767079590/Essential-question-Unselected.png">-->
                        <div class="button-back" *ngIf="!isShowingSectionInfo"><div class="image-container" style="max-width: 70%;"><img [src]="getCurrentSection().preambleThumbnail"></div><div class="button-text">{{getCurrentSection().preambleThumbnailText}}</div></div>
                        <div class="button-back is-active" *ngIf="isShowingSectionInfo"><div class="image-container" style="max-width: 70%;"><img [src]="getCurrentSection().preambleThumbnailSelected"></div><div class="button-text">{{getCurrentSection().preambleThumbnailText}}</div></div>
                    </a>
                    <button *ngIf="!useImagePreamble()" (click)="showSectionInfo()" class="button is-small is-fullwidth" [class.is-info]="isShowingSectionInfo">
                        <i *ngIf="!getInfoCaption()" class="fa fa-info"></i>
                        <span *ngIf="getInfoCaption()">{{getInfoCaption()}}</span>
                    </button>
                </div>
                <div *ngIf="getCurrentSection().isTimeLimit && !isFlushNavigation()" style="font-size: 0.85em; font-weight: 400;">
                    <tra [slug]="getSectionTimeRemainingSlug()"></tra>: {{sectionTimeRemaining}}
                </div>
            </div>
        </div>
        <div class="test-questions" [class.hide-flags]="!isAnyFlaggedQuestions()">
            <div class="question-navbar-container">
                <div class="question-navbar">
                    <ng-container *ngIf="isShowingResults">
                        <ng-container *ngFor="let mode of pageModeFlow; let i = index">
                            <div class="question-navbar-block" [class.is-active]="pageMode === mode.slug" (click)="selectPageMode(i)" *ngIf="mode.caption && (showResultsDetailPages() || !mode.isDetail)">
                                <button class="question">
                                    <tra *ngIf="!isCustomNavbarResultsIntroPageTitle(mode.slug)" [slug]="mode.caption"></tra>
                                    {{isCustomNavbarResultsIntroPageTitle(mode.slug) ? getCustomNavbarResultsIntroPageTitle() : ''}}                                
                                </button>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="(!isShowingResults || (showResultsDetailPages() && !showSectionsInResultsPage()))">
                        <div class="question-navbar-block" 
                            *ngFor="let question of getCurrentQuestions(); let qIndex = index;" 
                            tabindex="0" 
                            [class.question-navbar-block-colored]="!bgFillService.isFillWhite()"
                            [class.is-active]="!isShowingAmble() && getCurrentQuestionIndex() === qIndex && pageMode === PageMode.TEST_RUNNER"
                            [class.is-split-active]="isQuestionActiveInSplitScreen(question) && pageMode === PageMode.TEST_RUNNER"
                        >
                            <button 
                                class="question test-question--entry" 
                                (click)="selectQuestion(qIndex)" 
                                style="flex: 3;"
                                [class.is-black]="!bgFillService.isFillWhite()"
                            >
                                <img *ngIf="isQuestionFlagged(qIndex)" class="coloured-flag" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/flag (coloured)/1607961301596/flag (coloured).svg" alt=""/>
                                <span *ngIf="!isQuestionFlagged(qIndex)" class="coloured-flag invisible-flag"></span>
                                <span class="icon">
                                    <ng-container *ngIf="!isShowingResults">
                                        <i *ngIf="!isQuestionFilled(qIndex)" class="far fa-circle" [tooltip]="lang.tra('lbl_not_filled')" placement="left" aria-hidden="true"></i>
                                        <i *ngIf="isQuestionFilled(qIndex)" class="fas fa-circle" [tooltip]="lang.tra('lbl_filled')" placement="left" aria-hidden="true"></i>
                                    </ng-container>
                                    <ng-container *ngIf="isShowingResults">
                                        <ng-container *ngIf="!isQuestionUnmarked(question)">
                                            <!-- <img *ngIf="isQuestionCorrect(question)" style="width: 15px" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/correctcircle/1612215146148/correctcircle.png"> -->
                                            <!-- <img *ngIf="!isQuestionCorrect(question)" style="width: 15px" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/incorrectcircle/1612215224414/incorrectcircle.png"> -->
                                            <i *ngIf="isQuestionCorrect(question)" class="fas fa-check-circle fa-lg fa-fw" style="color: #0080FF;"></i>
                                            <i *ngIf="!isQuestionCorrect(question)" class="far fa-dot-circle fa-lg fa-fw" style="color: #0080FF;"></i>
                                        </ng-container>
                                        <!-- <img *ngIf="isQuestionUnmarked(question)" style="width: 15px" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/yellowclock/1612215246552/yellowclock.png"> -->
                                        <i *ngIf="isQuestionUnmarked(question)" class="fas fa-keyboard fa-lg fa-fw" style="color: #fcbc14;"></i>
                                    </ng-container>
                                </span>
                                <span>
                                    {{getQuestionTitleFromId(getSectionIdForQueTitle(question), question)}}
                                </span>
                            </button>
                            <span *ngIf="isSplitScreenLinkInNavBar() && hasSplitScreenLinkInNav(question)" style="flex: 1">
                                <element-render-text-link 
                                    class="read-selection-link-nav"
                                    [class.is-visible]="isShowReadSelLinkInNavbar(question)"
                                    [element]="getReadSelQueTextLinkEl(question)" 
                                    [isHighContrast]="isHighContrast" 
                                    [isIconOnly]="true"
                                    [isShowingResults]="isShowingResults"
                                >
                                </element-render-text-link>
                            </span>
                        </div>
                    </ng-container>  

                    <!-- Results detailed page section with questions -->
                    <ng-container *ngIf="isShowingResults && showResultsDetailPages() && showSectionsInResultsPage()">
                        <ng-container *ngFor="let section of getResultsSections()">
                            <!-- Section indicator -->
                            <div class="question-navbar-block result-nav-section"
                                [class.question-navbar-block-colored]="!bgFillService.isFillWhite()"
                            >
                                <button
                                    class="question test-question--entry" 
                                    style="flex: 3; font-weight: bold;"
                                    [class.is-black]="!bgFillService.isFillWhite()"                                    
                                >
                                    <span>{{getSectionCaptionBySectionId(section)}}</span>                                
                                </button>
                            </div> 

                            <!-- Questions for the section -->
                            <div class="question-navbar-block" 
                                *ngFor="let question of getResultSectionQuestions(section); let qIndex = index;" 
                                tabindex="0" 
                                [class.question-navbar-block-colored]="!bgFillService.isFillWhite()"
                                [class.is-active]="!isShowingAmble() && isQuestionActiveById(question) && pageMode === PageMode.TEST_RUNNER"
                                [class.is-split-active]="isQuestionActiveInSplitScreen(question) && pageMode === PageMode.TEST_RUNNER"
                            >                            
                                <button 
                                    class="question test-question--entry" 
                                    (click)="selectQuestionByQueId(question)" 
                                    style="flex: 3;"
                                    [class.is-black]="!bgFillService.isFillWhite()"
                                >
                                    <img *ngIf="isQuestionFlaggedByQId(question)" class="coloured-flag" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/flag (coloured)/1607961301596/flag (coloured).svg" alt=""/>
                                    <span *ngIf="!isQuestionFlaggedByQId(question)" class="coloured-flag invisible-flag"></span>
                                    <span class="icon">                                        
                                        <ng-container *ngIf="!isQuestionUnmarked(question)">                                            
                                            <i *ngIf="isQuestionCorrect(question)" class="fas fa-check-circle fa-lg fa-fw" style="color: #0080FF;"></i>
                                            <i *ngIf="!isQuestionCorrect(question)" class="far fa-dot-circle fa-lg fa-fw" style="color: #0080FF;"></i>
                                        </ng-container>                                        
                                        <i *ngIf="isQuestionUnmarked(question)" class="fas fa-keyboard fa-lg fa-fw" style="color: #fcbc14;"></i>                                        
                                    </span>
                                    <span>
                                        {{getQuestionTitleFromId(getSectionIdForQueTitle(question), question)}}
                                    </span>
                                </button>
                                <span *ngIf="isSplitScreenLinkInNavBar() && hasSplitScreenLinkInNav(question)" style="flex: 1">
                                    <element-render-text-link 
                                        class="read-selection-link-nav"
                                        [class.is-visible]="isShowReadSelLinkInNavbar(question)"
                                        [element]="getReadSelQueTextLinkEl(question)" 
                                        [isHighContrast]="isHighContrast" 
                                        [isIconOnly]="true"
                                        [isShowingResults]="isShowingResults"
                                    >
                                    </element-render-text-link>
                                </span>
                            </div>                        
                        </ng-container> 
                    </ng-container>                       
                    <div *ngIf="!isShowingResults" (click)="reviewAndSubmit()" class="question-navbar-block" audio-slug [slug]="getSubmissionText()">
                        <button 
                            class="question"
                            [class.is-black]="!bgFillService.isFillWhite()"
                        >
                            <tra [slug]="getSubmissionText()"></tra>
                        </button>
                    </div>
                    <div *ngIf="isShowingResults" (click)="leaveResults()" class="question-navbar-block" audio-slug [slug]="getExitResultsSlug()">
                        <button  class="question" >
                            <tra [slug]="getExitResultsSlug()"></tra>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class='test-timing back-to-map' *ngIf="checkIsOsslt()" (click)="goBackToMap()" audio-slug [slug]="getBackToMapSlug()">
            <i class="fas fa-angle-left"></i> <tra [slug]="getBackToMapSlug()"></tra>
        </div>
        <div class='test-timing back-to-map' *ngIf="hasBackToMenu()" (click)="goBackToMenu()" audio-slug [slug]="getBackToMenuSlug()">
            <i class="fas fa-angle-left"></i> <tra [slug]="getBackToMenuSlug()"></tra>
        </div>
        <ng-container>
            <div class="test-timing" *ngIf="!isNavFooterDisabled">
                <button (click)="checkTimeLeft()" *ngIf="isTimeEnabled" class="is-timer-trigger">
                    <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/clock/1666378022783/clock.png">
                    <span>
                        <tra slug="lbl_timerem"></tra>
                    </span>
                </button>
                <button (click)="openChat()" *ngIf="isChatEnabled">
                    <i class="far fa-comments" aria-hidden="true"></i>
                </button>
                <button *ngIf="chatService.newMessage" (click)="openChat()">
                    <a><i class="fa fa-envelope" aria-hidden="true"></i></a>
                </button>
            </div>
        </ng-container>
    </div>

    <ng-container [ngSwitch]="pageMode">
        <ng-container *ngSwitchCase="PageMode.RESULTS_INTRO">
            <div class="results-page " [ngStyle]="{'font-size.em': getZoomValue()}">
                <div class="centered-div results-intro-div" *ngIf="!customResultPage">
                    <div *ngIf="!asmtFmrk.isOrale && !customResultPageTitle" style="font-size: 2em;font-weight: bold">
                        <tra-md [isCondensed]="true" slug="bc-results-page-text"></tra-md>
                    </div>
                    <div *ngIf="!asmtFmrk.isOrale && customResultPageTitle" style="font-size: 2em;font-weight: bold">
                        <markdown 
                            class="markdown" 
                            [data]="customResultPageTitle" 
                            [class.is-condensed]="true"
                            ngPreserveWhitespaces>
                        </markdown>
                    </div>
                    <div>
                        <tra-md [isCondensed]="true" slug="tr_results_intro_text_1" [props]="{ASSESSMENT_NAME: getAssessmentName(true)}"></tra-md>
                    </div>
                    <div *ngIf="getCustomResultsText()" style="text-align:left;">
                        <!--<tra-md [isCondensed]="true" slug="tr_results_intro_text_2"></tra-md>-->
                        <tra-md [isCondensed]="true" [slug]="getCustomResultsText()"></tra-md>
                    </div>
                    <div *ngIf="showResultsDetailPages()">
                        <!--<tra-md [isCondensed]="true" slug="tr_results_intro_text_2"></tra-md>-->
                        <tra-md [isCondensed]="true" slug="tr_results_intro_text_2"></tra-md>
                    </div>
                </div>
                <ng-container *ngIf="customResultPage">
                    <div class="centered-div results-intro-div custom-result-page" [innerHTML]="getCustomResultsPage()"></div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.RESULTS_SUMMARY">
            <div class="results-page results-summary" [ngStyle]="{'font-size.em': getZoomValue()}">
                <div class="centered-div">
                    
                    <div style="font-size:2.1em; font-weight:600; line-height: 1.2em;"><tra [slug]="getResultSummaryTitleSlug()"></tra></div>


                    <results-summary-sr style="width:100%"
                        [numCorrectSRQuestions]="finalReportStats.numCorrectSRQuestions"
                        [numSRQuestions]="finalReportStats.numSRQuestions"
                        [correctSRScore]="finalReportStats.correctSRScore" 
                        [totalSRScore]="finalReportStats.totalSRScore"
                        [showScore]="showReportOptions(reportOptions.SCORE)"
                        [isCustomResultPageNote]="asmtFmrk.isCustomResultPageNote"
                        [customResultPageNoteEN]="asmtFmrk.customResultPageNoteEN"
                        [customResultPageNoteFR]="asmtFmrk.customResultPageNoteFR">                        
                    </results-summary-sr>
                    
                    <ng-container *ngIf="finalReportStats.numCRQuestions">
                        <results-summary-cr style="width:100%"
                            [totalCRScore]="finalReportStats.totalCRScore"
                            [numCRQuestions]="finalReportStats.numCRQuestions"
                        >
                        </results-summary-cr>
                    </ng-container>
                    <div><tra-md [isCondensed]="true" [slug]="getResultSummaryText1Slug()"></tra-md></div>
                    <button class="button results-export-button" (click)="exportResults()"><img class="img-export-button" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/96360/authoring/export_image/1619731475625/export_image.png"/><tra style="margin-bottom: 0.1em;" slug="test_auth_export"></tra></button>
                    <ng-container *ngIf="isNotTestTaker() && rubricDownloadLink">
                        <div><tra-md [isCondensed]="true" slug="tr_results_summary_text_rubric"></tra-md></div>
                        <button (click)="downloadRubric()" class="button results-export-button"><img class="img-export-button" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/96360/authoring/export_image/1619731475625/export_image.png"/><tra slug="tr_results_view_rubric"></tra></button>
                        <a #rubricLink [href]="rubricDownloadLink"></a>
                    </ng-container>
                    <div><tra-md [isCondensed]="true" [slug]="getResultSummaryText2Slug()"></tra-md></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.RESULTS_INSTRUCTIONS">
            <div class="results-page results-instructions" [ngStyle]="{'font-size.em': getZoomValue()}">
                <div class="centered-div">
                    <div></div>
                    <div style="font-size:2.1em; font-weight:600; line-height: 1.2em;"><tra [slug]="getResultInstructionTitleSlug()"></tra></div>
                    <div><tra-md [isCondensed]="true" slug="tr_results_legend_text_1"></tra-md></div>
                    <div *ngIf="showReportOptions(reportOptions.LEGEND)"><tra-md [isCondensed]="true" [slug]="getResultsLegendText2Slug()"></tra-md></div>
                    <results-legend [initExpand]="true" [numCRQuestions]="!!finalReportStats.numCRQuestions" style="width: 20em"></results-legend>
                    <div><tra-md [isCondensed]="true" [slug]="getResultsLegendText3Slug()"></tra-md></div>
                    <div *ngIf="finalReportStats.numCRQuestions"><tra-md [isCondensed]="true" slug="tr_results_legend_text_4"></tra-md></div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PageMode.TEST_RUNNER">
            <div class="question-content" id="quest-cont" [class.is-split-view]="getActiveQuestionReadSel()">
            <!--<button *ngIf="false" tabindex="1" aria-label="Keyboard Instructions" style="display: none;background-color: white; position: absolute; left: 0; color: #3298dc; border-radius: 5px; padding: 0.75em; border: 2px solid #3298dc">Keyboard Instructions</button>-->
            <div class="helper-tools" [style.font-size.em]="getZoomValue()">
                <div 
                    #questionDisplay 
                    class="question-content-constrainer no-padding" 
                    [class.needs-padding]="getActiveQuestionReadSel()" 
                    [style.width]="getActiveQuestionReadSel() ? '100%' : null" 
                >

                    <ng-container>
                        <!-- *ngIf="questionResetter" -->
                        <div 
                            class="split-view-container" 
                            [class.is-split-view-ltr]="isLtrSplitView()"
                            [style.backgroundColor]="getFillColor()"
                        >
                            <div cdkScrollable 
                                class="split-view-left" 
                                [class.is-full-screen]="isFullScreen()" 
                                [ngStyle]="getSplitViewStyle()" 
                                *ngIf="isShowingLeft()" 
                                [class.is-solo]="!isShowingRight()" 
                                [class.is-saving]="isSavingResponse  && !isQuietlySavingResponse"
                                [style.backgroundColor]="getFillColor()"
                            >
                                <div *ngIf="currQHasBanner()" class="banner" [ngStyle]="getBannerStyle()">
                                    <div class="banner-title" [style.visibility]="hasBannerTitle() ? 'visible' : 'hidden'">
                                        <element-render-text *ngIf="hasBannerTitle()" [element]="getBannerTitle()" audio-slug [url]="getBannerTitle()?.voiceover?.url"></element-render-text>
                                    </div>
                                    <div [style.visibility]="showBannerHr() ? 'visible' : 'hidden'" style="margin-left: 3em; margin-right: 3em;">
                                        <hr [ngStyle]="getBannerHrStyle()" style="margin: 0">
                                    </div>
                                    <div [style.visibility]="hasBannerSubtitle() ? 'visible': 'hidden'" class="banner-subtitle" [style.margin-bottom.em]="getBannerSubtitleMarginBottom()" >
                                        <element-render-text *ngIf="hasBannerSubtitle()" [element]="getBannerSubtitle()" audio-slug [url]="getBannerSubtitle()?.voiceover?.url"></element-render-text>
                                    </div>
                                    <img *ngIf="getBannerOverlaySrc()" class="banner-right-overlay" [src]="getBannerOverlaySrc()">
                                </div>
                                <div id="ghost-split-left" style="position:absolute; visibility: hidden; width: 10em; height:0.01em"></div>
                                    <ng-container *ngIf="isProgressBarEnabled() && !showQHeader()">
                                        <!-- <div class="question-header" *ngIf="isPj">
                                            <span class="pj-eqao-logo"><img class="logo" alt="EQAO logo" [src]="lang.tra('eqao_welcome_logo')" /></span>
                                            <span class="title">{{getCurrentQuestionTitle()}}</span>
                                            <span class="right-side-text" [class]="isReadingSelectionListToggle() ? 'show-text' : 'hide-text'"><i class="fas fa-link"></i><tra slug="pj_open_close"></tra></span>
                                        </div> -->
                                        <tr-progress-bar [isPj]="isPj" [isG6]="isG6" [currProgressLoc]="getCurrentProgressLoc()" [currProgressLocAsNum]="getCurrentProgressLoc(true)"
                                            [currProgressBySession]="getCurrentProgressBySession(currentSession, 0)" [isHidden]="isLineReaderActive"
                                            [useProgressBySession]="useProgressBySession()" [testRunnerSections]="testRunnerSections" 
                                            [currentQuestions]="getCurrentQuestions()" [currentQuestionIndex]="getCurrentQuestionIndex()"
                                            [isReadingSelection]="isReadingSelection" [isQuestionFilled]="isQuestionFilled" [getQuestionTitle]="getQuestionTitle"
                                            (selectQuestion)="selectQuestion($event)">
                                        </tr-progress-bar>
                                    </ng-container>
                                
                                <div *ngIf="isHelpOverlay" class="question-content question-content-help" style="height: auto;">
                                    <div class="question-content-constrainer" style="margin-bottom:2em; height: auto;;" >
                                        <question-runner 
                                            [currentQuestion]="getHelpScreenLayout()" 
                                            [containingWidth]="getQuestionRunnerWidth('main')"
                                            [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                                            [questionState]="helpPageState"
                                            [allowSubtitles]="isAllowedSubtitles()"
                                            [allowTranscripts]="isAllowedTranscripts()"
                                            [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                                            [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                                            [frameWorkTags]="frameWorkTags"
                                        ></question-runner>
                                    </div>
                                </div>

                                <!-- Display Preamble: -->
                                <ng-container *ngIf="isShowingAmble() && !isHelpOverlay">
                                    <div *ngIf="hasAmbleContent()" style="position: relative;" class="question-runner-padder">
                                        <div *ngIf="isInfoIconEnabled()" style="font-size: 2em; color: #3298dc; position:absolute; left:-1em; top:0.1em;">
                                            <i class="fa fa-info"></i>
                                        </div>
                                        <question-runner 
                                            [currentQuestion]="getCurrentAmbleQ()" 
                                            [containingWidth]="getQuestionRunnerWidth('main')"
                                            [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                                            [allowSubtitles]="isAllowedSubtitles()"
                                            [allowTranscripts]="isAllowedTranscripts()"
                                            [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                                            [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                                            [frameWorkTags]="frameWorkTags"
                                        ></question-runner>
                                        <div *ngIf="isShowOverlay && resetter && questionResetter" class="question-content__overlay" [ngClass]="{'deactivated':showDeactivatedMode}" id="draw-overlay-split">
                                            <div (click)="disableOverlay()" class="draw-overlay-close">
                                                &times;
                                            </div>
                                            <element-render-drawing  
                                                id="test-runner" 
                                                [parent]="getDrawingDisplayMode()" 
                                                [currId]="getActiveQuestionId()" 
                                                [activatedState]="!showDeactivatedMode" 
                                                [useEraser]="toolToggles.showEraser" 
                                                [useHighlighter]="toolToggles.showHighlight" 
                                                [useFreehand]="toolToggles.showFreehand"
                                                [useLine]="toolToggles.showLine" 
                                                [zoomLevel]="getZoomValue()" 
                                                [isZoomIn]="isZoomIn"
                                                [isFrameEnabled]="false"
                                                [isToolbarEnabled]="false"
                                                [drawingCtx]="loadDrawing()"
                                                [pageIndexTracker]="drawingPageIndexTracker"
                                                [section]="getCurrentSectionIndex()"
                                                (savedCtx)="saveDrawing($event)"
                                                [clearAllDrawings]="clearAllDrawings"
                                            ></element-render-drawing>
                                        </div>
                                    </div>
                                    <div class="next-button-container" [class.no-top-margin]="useCustomNextPrev()" *ngIf="showButtonAfterQ()">
                                        <ng-container [ngSwitch]="useCustomNextPrev()">
                                            <div *ngSwitchCase="true" style="display: flex;" [style.margin-left.em]="getCustomBtnAfterQIndent()">
                                                <custom-nav-button *ngIf="useCustomPrev()" (click)="gotoPrev()" [isBold]="isCustomPrevBold()" [fgColor]="getCustomPrevFgColor()" [bgColor]="getCustomPrevBgColor()" [text]="getCustomPrevText()" [isPrev]="true"></custom-nav-button>
                                                <custom-nav-button *ngIf="useCustomNext()" (click)="gotoNext()" [isBold]="isCustomNextBold()" [fgColor]="getCustomNextFgColor()" [bgColor]="getCustomNextBgColor()" [text]="getCustomNextText()"></custom-nav-button>
                                            </div>
                                            <button *ngSwitchCase="false" (click)="hideSectionInfo()" id="btn-submit-question" class="button is-info">
                                                <tra-md audio-slug [slug]="getNextPageSlug()" [isCondensed]="true"></tra-md>
                                            </button>
                                        </ng-container>
                                    </div>
                                </ng-container>

                                <!-- Display Current Question:    -->
                                <ng-container *ngIf="!isShowingAmble() && !isHelpOverlay">
                                    <div *ngIf="showQHeader()" class="top-bar">
                                        <div class="top-bar-row">
                                            <div [style]="isPj? 'display: flex; flex-direction: row; line-height: 1.5em' : 'display: flex; flex-direction: column'">
                                                <div class="align-center">
                                                    <!-- <button class="button" *ngIf="!isTestNavExpanded" (click)="toggleTestNav()">
                                                        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                                                    </button> -->
                                                    <span style="font-weight:600" [style.color]="isHighContrast ? '#fff' : '#000'" >
                                                       <span *ngIf="isShowingResults"><tra slug='bc-results-page-header'></tra>&nbsp;-&nbsp;</span> 
                                                       <span>{{getCurrentQuestionTitle()}}</span>
                                                    </span>
                                                    <span *ngIf="getCurrentPoints()" [style.color]="isHighContrast ? '#fff' : ''" class="points-indicator">({{getCurrentPoints()}})</span>
                                                </div>
                                                <div *ngIf="isFlagEnabledForCurrentQuestion() && !isShowingResults" style="font-size: 0.7em; margin-top:0.8em;" class="flag-button-container" audio-slug [slug]="getFlagSlug(isCurrentQuestionFlagged())">
                                                    <!-- class="button is-small is-info is-outlined" -->
                                                    <a (keypress)="flagKeyPress($event)" [style.color]="isHighContrast ? '#ff0' : ''" style="font-weight: 400;" tabindex="0" (click)="flagCurrentQuestion()">
                                                        <img class="flag-image" [src]="getFlagURL()" alt=""/> 
                                                        <tra [slug]="getFlagSlug()" [style.color]="!bgFillService.isFillWhite() ? '#000' : ''" *ngIf="!isCurrentQuestionFlagged()"></tra> 
                                                        <tra [slug]="getFlagSlug(true)" [style.color]="!bgFillService.isFillWhite() ? '#000' : 'red'" *ngIf="isCurrentQuestionFlagged()"></tra>
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            <ng-container *ngIf="!isHideReadSelLinksInQueHeader()">
                                                <div *ngIf="hasExactlyOneReadingSelection()" class="reading-selections-container">
                                                    <div class="reading-selections" [style.color]="isHighContrast ? '#ff0' : ''" style="margin-top:0.5em;">
                                                        <element-render-text-link *ngIf="readerInfo && readerInfo[0]" [element]="convertReadSelToTextLink(readerInfo[0])" [isHighContrast]="isHighContrast" [saveQuestion]="_saveQuestion.bind(this)" [isShowingResults]="isShowingResults"></element-render-text-link>
                                                    </div>
                                                </div>
                                                <div *ngIf="hasReadingSelections()" class="reading-selections-container">
                                                    <div class="reading-selections" [style.color]="isHighContrast ? '#ff0' : ''">
                                                        <div *ngIf="isReadingSelectionListToggle()">
                                                            <a (keydown.enter)="toggleReadingSelections()" [style.color]="isHighContrast ? '#ff0' : ''" (click)="toggleReadingSelections()" tabindex="0" [ngSwitch]="!!isShowingReadingSelections">
                                                                <i *ngSwitchCase="false" class="icon fas fa-caret-right"></i>
                                                                <i *ngSwitchCase="true" class="icon fas fa-caret-down"></i>
                                                                <tra [slug]="getOpenReadSelSlug(isShowingReadingSelections)"></tra>
                                                            </a>
                                                            <div *ngIf="isShowingReadingSelections" class="reading-selection-options">
                                                                <div class="reading-selection-option" [style.color]="isHighContrast ? '#ff0' : ''" *ngFor="let readSel of readerInfo; let i = index">
                                                                    <element-render-text-link  [element]="convertReadSelToTextLink(readSel)" [isHighContrast]="isHighContrast" [saveQuestion]="_saveQuestion.bind(this)" [isShowingResults]="isShowingResults"></element-render-text-link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!isReadingSelectionListToggle()">
                                                            <div class="reading-selection-options">
                                                                <div class="reading-selection-option" *ngFor="let readSel of readerInfo; let i = index">
                                                                    <element-render-text-link  [element]="convertReadSelToTextLink(readSel)" [isHighContrast]="isHighContrast" [saveQuestion]="_saveQuestion.bind(this)" [isShowingResults]="isShowingResults"></element-render-text-link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="isProgressBarEnabled()" style="width: 100%">
                                            <tr-progress-bar [isPj]="isPj" [isG6]="isG6" 
                                                [currProgressLoc]="getCurrentProgressLoc()" [currProgressLocAsNum]="getCurrentProgressLoc(true)" 
                                                [currProgressBySession]="getCurrentProgressBySession(currentSession, 0)" [isHidden]="isLineReaderActive" 
                                                [useProgressBySession]="useProgressBySession()" [testRunnerSections]="testRunnerSections" 
                                                [useLessPadding]="true" [currentQuestions]="getCurrentQuestions()" [currentQuestionIndex]="getCurrentQuestionIndex()"
                                                [isReadingSelection]="isReadingSelection" [isQuestionFilled]="isQuestionFilled" [getQuestionTitle]="getQuestionTitle"
                                                (selectQuestion)="selectQuestion($event)" [isQuestionnaire]="isQuestionnaire">
                                            </tr-progress-bar>
                                        </div>
                                    </div>
                                    <div class="question-runner-padder">
                                        <div style="position:relative;">
                                            <div >
                                                <div *ngIf="isFromItemSetEditor && questionSuggestions.has(getActiveQuestionId())">
                                                    <div cdkDrag (cdkDragEnded)="onViewModeSelectorDragEnd()" class="edit-view-mode-selector">
                                                        <button (click)="setQuestionEditViewMode(getActiveQuestionId(), EditViewMode.BEFORE)" class="button is-small has-icon" [class.is-info]="getQuestionEditViewMode(getActiveQuestionId()) === EditViewMode.BEFORE"><i style="margin-top: 0.1em;" class="fas fa-caret-square-left"></i>&nbsp;<tra slug="auth_view_real"></tra></button>
                                                        <button (click)="setQuestionEditViewMode(getActiveQuestionId(), EditViewMode.AFTER)" class="button is-small has-icon" [class.is-info]="getQuestionEditViewMode(getActiveQuestionId()) === EditViewMode.AFTER" style="margin-right: 0"><tra slug="auth_view_suggested"></tra>&nbsp;<i style="margin-top: 0.1em;" class="fas fa-caret-square-right"></i></button>
                                                    </div> 
                                                </div>
                                                <question-runner 
                                                    [currentQuestion]="getActiveQuestionContent()" 
                                                    [questionState]="getActiveQuestionState()"
                                                    [containingWidth]="getQuestionRunnerWidth('split-left')"
                                                    [containingWidthSpill]="getQuestionRunnerSpillover('split-left')"
                                                    [isSubmitted]="isShowingResults"
                                                    [allowSubtitles]="isAllowedSubtitles()"
                                                    [allowTranscripts]="isAllowedTranscripts()"
                                                    [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                                                    [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                                                    [frameWorkTags]="frameWorkTags"
                                                ></question-runner>
                                            </div>
                                            <div *ngIf="isShowOverlay && resetter && questionResetter" class="question-content__overlay" [ngClass]="{'deactivated':showDeactivatedMode}" id="draw-overlay-split">
                                                <div (click)="disableOverlay()" class="draw-overlay-close">
                                                    &times;
                                                </div>
                                                <element-render-drawing  
                                                    id="test-runner" 
                                                    [parent]="getDrawingDisplayMode()" 
                                                    [currId]="getCurrentLeftItemId()" 
                                                    [activatedState]="!showDeactivatedMode" 
                                                    [useEraser]="toolToggles.showEraser" 
                                                    [useHighlighter]="toolToggles.showHighlight" 
                                                    [useFreehand]="toolToggles.showFreehand"
                                                    [useLine]="toolToggles.showLine" 
                                                    [zoomLevel]="getZoomValue()" 
                                                    [isZoomIn]="isZoomIn"
                                                    [isFrameEnabled]="false"
                                                    [isToolbarEnabled]="false"
                                                    [drawingCtx]="loadDrawing()"
                                                    [pageIndexTracker]="drawingPageIndexTracker"
                                                    [section]="getCurrentSectionIndex()"
                                                    (savedCtx)="saveDrawing($event)"
                                                    [clearAllDrawings]="clearAllDrawings"
                                                ></element-render-drawing>
                                            </div>
                                            <div 
                                                class="question-content__faux-overlay" 
                                                [class.animateDown]="isShowOverlay && !showDeactivatedMode"
                                                [class.animateUp]="showDeactivatedMode"
                                            ></div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div  *ngIf="showButtonAfterQ()" [ngSwitch]="useCustomNextPrev()" class="next-button-container test-question" [class.no-top-margin]="useCustomNextPrev()" style="display:block; flex-direction: column;" [class.is-hidden]="isShowingAmble()">
                                    <ng-container *ngSwitchCase="true">
                                        <div style="display: flex;" [style.margin-left.em]="getCustomBtnAfterQIndent()">
                                            <custom-nav-button *ngIf="useCustomPrev()" (click)="gotoPrev()" [isBold]="isCustomPrevBold()" [fgColor]="getCustomPrevFgColor()" [bgColor]="getCustomPrevBgColor()" [text]="getCustomPrevText()" [isPrev]="true"></custom-nav-button>
                                            <custom-nav-button *ngIf="useCustomNext()" (click)="gotoNext()" [isBold]="isCustomNextBold()" [fgColor]="getCustomNextFgColor()" [bgColor]="getCustomNextBgColor()" [text]="getCustomNextText()"></custom-nav-button>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="false">
                                        <ng-container  [ngSwitch]="!!isOnLastQuestion()">
                                            <button *ngSwitchCase="false" (click)="gotoNextQuestion()" id="btn-submit-question" [disabled]="!isCurrentQuestionFilled() && !isCurrentQuestionFlagged() && !areTestQuestionsSkippable() && !forceQuestionsSkippable"   class="button is-large is-info">
                                                <tra-md audio-slug [slug]="getNextPageSlug()" [isCondensed]="true"></tra-md>
                                            </button>
                                            <button audio-slug [slug]="getSubmissionText()" *ngSwitchCase="true" (click)="reviewAndSubmit()" id="btn-submit-question" [disabled]="!isCurrentQuestionFilled() && !isCurrentQuestionFlagged()"  class="button is-large is-info">
                                                <tra [slug]="getSubmissionText()"></tra>
                                            </button>
                                            <div *ngIf="isFlagEnabledForCurrentQuestion()" class="flag-button-container">
                                                <!-- class="button is-small is-info is-outlined" -->
                                                <a (keypress)="flagKeyPress($event)" tabindex="0" (click)="flagCurrentQuestion()">
                                                    <img class="flag" [src]="getFlagURL()" alt=""/> 
                                                    <tra [slug]="getFlagSlug(false)" *ngIf="!isCurrentQuestionFlagged()"></tra> 
                                                    <tra [slug]="getFlagSlug(true)" style="color: red;" *ngIf="isCurrentQuestionFlagged()"></tra>
                                                </a>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
            
                                <div style="height:6em;"></div>

                            </div>
                            <div cdkScrollable 
                                class="split-view-right" 
                                [class.is-solo]="!isShowingLeft()" 
                                id="readingPassageSplit" 
                                *ngIf="isShowingRight() && (!isShowingAmble() || showToolbar())"
                                [style.backgroundColor]="getFillColor()"
                            >

                                <ng-container [ngSwitch]="asmtFmrk.showDocumentsSplitScreen && isShowDocuments">
                                    <div *ngSwitchCase="true">
                                        <documents-display [addPadding]="true" [showCloseButton]="false" [documentViews]="documentViews" (selectDocumentView)="selectDocumentView($event)" [selectedDocumentId]="selectedDocumentId" [activeDocument]="activeDocument" [containingWidth]="getQuestionRunnerWidth('main')" [containingWidthSpill]="getQuestionRunnerSpillover('main')" [allowSubtitles]="isAllowedSubtitles()" [allowTranscripts]="isAllowedTranscripts()" [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()" [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()" [isRefDocumentsNestedView]="asmtFmrk.isRefDocumentsNestedView"></documents-display>
                                    </div>                                    
                                    <!-- Reading selection -->
                                    <div *ngSwitchCase="false" id="left-split-container">
                                        <ng-container *ngIf="getActiveQuestionReadSel()">
                                            <div id="ghost-split-right" style="position:absolute; visibility: hidden; width: 10em; height:0.01em"></div>
                                            <div [class.question-runner-padder]="isPassagePadded()">
                                                <div *ngIf="isFromItemSetEditor && questionSuggestions.has(getActiveReadingSelectionId())"class="edit-view-mode-selector">
                                                    <button (click)="setQuestionEditViewMode(getActiveReadingSelectionId(), EditViewMode.BEFORE)" class="button is-small has-icon" [class.is-info]="getQuestionEditViewMode(getActiveReadingSelectionId()) === EditViewMode.BEFORE"><i style="margin-top: 0.1em;" class="fas fa-caret-square-left"></i>&nbsp;<tra slug="auth_view_real"></tra></button>
                                                    <button (click)="setQuestionEditViewMode(getActiveReadingSelectionId(), EditViewMode.AFTER)" class="button is-small has-icon" [class.is-info]="getQuestionEditViewMode(getActiveReadingSelectionId()) === EditViewMode.AFTER" style="margin-right: 0"><tra slug="auth_view_suggested"></tra>&nbsp;<i style="margin-top: 0.1em;" class="fas fa-caret-square-right"></i></button>
                                                </div>
                                                <question-runner *ngIf="activeReadingSelection" id="questionRunnerInReadingPassage" 
                                                    [currentQuestion]="getActiveReadingSelectionContent()"
                                                    [containingWidth]="getQuestionRunnerWidth('split-right')"
                                                    [containingWidthSpill]="getQuestionRunnerSpillover('split-right')"
                                                    [questionState]="getReadingSelectionState()"
                                                    [isSubmitted]="isShowingResults"
                                                    [allowSubtitles]="isAllowedSubtitles()"
                                                    [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                                                    [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                                                    [frameWorkTags]="frameWorkTags"
                                                ></question-runner>
                                            </div>
                                            <div *ngIf="isShowOverlay && resetter && questionResetter" class="question-content__overlay" [ngClass]="{'deactivated':showDeactivatedMode}" id="draw-overlay-split">
                                                <div (click)="disableOverlay()" class="draw-overlay-close">
                                                    &times;
                                                </div>
                                                <element-render-drawing  
                                                    id="test-runner" 
                                                    [parent]="getDrawingDisplayMode()" 
                                                    [currId]="getCurrentRightItemId()" 
                                                    [activatedState]="!showDeactivatedMode" 
                                                    [useEraser]="toolToggles.showEraser" 
                                                    [useHighlighter]="toolToggles.showHighlight" 
                                                    [useFreehand]="toolToggles.showFreehand"
                                                    [useLine]="toolToggles.showLine" 
                                                    [zoomLevel]="getZoomValue()" 
                                                    [isZoomIn]="isZoomIn"
                                                    [isFrameEnabled]="false"
                                                    [isToolbarEnabled]="false"
                                                    [drawingCtx]="loadDrawing()"
                                                    [pageIndexTracker]="drawingPageIndexTracker"
                                                    [section]="getCurrentSectionIndex()"
                                                    (savedCtx)="saveDrawing($event)"
                                                    [clearAllDrawings]="clearAllDrawings"
                                                ></element-render-drawing>
                                            </div>
                                            <div 
                                                class="question-content__faux-overlay" 
                                                [class.animateDown]="isShowOverlay && !showDeactivatedMode"
                                                [class.animateUp]="showDeactivatedMode"
                                            ></div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        </ng-container>
    </ng-container>


    <div *ngIf="showToolbar()" class="toolbar-surrogate" [class.is-pj]="isPj" ></div>
    <!-- *ngIf="pageMode === PageMode.TEST_RUNNER"  -->

</div>
<div *ngIf="!testState.isSubmitted && !isPrintMode" class="test-runner-overlays">

    <!-- [style.max-width.px]="this.screenWidth" -->

    <div *ngIf="isLineReaderActive">
        <div class="line-reader-container" id="quest-reader" [ngStyle]="{'font-size.em': getZoomValue()}" (window:mouseup)="resizeLineReaderWidth($event, false);">
            <div cdkDrag class="line-reader" #lineReader [style.width.px]="lineReaderWidth" >
                <div class="line-reader-top"></div>
                <div class="line-reader-left"></div>
                <div class="line-reader-right"></div>
                <div class="line-reader-bottom"></div>
                <div class="resize-line-reader" (mousedown)="resizeLineReaderWidth($event, true);" >
                    <div class="resizer-tool">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isNotepadEnabled" [ngStyle]="{'font-size.em': getZoomValue()}">
        <div cdkDrag class="notepad-container" >
            <div (click)="toggleNotepad()" class="notepad-close">&times;</div>
            <div style="padding-bottom:0.5em;">
                <tra [slug]="getNotepadSlug()"></tra>
            </div>
            <div *ngIf="getNotepadText()" style="    padding-bottom: 0.6em;
            border-bottom: 1px solid #fff;
            margin-bottom: 0.5em;
            line-height: 1.1em;
            font-weight: 500;
            font-size: 0.7em;;">
                {{getNotepadText()}}
            </div>
            <ng-container [ngSwitch]="!!(isFlushNavigation() || checkIsOsslt())">
                <textarea *ngSwitchCase="true" class="textarea" style="font-size:1em;" spellcheck="false" (mousedown)="$event.stopPropagation()"  [(ngModel)]="testState.notes"></textarea>
                <ng-container *ngSwitchCase="false">
                    <ng-container [ngSwitch]="!!isUsingTemporaryNotes()">
                        <textarea *ngSwitchCase="false" class="textarea" spellcheck="false" (mousedown)="$event.stopPropagation()" oncopy="return false" [(ngModel)]="getActiveQuestionState().__meta.notes"></textarea>
                        <textarea *ngSwitchCase="true" class="textarea"  spellcheck="false" (mousedown)="$event.stopPropagation()" oncopy="return false" [(ngModel)]="testState.notes"></textarea>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngIf="isCalcAvailable()" style="z-index: 999;" id="calculator-widget">
        <widget-calculator 
            [disableCalcSD]="disableCalcSD" 
            [disableCalcRoot]="disableCalcRoot" 
            [disableCalcDecimal]="disableCalcDecimal" 
            [disableCalcFraction]="disableCalcFraction" 
            [isGraphical]="false" 
            [zoom]="getZoomValue()" 
            [type]="getCalculatorType()"
            [isPj]="isPj"
            *ngIf="isShowingCalc() && resetter"
        ></widget-calculator>
    </div>

    <div *ngIf="isDictionaryAvailable()" style="z-index: 999;" id="calculator-widget">
        <widget-dictionary 
            [zoom]="getZoomValue()" 
            *ngIf="isShowingDictionary() && resetter"
            [isShowSynonyms]="asmtFmrk.toolbarOptions.dictionaryDisplaySynonyms"
        ></widget-dictionary>
    </div>

    <div *ngIf="isShowFormulaSheet" class="formula-container">
    <div class="formula-container__sheet">
        <div class="formula-container__sheet-header">
            <p>Formula Sheet</p>
            <button (click)="toggleFormula()"> <i class="fas fa-times"></i></button>
            
        </div>
    </div>
    </div>

    <div *ngIf="isTtsEnabled() && !showToolbar()" class="tts-button-div" [class.pj-tts-button-div]="isPj">
        <button class="toolbar-icon" (click)="toggleTextToSpeech()" [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isTextToSpeechActive()" [tooltip]="lang.tra(getListenSlug())" placement="left"
        [attr.aria-label]="lang.tra(getListenSlug())" audio-slug [slug]="getListenSlug()">
            <img *ngIf="!isPj" aria-hidden="true" [src]="getTtsImgSrc()">
            <img class="toolbar-icon amble-icon" *ngIf="isPj" aria-hidden="true" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/tts_icon/1639495226686/tts_icon.svg">
        </button>
    </div>

    <div *ngIf="showToolbar()" class="toolbar" [class.pj-toolbar]="isPj" [class.g6-toolbar]="isG6" [class.is-expanded]="isToolbarExpanded">
            <div class="tools-top">
                <button class="button collapser" (click)="toggleToolbar()">
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
                <button class="toolbar-icon tool-help" [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isHelpOverlay" (click)="toggleHelpScreen()" [tooltip]="lang.tra(getHelpSlug())" 
                        placement="left"  *ngIf="isHelpEnabled && !isFlushNavigation()" [attr.aria-label]="lang.tra(getHelpSlug())" audio-slug [slug]="getHelpSlug()">
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/help_icon/1639494385882/help_icon.svg">
                    <i *ngIf="!isPj" class="fa fa-question-circle toolbar-icon-icon"  aria-hidden="true"></i>
                </button>
                <!-- Text to speech by Trevor Dsouza from the Noun Project -->
                <button class="toolbar-icon" (click)="toggleTextToSpeech()" [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isTextToSpeechActive()" 
                        *ngIf="isTtsEnabled()" [tooltip]="lang.tra(getListenSlug())" placement="left"
                        [attr.aria-label]="lang.tra(getListenSlug())" audio-slug [slug]="getListenSlug()">
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/tts_icon/1639495226686/tts_icon.svg">
                    <img *ngIf="!isPj" aria-hidden="true" [src]="getTtsImgSrc()">
                </button>
                <button class="toolbar-icon" (click)="zoomIn()" [tooltip]="lang.tra(getZoomInSlug())" placement="left" *ngIf="asmtFmrk.toolbarOptions.zoomIn" [attr.aria-label]="lang.tra(getZoomInSlug())" audio-slug [slug]="getZoomInSlug()">
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/zoom_in/1639495295805/zoom_in.svg">
                    <img *ngIf="!isPj" aria-hidden="true" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/zoom-in/1666378095532/zoom-in.png">
                </button>
                <div *ngIf="!isPj" style="color:#fff">{{getCurrentZoomDisplay()}}</div>
                <div *ngIf="isPj" style="color:black; background-color: white; border-radius: 3em; padding: 0em .5em">{{getCurrentZoomDisplay()}}</div>
                <button class="toolbar-icon" (click)="zoomOut()" [tooltip]="lang.tra(getZoomOutSlug())" placement="left" *ngIf="asmtFmrk.toolbarOptions.zoomOut" [attr.aria-label]="lang.tra(getZoomOutSlug())" audio-slug [slug]="getZoomOutSlug()">
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/zoom_out/1639495327727/zoom_out.svg">
                    <img *ngIf="!isPj" draggable="FALSE" aria-hidden="true" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/zoom-out/1666378160109/zoom-out.png">
                </button>
                <button class="toolbar-icon" (click)="toggleLineReader()" [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isLineReaderActive" [tooltip]="lang.traWithWhitelabel('btn_line_reader', getLineReaderCaption())" audio-slug [slug]="getLineReaderCaption()" *ngIf="asmtFmrk.toolbarOptions.lineReader"
                        placement="left" [attr.aria-label]="lang.traWithWhitelabel('btn_line_reader', getLineReaderCaption())">
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/line_reader/1639496552787/line_reader.svg">
                    <img *ngIf="!isPj" draggable="FALSE" aria-hidden="true" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/line_reader/1666376884926/line_reader.png">
                </button>
                <!-- Disabling the HC tool for ABED -->
                <button *ngIf="isDrawingToolsShown() && asmtFmrk.toolbarOptions.hiContrast && false" class="toolbar-icon" (click)="toggleHiContrast()" [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isHighContrast" 
                        [tooltip]="lang.tra(getHiContrastSlug())" placement="left" [attr.aria-label]="lang.tra(getHiContrastSlug())" audio-slug [slug]="getHiContrastSlug()">
                    <!-- hi contrast -->
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/high_contrast/1639495102945/high_contrast.svg">
                    <img *ngIf="!isPj" draggable="FALSE" aria-hidden="true" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/hi-contrast/1666378238806/hi-contrast.png">
                </button>
                <button *ngIf="!isShowingResults && isDrawingToolsShown() && asmtFmrk.toolbarOptions.freehand" class="toolbar-icon" (click)="setFreehand()" 
                        [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isShowOverlay && !showDeactivatedMode && toolToggles.showFreehand" 
                        [tooltip]="lang.tra(getFreehandSlug())" placement="left" [attr.aria-label]="lang.tra(getFreehandSlug())" audio-slug [slug]="getFreehandSlug()">
                    <i class="fas fa-pen-nib toolbar-icon-icon" aria-hidden="true"></i>
                </button>
                <button *ngIf="!isShowingResults && isDrawingToolsShown() && asmtFmrk.toolbarOptions.toggleEditor" class="toolbar-icon" (click)="setLine()" 
                        [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isShowOverlay && !showDeactivatedMode && toolToggles.showLine" 
                        [tooltip]="lang.tra(getDrawingToolsSlug())" placement="left" [attr.aria-label]="lang.tra(getDrawingToolsSlug())" audio-slug [slug]="getDrawingToolsSlug()">
                    <i class="fas fa-edit toolbar-icon-icon" aria-hidden="true"></i>
                </button>
                <button *ngIf="!isShowingResults && isDrawingToolsShown() && asmtFmrk.toolbarOptions.highlighter" class="toolbar-icon" (click)="setHighlighter()" 
                        [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isShowOverlay && !showDeactivatedMode && toolToggles.showHighlight"  
                        [tooltip]="lang.tra(getHighlighterSlug())" placement="left" [attr.aria-label]="lang.tra(getHighlighterSlug())" audio-slug [slug]="getHighlighterSlug()">
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE"  src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/highlighter/1639495142135/highlighter.svg">
                    <i *ngIf="!isPj" class="fas fa-highlighter toolbar-icon-icon" aria-hidden="true"></i>
                </button>
                <button *ngIf="!isShowingResults && isDrawingToolsShown() && asmtFmrk.toolbarOptions.eraser" class="toolbar-icon" (click)="setEraser()" 
                        [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isShowOverlay &&!showDeactivatedMode && toolToggles.showEraser"
                        [tooltip]="lang.tra(getEraserSlug())" placement="left" [attr.aria-label]="lang.tra(getEraserSlug())" audio-slug [slug]="getEraserSlug()">
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE"  src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/eraser_icon/1639495047721/eraser_icon.svg">
                    <i *ngIf="!isPj" class="fas fa-eraser toolbar-icon-icon" aria-hidden="true"></i>
                </button>
                <button *ngIf="!isShowingResults && isDrawingToolsShown() && asmtFmrk.toolbarOptions.backgroundColor" class="toolbar-icon" (click)="setFill()" 
                        [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="toolToggles.showFill"
                        [tooltip]="lang.tra(getFillSlug())" placement="left" [attr.aria-label]="lang.tra(getFillSlug())" audio-slug [slug]="getFillSlug()">
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE"  src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/eraser_icon/1639495047721/eraser_icon.svg">
                    <i *ngIf="!isPj" class="fas fa-fill-drip toolbar-icon-icon" aria-hidden="true"></i>
                </button>
                <button *ngIf="!isShowingResults && (checkIsOsslt()  || asmtFmrk.toolbarOptions.notepad)" class="toolbar-icon" (click)="toggleNotepad()" [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isNotepadEnabled" 
                        [tooltip]="lang.tra(getNotepadSlug())" placement="left" [attr.aria-label]="lang.tra(getNotepadSlug())" audio-slug [slug]="getNotepadSlug()">
                    <img *ngIf="isPj" class="pj-toolbar-icon" draggable="FALSE" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/rough_notes/1639495192066/rough_notes.svg">
                    <i *ngIf="!isPj" class="fas fa-sticky-note toolbar-icon-icon" aria-hidden="true"></i>
                </button>
                <button id="CalculatorToolbar" *ngIf="isCalcAvailable() && isPj" (click)="toggleCalc()" class="toolbar-icon" [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isShowingCalc()" 
                    [tooltip]="lang.tra(getCalcSlug())" [attr.aria-label]="lang.tra(getCalcSlug())" placement="left" audio-slug [slug]="getCalcSlug()">
                    <img style="width: 100%;" draggable="FALSE" [class.pj-toolbar-icon]="isPj" [src]=getCalcImgUrl()>
                </button>
                <button *ngIf="isDictionaryAvailable() && isPj" (click)="toggleDictionary()" class="toolbar-icon" [class.is-pj]="isPj" [class.is-g6]="isG6" [class.is-active]="isShowingDictionary()" 
                    [tooltip]="lang.tra(getDictionarySlug())" [attr.aria-label]="lang.tra(getDictionarySlug())" placement="left" audio-slug [slug]="getDictionarySlug()">
                    <!-- <img style="width: 100%;" draggable="FALSE" [class.pj-toolbar-icon]="isPj" [src]=getCalcImgUrl()> -->
                    <i class="fa fa-book" aria-hidden="true"></i>
                </button>
                <button *ngIf="documentItems && documentItems.length && !checkIsOsslt() && isPj" 
                    (click)="toggleDocuments()" 
                    class="toolbar-icon" 
                    [class.is-active]="isShowDocuments" 
                    [tooltip]="lang.tra(getDocumentTooltip())" placement="left"
                    [attr.aria-label]="lang.tra(getDocumentTooltip())"
                    audio-slug [slug]="getDocumentTooltip()">
                    <img aria-hidden="true" style="width: 100%;" draggable="FALSE" [class.pj-toolbar-icon]="isPj" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/book-4Asset 93/1647631671769/book-4Asset 93.svg">
                </button>
                <button *ngIf="isAccessibilitySettingsAvail()" class="toolbar-icon" [class.is-pj]="isPj" [class.is-g6]="isG6" [tooltip]="lang.tra(getAccSettingsSlug())" audio-slug [slug]="getAccSettingsSlug()" (click)="openAccSettingsModal()">
                    <img style="width:100%" [class.pj-toolbar-icon]="isPj" draggable="FALSE" [src]="getAccessibilityIconSrc()">
                </button>
            </div>
            <div class="tools-bottom">
            <button *ngIf="documentItems && documentItems.length && !checkIsOsslt() && !isPj" 
                (click)="toggleDocuments()" 
                class="toolbar-icon" 
                [class.is-active]="isShowDocuments" 
                [tooltip]="lang.tra(getDocumentTooltip())" placement="left"
                [attr.aria-label]="lang.tra(getDocumentTooltip())"
                audio-slug [slug]="getDocumentTooltip()">
                <img aria-hidden="true" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/reference-book/1666378316038/reference-book.png">
            </button>
            <!-- <button *ngIf="isFormulasAvailable()" (click)="toggleFormulas()" class="toolbar-icon" [class.is-active]="isFormulasToggledOn" title="{{lang.tra('btn_formulas')}}">
                <img src="https://mathproficiencytest.ca/img/reference-book.png">
            </button> -->
            <button id="CalculatorToolbar" *ngIf="isCalcAvailable() && !isPj" (click)="toggleCalc()" class="toolbar-icon" [class.is-active]="isShowingCalc()" 
                    [tooltip]="lang.tra(getCalcSlug())" [attr.aria-label]="lang.tra(getCalcSlug())" placement="left" audio-slug [slug]="getCalcSlug()">
                    <img class="toolbar-icon-img-doc" [src]="getCalcImgUrl()">
            </button>
            <button *ngIf="isDictionaryAvailable() && !isPj" (click)="toggleDictionary()" class="toolbar-icon" [class.is-active]="isShowingDictionary()" 
                [tooltip]="lang.tra(getDictionarySlug())" [attr.aria-label]="lang.tra(getDictionarySlug())" placement="left" audio-slug [slug]="getDictionarySlug()">
                <!-- <img style="width: 100%;" draggable="FALSE" [class.pj-toolbar-icon]="isPj" [src]=getCalcImgUrl()> -->
                <i class="fa fa-book toolbar-icon-icon" aria-hidden="true"></i>
            </button>
            <div *ngIf="!asmtFmrk.isOrale && asmtFmrk.toolbarOptions.infoButton" (click)="toggleInfoModal()" [class.info-button-pj]="isPj" [class.info-button]="!isPj" audio-slug [slug]="getToolbarInfoSlug()">
                <img *ngIf="!isPj" class="toolbar-icon-img-cal" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/96360/authoring/Info/1621008934819/Info.svg">
            </div>
            <div class="debug-button is-embedded" (click)="triggerDebugModeAgg()"> </div>
        </div>
    </div>

    <div class="info-overlay" *ngIf="isShowingReport">
        <div class="info-box">
            <strong><tra slug="btn_report_issue"></tra></strong>
            <hr/>
            <textarea class="textarea" placeholder="Please describe the issue that you experienced in the box below" [formControl]="issueReportMessage" rows=6></textarea>
            <hr/>
            <div class="buttons">
                <button class="button" (click)="sendIssueReport()">
                    Report Issue
                </button>
                <button class="button is-danger is-outlined" (click)="isShowingReport = false">
                    Cancel
                </button>
            </div>
        </div>
    </div>

    <div class="info-overlay" *ngIf="isShowDocuments && !asmtFmrk.showDocumentsSplitScreen">
        <div class="info-box is-maximized documents-container">
            <div [ngStyle]="{'font-size.em': getZoomValue()}">
                <documents-display (toggleDocuments)="toggleDocuments()" [showCloseButton]="true" [documentViews]="documentViews" (selectDocumentView)="selectDocumentView($event)" [selectedDocumentId]="selectedDocumentId" [activeDocument]="activeDocument" [containingWidth]="getQuestionRunnerWidth('main')" [containingWidthSpill]="getQuestionRunnerSpillover('main')" [allowSubtitles]="isAllowedSubtitles()" [allowTranscripts]="isAllowedTranscripts()" [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()" [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"></documents-display>
            </div>
        </div>
    </div>

    <div class="info-overlay" *ngIf="isShowingTime">
        <div class="info-box">
            <ng-container *ngIf="isBCED()">
                <small><tra slug="{{isBCED() ? 'test_time_message_bc' : 'test_time_message_bc'}}"></tra></small>
                <hr/>
                <div >
                    <div> <strong><tra slug="test_time_elapsed"></tra></strong> </div>
                    <div class="test-time">{{getElapsedTestTime()}}</div>
                    <ng-container *ngIf="asmtFmrk.recommendedTime">
                        <hr/>
                        <div><strong><tra slug="test_time_recommended"></tra></strong></div>
                        <div class="test-time" [innerHTML]="asmtFmrk.recommendedTime"></div>
                    </ng-container>
                    <ng-container *ngIf="asmtFmrk.extraTime">
                        <hr/>
                        <div><strong><tra slug="test_time_extra"></tra></strong></div>
                        <div class="test-time">{{asmtFmrk.extraTime}}</div>
                    </ng-container>
                </div>
            </ng-container>
            <div *ngIf="!isBCED()">                
                <div> <strong><tra slug="lbl_timerem"></tra></strong> </div>
                <div style="font-size:1.4em;">{{regularTimeRemaining}}</div>
            </div>
            <!-- <hr/> -->
            <!-- <div> <strong>Extra Time Remaining:</strong> </div> -->
            <!-- <div>02:00</div> -->
            <hr/>
            <div>
                <button class="button is-fullwidth" (click)="isShowingTime=false">
                    OK
                </button>
            </div>
        </div>
    </div>

    <div class="chat-overlay" *ngIf="isShowingChat">
        <div class="info-box">
            <div>
                <button class="button is-fullwidth" (click)="isShowingChat=false">
                    Close
                </button>
            </div>
            <hr/>
            <div>
                <test-taker [uid]="auth.user().value.uid" [instit_group_id]="instit_group_id" [markingPoolId]="testSessionId" [chatService]="chatService"></test-taker>
            </div>
        </div>
    </div>

    <div class="save-overlay" [class.is-activated]="isSavingResponse && !isQuietlySavingResponse">
        <div class="overlay-bg"></div>
        <div class="overlay-text">
            <span class="tag is-warning"> <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>&nbsp; Saving...</span>
        </div>
    </div>

    <!-- <div style="top:0em; left:0em;" *ngIf="!isTestNavExpanded">
        <button class="button" (click)="toggleTestNav()">
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
    </div> -->

    <div class="expander" style="top:0em; right:0em;" *ngIf="!isToolbarExpanded && showToolbar()">
        <button class="button" (click)="toggleToolbar()">
            <i class="fa fa-angle-double-left" aria-hidden="true"></i>
        </button>
    </div>

    <!-- *ngIf="isFlushNavigation()" -->
    <div class="navigation-panel-container" [class.is-wider]="lang.c() === 'fr'" >
        <div
            *ngIf="toolToggles.showFill"
            class="drawing-panel annotation-tool-close color-palette"
        >
            <div *ngFor="let color of getColors()"
                class="color-square" 
                [style.backgroundColor]="color"
                (click)="setFillColor(color)"
            ></div>
        </div>
        <div class="reading-text-controls" [class.pj-reading-controls]="isPj" [class.g6-reading-controls]="isG6" *ngIf="isShowSplitScreenControlBtn()">
            <ng-container [ngSwitch]="isShowCutsomSplitScreenControls()">
                
                <ng-container *ngSwitchCase = "true">
                    <!-- In ABED the Control Button labels are left, Split, Right -->
                    <button class="button reading-text-btn" (click)="isLtrSplitView() ? readSelViewFull() : readSelViewClose()" audio-slug [slug]="getReadselCloseSlug()" [class.is-active]="(isLtrSplitView() ? isShowingRight() : isShowingLeft()) && !isShowingSplitScreen()">
                        <span><tra [slug]="getReadselCloseSlug()"></tra></span>   
                    </button>
                    <button class="button has-icon reading-text-btn" (click)="readSelViewHalf()" audio-slug [slug]="getReadselHalfSlug()" [class.is-active]="isShowingSplitScreen()">
                        <span class="icon"><i class="fas fa-columns"></i></span>
                        <span><tra [slug]="getReadselHalfSlug()"></tra></span>
                    </button>
                    <button class="button reading-text-btn" (click)="isLtrSplitView() ? readSelViewClose() : readSelViewFull()" *ngIf="!asmtFmrk.isOrale" audio-slug [slug]="getReadselFullSlug()" [class.is-active]="(isLtrSplitView() ? isShowingLeft() : isShowingRight()) && !isShowingSplitScreen()">
                        <span><tra [slug]="getReadselFullSlug()"></tra></span>
                    </button>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <button class="button has-icon reading-text-btn" (click)="readSelViewClose()" audio-slug [slug]="getReadselCloseSlug()">
                        <span *ngIf="!isPj"  class="icon"><i class="fas fa-caret-square-right"></i></span>
                        <img *ngIf="isPj" class="icon" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/515714/authoring/Asset_84/1643726691127/Asset_84.png">
                        <span><tra [slug]="getReadselCloseSlug()"></tra></span>   
                    </button>
                    <button class="button has-icon reading-text-btn" (click)="readSelViewHalf()" audio-slug [slug]="getReadselHalfSlug()">
                        <span *ngIf="!isPj"  class="icon"><i class="fas fa-columns"></i></span>
                        <img *ngIf="isPj" class="icon" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/515714/authoring/split screen/1637963557540/split screen.png">
                        <span><tra [slug]="getReadselHalfSlug()"></tra></span>
                    </button>
                    <button class="button has-icon reading-text-btn" (click)="readSelViewFull()" *ngIf="!asmtFmrk.isOrale" audio-slug [slug]="getReadselFullSlug()">
                        <span *ngIf="!isPj" class="icon"><i class="far fa-square"></i></span>
                        <img *ngIf="isPj" class="icon" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/515714/authoring/full screen/1637963572708/full screen.png">
                        <span><tra [slug]="getReadselFullSlug()"></tra></span>
                    </button>
                </ng-container>                
            </ng-container>
        </div>

        <button 
            *ngIf="!showDeactivatedMode && isShowOverlay" 
            class="annotation-tool-close"
            [class.pj-annotation-tool-close]="isPj"
            [class.g6-annotation-tool-close]="isG6"
            (click)="removeAllDrawings()" 
            audio-slug [slug]="getRemoveDrawingSlug()"
        >
            <tra [slug]="getRemoveDrawingSlug()"></tra>
        </button>
        
        <button 
            *ngIf="!showDeactivatedMode && isShowOverlay" 
            class="annotation-tool-close"
            [class.pj-annotation-tool-close]="isPj"
            [class.g6-annotation-tool-close]="isG6"
            (click)="disableOverlay()"
            audio-slug
            [slug]="getCloseDrawingSlug()"
        >
            <tra [slug]="getCloseDrawingSlug()"></tra>
        </button>

        <div class="custom-nav-button-container" *ngIf="useCustomNextPrev() && showCustomButtonsBotRight()">
            <custom-nav-button *ngIf="useCustomPrev()" (click)="gotoPrev()" [isBold]="isCustomPrevBold()" [fgColor]="getCustomPrevFgColor()" [bgColor]="getCustomPrevBgColor()" [text]="getCustomPrevText()" [isPrev]="true"></custom-nav-button>
            <custom-nav-button *ngIf="useCustomNext()" (click)="gotoNext()" [isBold]="isCustomNextBold()" [fgColor]="getCustomNextFgColor()" [bgColor]="getCustomNextBgColor()" [text]="getCustomNextText()" [showStar]="showNextStar()"></custom-nav-button>
        </div>

        <ng-container *ngIf="showPrevNext() && useNextPrevButtons()">
            <div class="navigation-panel">
                <button audio-slug [slug]="getPrevBtnSlug()" class="toolbar-icon navigation-icon left-nav-button" (click)="gotoPrev()">
                    <div class="nav-button-div" [class.is-smaller-font]="isABED() && lang.c() == 'fr'">
                        <i class="fas fa-arrow-left"></i> 
                        <tra [slug]="getPrevBtnSlug()"></tra>
                    </div>
                </button>
                <button audio-slug [slug]="getNextBtnSlug()" class="toolbar-icon navigation-icon" (click)="gotoNext()">
                    <div class="nav-button-div" [class.is-smaller-font]="isABED() && lang.c() == 'fr'">
                        <i class="fas fa-arrow-right"></i> 
                        <tra [slug]="getNextBtnSlug()"></tra>
                    </div>
                </button>
            </div>
        </ng-container>
    </div>

</div>


<div *ngIf="isPrintMode && !isShowingResults" class="printed-test-form">
    <div>
        <strong> Test Form {{testFormId}}</strong>
        <!-- <div>The following sheets of paper must be shredded after digitization.</div> -->
    </div>
    <!-- section.isShuffleDisabled &&  !isShowQuestionLabel-->
    <div *ngFor="let section of testRunnerSections; let index = index" >
        <div class="section-block">
            <h1><tra [slug]="getSectionTitleSlug()"></tra> {{index+1}}</h1>
            <ng-container *ngFor="let preambleContent of getSectionPreambleContent(section)">
                <question-runner 
                    [currentQuestion]="preambleContent" 
                    [containingWidth]="getQuestionRunnerWidth('main')"
                    [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                    [allowSubtitles]="isAllowedSubtitles()"
                    [allowTranscripts]="isAllowedTranscripts()"
                    [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                    [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                    [frameWorkTags]="frameWorkTags"
                ></question-runner>
            </ng-container>
        </div>
        <div class="question-block double-font-size" *ngFor="let question of section.questions; let qindex = index">
            <div style="border-top: 0.3em solid #666; padding:0.2em 0em;  font-weight:600; margin-top:1em;">
                <tra [slug]="getSectionTitleSlug()"></tra> {{index+1}}, Question {{qindex+1}}
                <span *ngIf="isShowQuestionLabel">[{{getQuestionLabel(question)}}]</span>
            </div>
            <question-runner 
                [currentQuestion]="getQuestionDef(question)" 
                [containingWidth]="getQuestionRunnerWidth('main')"
                [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                [allowSubtitles]="isAllowedSubtitles()"
                [allowTranscripts]="isAllowedTranscripts()"
                [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                [frameWorkTags]="frameWorkTags"
            ></question-runner>
        </div>
    </div>
</div>

<ng-container *ngIf="isShowingResults && !isResultsDisabled()">
<div *ngIf="isPrintMode" class="printed-page" style="padding-left:0.5em; color:#000">

    <div class="dont-print" style="margin-top:1em;margin-bottom:1.5em;">
        <button class="button" (click)="exitExportResults()"> 
            <i style="margin-right:0.5em" class="fas fa-arrow-left"></i>
            <tra slug="btn_back"></tra>
        </button>
        <button class="button" (click)="triggerPrintDialog()"> 
            <i style="margin-right:0.5em" class="fas fa-print"></i>
            <tra slug="btn_print"></tra>
        </button>
    </div>

    <div>
        <div style="font-size:1.7em; line-height:1.3em; margin-bottom:0.5em;">
            <div [innerHtml]="getAssessmentName()"></div>
            <div><strong><tra slug="lbl_results_export"></tra></strong></div>
        </div>
        <div style="margin-bottom:0.8em;" *ngIf="showReportOptions(reportOptions.STUDENT_PEN)">
            <strong><tra slug="tr_student_pen"></tra> </strong>
            {{getTestTakerID()}}
        </div>
        <div style="margin-bottom:0.8em;">
            <tra slug="lbl_completed_on"></tra><tra slug="txt_colon"></tra>
            {{getWritingDate()}}
        </div>
    </div>

    <div style="border: 1px solid #000; margin-bottom:0.8em">
        <div style="    font-size: 1.2em; font-weight: 600; background-color: rgb(163, 188, 235); color: #000; padding: 0.2em;">
            <tra slug="tr_results_sr_questions"></tra> {{showReportOptions(reportOptions.SCORE)? '(points)' :''}}
        </div>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap;;">
            <ng-container *ngFor="let resultQ of resultQs">
                <div *ngIf="!isQuestionUnmarked(resultQ.qId)" [class.is-green]="isQuestionCorrect(resultQ.qId)" style="display: flex; flex-grow: 1; flex-direction:row; width:12em; justify-content: space-between; border:1px solid #000;    padding: 0.2em; margin-right: -1px;">
                    <div style="max-width:8em; overflow: hidden; text-overflow: ellipsis;">{{getQuestionTitleFromId(resultQ.sId, resultQ.qId)}}</div>
                    <div style="display: flex; flex-direction: row; align-self: flex-end; ">
                        <ng-container *ngIf="showReportOptions(reportOptions.CHECKMARK)">
                            <div style="margin-right: 0.5em;" [ngSwitch]="isQuestionCorrect(resultQ.qId)">
                                <i *ngSwitchCase="true" class="fas fa-check"></i>
                                <i *ngSwitchCase="false" class="fas fa-times is-red"></i>
                            </div>
                        </ng-container>
                        <div *ngIf="showReportOptions(reportOptions.SCORE)">({{getCorrectItemScore(resultQ.qId, true) }}/{{getQuestionWeight(resultQ.qId, asmtFmrk?.reportOptions?.autoCalcQuesWeight)}})</div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div style="padding: 0.2em" *ngIf="showReportOptions(reportOptions.SCORE)">
            <tra slug="tr_results_score_total"></tra> : {{finalReportStats.correctSRScore}}/{{finalReportStats.totalSRScore}}
        </div>
    </div>
    

    <div *ngIf="finalReportStats.numCRQuestions && finalReportStats.numCRQuestions>0" style="border: 1px solid #000; margin-bottom:0.8em">
        <div style="    font-size: 1.2em; font-weight: 600; background-color: rgb(163, 188, 235); color: #000; padding: 0.2em;">
            <tra slug="tr_results_cr_questions"></tra>
        </div>
        <div style="padding: 0.2em">
            <tra slug="lbl_num_responses"></tra><tra slug="txt_colon"></tra> 
            {{finalReportStats.numCRQuestions}}
        </div>
    </div>
    
    <div class="question-block double-font-size" *ngFor="let question of resultQs" >
        <ng-container *ngIf="isQuestionUnmarked(question) && getPrintElement(question)">
            <!-- <div style="border-top: 0.3em solid #666; padding:0.2em 0em;  font-weight:600; margin-top:1em; display: flex; flex-direction: column;"> -->
                <!-- <span>{{getQuestionTitleFromId(question)}}</span> -->
                <!-- <span *ngIf="!isQuestionUnmarked(question)"> -->
                    <!-- <tra slug="tr_results_score"></tra> {{getQuestionScore(question)}}/{{getQuestionTotalScore(question)}} -->
                <!-- </span> -->
                <!-- <span *ngIf="isQuestionUnmarked(question)"> -->
                    <!-- <tra slug="tr_results_score_pending"></tra> -->
                <!-- </span> -->
            <!-- </div> -->
            <div style="margin-bottom:0.8em; border:1px solid #ccc;">
                <question-runner
                    [currentQuestion]="getResultQuestionDef(question)"
                    [questionState]="getResultState(question)"
                    [containingWidth]="getQuestionRunnerWidth('main')"
                    [containingWidthSpill]="getQuestionRunnerSpillover('main')"
                    [isSubmitted]="true"
                    [isPrintMode]="true"
                    [allowSubtitles]="isAllowedSubtitles()"
                    [allowTranscripts]="isAllowedTranscripts()"
                    [allowAudioPlaybackSpeed]="isAllowedAudioPlaybackSpeed()"
                    [allowVideoPlaybackSpeed]="isAllowedVideoPlaybackSpeed()"
                    [frameWorkTags]="frameWorkTags"
                ></question-runner>
                <div style="font-size: 0.8em; text-align: right; padding: 0.5em; font-style: italic;">
                    <span>
                        <tra slug="lbl_submitted_by"></tra>  {{getTestTakerID()}}
                        (<tra slug="lbl_student_pen"></tra>)
                        <tra slug="snippet_for"></tra>
                    </span>&nbsp;
                    <span [innerHTML]="getAssessmentName(true)" ></span>
                </div>
            </div><br>
            <div *ngIf="getQuestionRubric(question)">
                <div class="pagebreak">
                </div>
                <element-render [contentElement]="getQuestionRubric(question)" isLocked=true>
                </element-render>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="pageMode === PageMode.TEST_RUNNER" class="score-legend-box">
    <div  class="score-box" *ngIf="showReportOptions(reportOptions.SCORE)">
        <div><tra slug="tr_results_score"></tra></div>
        <!-- getQuestionScoreDisplay(getActiveQuestionId()) -->
        <div *ngIf="!isQuestionUnmarked(getActiveQuestionId())">{{getCorrectItemScore(getActiveQuestionId(), true)}}/{{getQuestionWeight(getActiveQuestionId(), asmtFmrk?.reportOptions?.autoCalcQuesWeight)}}</div>
        <!-- <img *ngIf="isQuestionUnmarked(getActiveQuestionId())" style="width: 1.1em" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/yellowclock/1612215246552/yellowclock.png"> -->
        <i *ngIf="isQuestionUnmarked(getActiveQuestionId())" class="fas fa-keyboard fa-fw" style="color: #fcbc14; font-size: 1.1em"></i>
    </div>
    <ng-container *ngIf="showReportOptions(reportOptions.LEGEND)">
        <results-legend [numCRQuestions]="!!finalReportStats.numCRQuestions"></results-legend>
    </ng-container>
</div>
</ng-container>

<div class="custom-modal" *ngIf="cModal()">
    <div class="modal-contents">
        <div [ngSwitch]="cModal().type">
            <accessibility-settings-modal *ngSwitchCase="TrModal.ACC_SETTINGS" [accessibilitySettings]="asmtFmrk.accessibilitySettings"></accessibility-settings-modal>
        </div>
        <modal-footer [pageModal]="pageModal"></modal-footer>
    </div>
</div>

<div class="custom-modal" *ngIf="showStudentLogin()">
    
    <form [formGroup]="formGroup" class="modal-contents" (ngSubmit)="tryStudentAuth()">
        <div>{{reSignInTitle}}</div>
        <div class="field">
            <label class="label">
              <tra *ngIf="whitelabel.isABED()" slug="abed_access_code"></tra>
              <tra *ngIf="!whitelabel.isABED()" slug="access_code"></tra>
            </label>
            <div class="control">
              <input [formControl]="formGroup.controls.accessCode" class="input is-success" type="text">
            </div>
        </div>
        <div class="field">
            <label class="label">
              <tra [slug]="getStudentIdentificationNumber()"></tra>
            </label>
            <div class="control">
              <input [formControl]="formGroup.controls.userId" class="input is-success" type="text">
            </div>
        </div>
        <div *ngIf="isDobReq()" class="field">
            <label class="label">
              <tra slug="abed_dob_lbl"></tra>
            </label>
            <div class="control">
              <dob-input [formControlModel]="formGroup.controls.date_of_birth"> </dob-input>
        </div>
        <div>
            <input 
              type="submit"
              value={{reSignInText}}
              id="signIn"
              class="button is-link is-fullwidth"
              [disabled]="isLoggingIn"
            />
        </div>
    </div>
    </form>
</div>


<div class="debug-button" (click)="triggerDebugModeAgg()"> </div>
<div class="debug-box" *ngIf="isDebugMode">
    <div class="debug-content" cdkDrag>
        <div class="debug-current">
            <div class="debug-drag-bar" cdkDragHandle></div>
            <div class="panel-name"><b>Current Panel:</b> {{getSourceFormId()}}</div>
            <div><b>Current Label:</b> {{getQuestionLabel(getCurrentQuestionId())}}</div>
            <div><b>Current ID:</b> {{getCurrentQuestionId()}}</div>
            <div style="margin-top:1em"><button (click)="isDebugMode = false">x Close Debug Mode</button></div>
        </div>
        
        <div class="debug-last" *ngIf="lastResponseDebug">
            <div><b>Last Label:</b> {{lastResponseDebug.label}}</div>
            <div><b>Last Score:</b> {{lastResponseDebug.score}} / {{lastResponseDebug.weight}}</div>
            <div><b>Last Response:</b> {{lastResponseDebug.formatted_response}}</div>
        </div>
    </div>
</div>