import { API_ADDRESS_LOCAL, API_ADDRESS_LOCALB } from "../../api/api-addr/l";
import { IContextData } from "./common";

export const SCHL_BOARDS:IContextData = {
  id: 'SCHL_BOARDS',
  homepageRoute: '/en/login-router-st',
  brandName: 'Vretta',
  logo: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/392800/logo_vretta.png',
  footer: null,
  apiAddress: (hostname:string) => {
    switch(hostname){
      case 'localhost': 
        return API_ADDRESS_LOCAL;
        return 'https://dsbn-api.vretta.com';  
      case 'abed.vretta.com':
        case 'dt6dnz30h5udd.cloudfront.net':
        return 'https://abed-api.vretta.com';
      case 'abed-qc.vretta.com':
        return 'https://abed-qc-api.vretta.com';
      case 'abed-qc-pasi.vretta.com':
          return 'https://abed-qc-pasi-api.vretta.com';
      case 'abed-uat.vretta.com':
        return 'https://abed-uat-api.vretta.com';
      case 'abed-uat-auth.vretta.com':
        return 'https://abed-uat-auth-api.vretta.com';
      case 'dsbn.vretta.com':
        return 'https://dsbn-api.vretta.com';  
      default:
        return 'https://abed-api.vretta.com';
    }
  },
  siteFlags: {
    'IS_ABED': true, // please use sparingly
    'IS_SA_STUDENT_ID': true,
    'IS_SA_DOB_MAIN': true,
    'IS_SA_SESSION_DISABLED': true, // temp
    'IS_SPELLCHECK': true,
    'IS_CKEDITOR': true,
    'IS_TIMER_ENABLED': false,
    'IS_VEA': true,
    'IS_TTS_SOMETIMES': true,
    'ENABLE_LISTEN_TOOL': true,
    'INST_MNG_TT': true,
    'TEST_CTRL_REPORT_EXPLR': true,
    'TEST_CTRL_DATA_QUERY': true,
    'IS_TC_SUMM': false,
    'IS_LANDING_GUIDE': true,
    'IS_LANDING_PUBLIC_PRACTICE': true,
    'IS_TC_SUMM_DETAILED': true,
    'IS_STU_REC_PASI': true,
    'IS_STU_LANG_SELECTOR': true,
    'IS_STU_DOB_LOGIN': true,
    'IS_STU_LOGIN_SELFREG': false,
    'IS_INVIG_SLOCK_CTRL': false,
    'IS_INVIG_ABSNC': true,
    'IS_INVIG_SESSION_SINGLE': true,
    'IS_DIRECT_SCHED': true,
    'IS_INVIG_SECTIONS_ALLOWED_DISABLED': true,
    'IS_SCORER_REPORT': false,
    'IS_SCOR_CARD_DETAIL': false,
    'IS_ISSUE_REV_SUBMIT_DISABLED': true,
    'IS_STU_WORDCOUNT_TOGGLE': true, // todo: currently only works with IS_STU_WORDCOUNT_SIMPLE
    'IS_STU_WORDCOUNT_SIMPLE': true,
    'IS_STU_ESSAY_SPACING': true,
    'IS_STU_ESSAY_PREVIEW': true,
    'IS_STU_NUMBER_INPUT_NOISY': true,
    'IS_SCH_CONFAGR': false,
    'IS_SESSION_PRACTICE_ONLY': true,
    'IS_LOGIN_AGR': true,  // controls visibility of the login agreement
    'IS_LANG_HEADER_SWITCH': true,
    'IS_SCORE_PROGRESS_COLLAPSED': true,
    'ALLOW_TEACHER_UNSUBMIT': true,
    'IS_RESULTS_PAGE_AVAILABLE': true,
    'IS_MRKG_VIEW_QUESTIONS_HIDDEN': true,
    'IS_SA_SC_CREATE': true,
    'IS_SA_IMPORT_TEACHER': false,
    'IS_SA_IMPORT_STUDENT': false,
    'IS_SCORE_MSG_CENTRE': false,
    'IS_SCORE_RESP_BOOKEND': false,
    'IS_SCOR_SCALES_HELP': false,
    'IS_SCORE_RESP_DBL_SPACE': true,
    'IS_SCORE_LEAD_DIRECT_ADD_ACCT': false,
    'IS_SCORE_HIDE_DROPDOWN': true,
    'IS_TW_DUR': false,
    'DISABLE_TS_DATE_MOD': true,
    'IS_ALLOW_DISABLE_SOFTLOCK_AFTER_NOTIFICATION': true,
    'IS_ENABLE_PROMPT_BEFORE_SOFTLOCK_PAUSE': false,

  },
  siteText: {
    copyright: ' © Vretta Inc. 2024',
    supportEmail: 'support@vretta.com',
    supportPhone: '',
    my_inst: 'My School',
    my_inst_admin: 'You are the **administrator** of this school.',
    my_inst_manage_acct: 'Manage Teachers+',
    currentLogo: 'https://www.vretta.com/logo.svg',
    urlScorInvitTemplate: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/ABED_Scoring_Invitation_Wave_Template_v3/1700165152771/ABED_Scoring_Invitation_Wave_Template_v3.xlsx',
    urlrescoreTemplate: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/441403/authoring/ABED_Rescore_Template_v1/1721990747498/ABED_Rescore_Template_v1.xlsx',
    student_ident: "student_account_num_abed",
    student_ident_full: "sdc_1_student_gov_id_full_abed",
    student_ident_show: "show_ASN_ABED",
    student_ident_2: "lbl_sasn",
    student_ident_invalid: 'abed_invalid_ASN',
    invigilationIntro: 'lbl_student_post_ABED',
    studentIdProp: "StudentIdentificationNumber",
    ttaker_ident: "Test Taker Login ID",
    login_admins: 'login_staff_member',
    btn_line_reader: 'btn_line_reader_abed',
    btn_remove_drawings: 'btn_remove_drawings_abed',
    draw_tool_exit_g9: 'draw_tool_exit_abed',
    teacher_title: 'abed_teacher',
    returning_user_title: "abed_returning_users",
    email_title: "abed_email",
    lbl_op_asmts: 'abed_lbl_op_asmts',
    password_title: "abed_password",
    STU_ID_MASK: 'XXXX-XXXX-X',
    DOB_MASK: 'XXXX-XX-XX',
    DOB_YEAR_MIN: '1930',
    STU_ACCOMM_TTS_SLUGS: 'abed_t2s,abed_readeraudio_mp3_vi_accom,audio_mp3_lpd_accom',
    STU_ACCOMM_MULT_TABLE_SLUGS: 'abed_12x12',
    SITE_SCORLEAD_CHEATSHEET:'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/mark_counts/1701314558327/mark_counts.png',
    logo_white: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Alberta-logo/1703865928160/Alberta-logo.png',
    st_michaels_logo: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/515714/authoring/St._Michael's_CS_logo_trans/1666384753966/St._Michael's_CS_logo_trans.png",
    tc_group_id: "6397",
  },
  hosts: [
      'dsbn.vretta.com',
      'ey.vretta.com',
      'localhost:4200'
  ],
  defaultTimezone: 'America/Toronto',
}
