<div class="modal-buttons">
    
    <button 
        [disabled]="pageModal.isSaving" 
        class="button" 
        *ngIf="!pageModal.getCurrentModal().isProceedOnly"
        (click)="pageModal.closeModal()"
    > <tra [slug]="closeMessage"></tra> </button>

    <button 
        *ngIf="confirmButton"
        [disabled]="isEditDisable || pageModal.isSaving" 
        class="button is-info" 
        (click)="confirmSubmission()"
        [ngSwitch]="!!pageModal.isSaving"
    >
        <span *ngSwitchCase="false">
            <tra [slug]="pageModal.getCurrentModal().confirmationCaption || 'btn_ok'"></tra>
        </span>
        <span *ngSwitchCase="true">
            ...
        </span>
    </button>

</div>
