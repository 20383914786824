<div *ngIf="isLoading">
    Loading...
</div>
<div style="margin-top:1em;">
    <div class="buttons">
        <button 
            *ngFor="let df of dataFrames"
            [class.is-info]="isDfSelected(df.slug)"
            (click)="selectDf(df.slug)"
            class="button is-small"
        >
            {{df.title}}
        </button>
    </div>
    <div class="space-between" *ngIf="false">
        <div style="display: flex; gap: 1em;">
            <mat-slide-toggle [(ngModel)]="dataLoadOptions.includeAsmtSample">
                Include Practice Exams
            </mat-slide-toggle>
            <mat-slide-toggle [(ngModel)]="dataLoadOptions.focusAsmtSwapRisk">
                Focus on Swap Risk
            </mat-slide-toggle>
        </div>
        
    </div>
    <div>

    </div>
    <div style="width: 100%;">
        <ng-container *ngFor="let df of dataFrames">
            <div 
                class="df-container" 
                *ngIf="isDfSelectedOrInited(df.slug)" 
                [class.is-invisible]="!isDfSelected(df.slug)"
            >
                <div *ngIf="!!getExportStartDate(df)" style="margin-bottom: 0.5em;">
                    Recalculated on {{getExportFormattedDate(df)}}
                </div>
                <ag-grid-angular
                    class="ag-theme-alpine ag-grid-fullpage"
                    [rowData]="df.records"
                    [gridOptions]="df.gridOptions"
                    [enableCellTextSelection]="true"
                ></ag-grid-angular>
                <div style="margin-top:1em; gap: 4em;" class="space-between">
                    <div style="text-align:center;">
                        <div>
                            {{getFilteredRowCount(df)}}
                        </div>
                        <button class="button is-small" (click)="exportCsv(df)">
                            Export to CSV
                        </button>
                    </div>
                    <div>
                        {{df.note}}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div style="margin-top:1em;">
        <div>
            <button (click)="updateTwStats()" [disabled]="isStatsReloadTriggering" class="button has-icon">
                <span class="icon"><i class="fa fa-cogs" aria-hidden="true"></i> </span>
                <span>Recalculate Attempt Stats</span>
            </button>
            <button class="button is-dark has-icon" [disabled]="isLoading" (click)="loadData()">
                <span class="icon"><i class="fas fa-sync" aria-hidden="true"></i> </span>
                <span>Re-pull Calculated Stats</span>
            </button>
        </div>
        <div style="margin-top:1em">
            <div class="notification is-danger is-small" *ngIf="isStatsReloadTriggering">
                Attempt stats starting...
            </div>
            <div class="notification is-warning is-small" *ngIf="isStatsReloadTriggered">
                The data job has started. Please revisit/re-pull in the next minute or so to see the latest numbers.
            </div>
        </div>
    </div>

</div>