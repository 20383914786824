import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools, IEntryStateVirtualTools } from "../model";
import { EToolType, IPoint, VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { ARROW_LEFT, ARROW_RIGHT, BACKSPACE, BLACK, CARET_BLINK_SPEED, DEFAULT_TICK_MEASURE, ENTER, KEY_C, KEY_V, LAYER_LEVEL, SINGLE_QUOTE, SPACE } from '../types/constants'
import { OnDestroy, OnInit } from "@angular/core";
import { IExtendedTools } from "../element-render-virtual-tools.component";
import { ENamingTypes, EPixiTools } from "../types/types";
import { ElementType, QuestionState, ScoringTypes, getElementWeight } from "../../models";
import { ITextboxData } from "../model";

enum ACTIONS {
  DELETE = "DELETE",
  SIZE_UP = "SIZE_UP",
  SIZE_DOWN = "SIZE_DOWN",
  DUPLICATE = "DUPLICATE",
  NONE = "NONE"
}
enum textboxHash {
  TEXT = 'text',
  SELECT_OUTLINE = 'selectOutline',
  OUTLINE = 'outline',
  CARET = 'caret',
  TEXTBOX_CONTAINER = 'textBoxContainer',
  ROTATION_POINT = 'rotationPoint',
  CELL = 'cell',
  HIGHLIGHT = 'highlight'
}

export class TextBox extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  hcPrevState: boolean;
  staticBg: PIXI.Graphics;
  wasTextBoxSelected: boolean = false;
  activeTextBoxData: ITextboxData = null;
  activeMenu: PIXI.Graphics;
  cellHeight = 30;
  cellWidth = 60;
  padding = (this.cellHeight - 14)/2;
  textBoxesContainer: PIXI.Graphics[];
  singleTextBoxData: ITextboxData[] = [];
  singleTextboxes =  [];
  deleteSprite: PIXI.Sprite;
  upSprite: PIXI.Sprite;
  downSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  fontSize: number = 14;
  cellIdCounter: number;
  clipboard = '';
  zIndex: number;
  deselectAll_document_bind_this = this.deselectAll_document.bind(this)

  redrawTable: (tableIndex: number) => void;
  deselectTables: () => void;

  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    redrawTable: (tableIndex: number) => void,
    deselectTables: () => void,
    layerLevel: LAYER_LEVEL,
    spriteLoader: SpriteLoader
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.TEXTBOX, type: EToolType.BUTTON, layerLevel: layerLevel});

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()
    this.hcPrevState = this.textToSpeech.isHiContrast;  

    this.element = element;
    this.isGlobalRotating = isGlobalRotating;
    this.isGlobalDragging = isGlobalDragging;
    this.questionState = questionState;
    this.zIndex = this.getContainerZindex(7);

    this.spriteLoader = spriteLoader;

    this.deleteSprite = new PIXI.Sprite();
    this.upSprite = new PIXI.Sprite();
    this.downSprite = new PIXI.Sprite();
    this.dupeSprite = new PIXI.Sprite();
    this.textBoxesContainer = [];
    // this.textBoxesContainer.name = this.getName(ENamingTypes.CONTAINER);
    // this.textBoxesContainer.zIndex = this.getContainerZindex(4);

    this.redrawTable = redrawTable
    this.deselectTables = deselectTables;

    this.deselectAllTextBoxListener();

    this.addTextEventListener();

    // this.addGraphic(this.textBoxesContainer);

    this.loadAssets().then(this.initSprites);
  }

  initSprites = ({resources}) => {
    if (!resources || Object.keys(resources).length == 0){
      this.deleteSprite.texture = PIXI.utils.TextureCache.delete;
      this.upSprite.texture = PIXI.utils.TextureCache.up;
      this.downSprite.texture = PIXI.utils.TextureCache.down;
      this.dupeSprite.texture = PIXI.utils.TextureCache.duplicate;
    } else {
      this.deleteSprite.texture = resources.delete.texture;
      this.upSprite.texture = resources.up.texture;
      this.downSprite.texture = resources.down.texture;
      this.dupeSprite.texture = resources.duplicate.texture;
    }
  }
  createOutline = (outline: PIXI.Graphics, color: number, width: number, height: number, fillColor?: number) => {
    outline.lineStyle(1, color, 1);
    if(fillColor != null) {
      outline.beginFill(fillColor);
    }
    outline.drawRect(0,0, width, height)
    outline.endFill();
  }

  // Will only be called on creation from now on
  createTextBox(x: number, y: number, color: number, textBoxData: ITextboxData, isStandAlone?: boolean, tableContainer?: PIXI.Graphics) {
    if(textBoxData?.isRevoked) {
      return;
    }

    // const cell = new PIXI.Graphics();
    const textBoxContainer = new PIXI.Graphics();
    textBoxContainer.name = this.getName(ENamingTypes.CONTAINER);
    textBoxContainer.zIndex = this.zIndex;
    const cell = new PIXI.Graphics();
    const outline = new PIXI.Graphics();
    const selectOutline = new PIXI.Graphics();
    let isNewTextbox = false;

    if(isStandAlone && (!textBoxData || textBoxData == null)) {
        const defaultData: ITextboxData = {
          id: this.singleTextboxes.length,
          input: '', 
          points: [{x, y}],
          width: this.cellWidth, 
          height: this.cellHeight, 
          isRevoked: false,
          fontSize: this.fontSize,
          activeCharIndex: -1,
          zIndex: this.zIndex
        };
        this.singleTextBoxData.push(JSON.parse(JSON.stringify(defaultData)));
        textBoxData = this.singleTextBoxData[this.singleTextBoxData.length-1];
        isNewTextbox = true;
    } else if(!isStandAlone) {
      // this.singleTextBoxData.push(textBoxData);
      textBoxData.points[0].x = x;
      textBoxData.points[0].y = y;
    }

    textBoxData.id = this.singleTextboxes.length;

    textBoxData.zIndex = this.zIndex;

    textBoxData.isDrawn = true;
    textBoxData.color = color;

    // const caret = new Caret(this.render);
    const caret = new PIXI.Graphics;

    const text = new PIXI.Text('', {fontSize: textBoxData.fontSize});
    text.text = textBoxData.input;
    text.style.fill = BLACK;
    text.y = this.padding;
    text.x = this.padding;
    text.zIndex = 3;
    text.interactive = true;
    text.cursor = 'text';
  
    cell.beginFill(textBoxData.color, isStandAlone ? 0.000001 : 1);
    cell.drawRect(0,0, textBoxData.width || this.cellWidth, textBoxData.height || this.cellHeight);
    cell.interactive = true;
    cell.endFill();
    // cell.x = x;
    // cell.y = y;

    this.createOutline(outline, 0xbbbbbb, textBoxData.width || this.cellWidth, textBoxData.height || this.cellHeight);
    this.createOutline(selectOutline, 0x000000, textBoxData.width || this.cellWidth, textBoxData.height || this.cellHeight);
    outline.alpha = isStandAlone && textBoxData.input.length > 0 ? 0 : 1;
    selectOutline.alpha = 0;
    caret.alpha = 0;
    outline.zIndex = 1;
    selectOutline.zIndex = 2;

    const rotationPoint = new PIXI.Graphics();
    rotationPoint.beginFill(0x0000, 0.9);
    rotationPoint.drawCircle(cell.width, 0, 6);
    rotationPoint.endFill();
    rotationPoint.position.set(0 + cell.width, 0);
    rotationPoint.pivot.set(0 + cell.width, 0);
    rotationPoint.interactive = true;
    rotationPoint.cursor = 'ew-resize';

    const highlight = new PIXI.Graphics();
    
    cell.addChild(outline);
    cell.addChild(selectOutline);
    text.addChild(caret);
    cell.addChild(text);
    text.addChild(highlight);

    if(!textBoxData.isTableCell) {
      cell.addChild(rotationPoint);
    }

    textBoxContainer.addChild(cell);
    textBoxContainer.position.set(x,y);

    const textboxHashmap = new Object();
    textboxHashmap[textboxHash.TEXT] = text;
    textboxHashmap[textboxHash.SELECT_OUTLINE] = selectOutline;
    textboxHashmap[textboxHash.OUTLINE] = outline;
    textboxHashmap[textboxHash.CARET] = caret;
    textboxHashmap[textboxHash.TEXTBOX_CONTAINER] = textBoxContainer;
    textboxHashmap[textboxHash.ROTATION_POINT] = rotationPoint;
    textboxHashmap[textboxHash.CELL] = cell;
    textboxHashmap[textboxHash.HIGHLIGHT] = highlight;

    this.singleTextboxes.push(textboxHashmap);
    this.selectTextBoxListener(cell, textBoxData);
    this.editTextBoxListener(cell, textBoxData);

    if(textBoxData.isTableCell && textBoxData.isActive) {
      const caretPos = this.findCaretPosition(textBoxData, text)
      this.drawCaret(caret, textBoxData)
      caret.alpha = 1;
      caret.position.set(caretPos.x, caretPos.y);
    }

    this.selectCharListener(text, textBoxData);
    this.selectTextListener(text, textBoxData);

    if(isStandAlone) {
      this.TextBoxMenuListener(cell, textBoxData);
      this.textBoxDragListener(cell, textBoxContainer, textBoxData);
      this.addContainer(textBoxContainer, {...textBoxData})
      this.textBoxesContainer.push(textBoxContainer);

      this.deselectAll();
      if(isNewTextbox) {
        this.drawTextboxMenu(cell, textBoxData);
      }
    } else {
        tableContainer.addChild(textBoxContainer);
    }

    if(textBoxData.isActive || isNewTextbox) {
      this.selectTextbox(textBoxData);
    }

    this.updateState();

    return isStandAlone ? cell : textBoxContainer;
  }

  drawCaret(caret: PIXI.Graphics, textboxData: ITextboxData) {
    caret.clear();
    caret.beginFill(0x0000);
    caret.drawRect(0,0, 1, textboxData.fontSize);
    caret.endFill();
  }

  drawTextboxMenu(cell: PIXI.Graphics, textboxData: ITextboxData) {
    if(this.activeMenu) {
      this.activeMenu.destroy();
      this.activeMenu = null;
    }

    const menu = new PIXI.Graphics();
    const menuYOffset = 10;
    const menuHeight = 30;
    const menuWidth = 100;
    const itemGap = 20;
    const textContainer = this.singleTextboxes[textboxData.id][textboxHash.TEXTBOX_CONTAINER];
    menu.beginFill(0x333c42);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    const lowestPoint = this.getLowestPoint(cell, menu);
    menu.y = lowestPoint.y + menuYOffset;
    menu.x = lowestPoint.x;

    menu.endFill();
    menu.interactive = true;

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = 10;
    del.interactive = true;

    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    dupe.y = menuHeight / 2;
    dupe.x = del.x + itemGap;
    dupe.interactive = true;

    const fontSizeContainer = new PIXI.Graphics();
    fontSizeContainer.beginFill(0x0000, 0.15);
    fontSizeContainer.drawRoundedRect(0,0, 25, menuHeight/2, 5)
    fontSizeContainer.endFill();
    fontSizeContainer.position.set(dupe.x + itemGap, menuHeight/2 - ((menuHeight/2) / 2));

    const fontSize = new PIXI.Text(textboxData.fontSize.toString(), {fontSize: 8});
    fontSize.style.fill = 0xffffff;
    fontSize.y = (fontSizeContainer.height / 2) - (fontSize.height / 2);
    fontSize.x = 5;

    const up = new PIXI.Sprite();
    up.texture = this.upSprite.texture;
    up.scale.set(0.02);
    up.anchor.set(0,0);
    up.y = -2;
    up.x = fontSizeContainer.width + 5;
    up.interactive = true;

    const down = new PIXI.Sprite();
    down.texture = this.downSprite.texture;
    down.scale.set(0.02);
    down.anchor.set(0,0);
    down.y = fontSizeContainer.height / 2 + 2;
    down.x = fontSizeContainer.width + 5;
    down.interactive = true;


    fontSizeContainer.addChild(fontSize);
    fontSizeContainer.addChild(up);
    fontSizeContainer.addChild(down);

    menu.addChild(del);
    menu.addChild(dupe);
    menu.addChild(fontSizeContainer);
    this.addMenuListeners(textContainer, ACTIONS.DELETE, del, textboxData);
    this.addMenuListeners(cell, ACTIONS.SIZE_UP, up, textboxData, fontSize);
    this.addMenuListeners(cell, ACTIONS.SIZE_DOWN, down, textboxData, fontSize);
    this.addMenuListeners(cell, ACTIONS.DUPLICATE, dupe, textboxData);
    this.addMenuListeners(cell, ACTIONS.NONE, menu, textboxData);
    
    this.activeMenu = menu;
    textContainer.addChild(menu);
    return menu;
  }

  TextBoxMenuListener(cell: PIXI.Graphics, textboxData: ITextboxData) {
    const clearMenus = () => {
      if(this.activeMenu) {
        this.activeMenu.destroy();
        this.activeMenu = null;
      }
    }
    const selectTable = () => {
        clearMenus();
        this.drawTextboxMenu(cell, textboxData);
        this.render();
    }

    cell.on('pointerdown', selectTable)
  }

  selectTextbox(textBoxData: ITextboxData) {
    this.wasTextBoxSelected = true;
    if(!textBoxData.isActive) {
      this.deselectTextboxes();
      textBoxData.isActive = true;
      this.singleTextboxes[textBoxData.id][textboxHash.SELECT_OUTLINE].alpha = 1;
      this.singleTextboxes[textBoxData.id][textboxHash.ROTATION_POINT].alpha = 1;
      this.singleTextboxes[textBoxData.id][textboxHash.ROTATION_POINT].interactive = true;
      this.render();
    }
  }

  selectTextBoxListener(obj: PIXI.Graphics, textBoxData: ITextboxData) {
    const selectText = () => {
      this.selectTextbox(textBoxData);
    }

    obj.on('pointerdown', selectText);
  }

  editTextbox(textBoxData: ITextboxData) {
    this.activeTextBoxData = textBoxData;
    textBoxData.isEditing = true;
    this.singleTextboxes[textBoxData.id][textboxHash.TEXT].cursor = 'text';
    this.singleTextboxes[textBoxData.id][textboxHash.CARET].alpha = 1;
    this.singleTextboxes[textBoxData.id][textboxHash.SELECT_OUTLINE].alpha = 0;
    this.singleTextboxes[textBoxData.id][textboxHash.ROTATION_POINT].alpha = 0;
    this.singleTextboxes[textBoxData.id][textboxHash.ROTATION_POINT].interactive = false;

    this.drawCaret(this.singleTextboxes[textBoxData.id][textboxHash.CARET], textBoxData);
    this.updateTextInput(textBoxData);
    this.render();
  }

  editTextBoxListener(obj: PIXI.Graphics, textboxData: ITextboxData) {
    let _clicked = false;
    let _double;
    const highlight = this.singleTextboxes[textboxData.id][textboxHash.HIGHLIGHT]
    const textContainer = this.singleTextboxes[textboxData.id][textboxHash.TEXT]
    const pointerdown = (e) => {
      if(_clicked){
          if(!textboxData.isEditing) {
            this.editTextbox(textboxData);
          } else {
            this.highlightAllText(highlight, textContainer, textboxData);
            this.render();
          }
      }
      _clicked = false;
      clearTimeout(_double);

    };

    const pointerup = (e) => {
        _clicked = true;
        _double = setTimeout(() => { _clicked = false; }, 300); // time for double click detection
    };
    
    obj.on('pointerdown', pointerdown).on('pointerup', pointerup)
  }

  deselectTextboxes() {
    this.activeTextBoxData = null;
    //[text, selectOutline, outline, caret]
    this.singleTextboxes.forEach(textbox => {
      textbox[textboxHash.SELECT_OUTLINE].alpha = 0
      textbox[textboxHash.CARET].alpha = 0
      textbox[textboxHash.TEXT].cursor = 'grab';
      textbox[textboxHash.ROTATION_POINT].alpha = 0;
      textbox[textboxHash.ROTATION_POINT].interactive = false;
      textbox[textboxHash.HIGHLIGHT].clear();
    })
    this.singleTextBoxData.forEach((data) => {
      data.isActive = false;
      data.isEditing = false;
      data.selectedStartIndex = null;
      data.selectedEndIndex = null;
    })
    this.deselectTables();
  }

  deselectAll() {
    if(!this.wasTextBoxSelected) {
      this.deselectTextboxes();
      if(this.activeMenu) {
        this.activeMenu.destroy();
        this.activeMenu = null;
      }
      this.render();

    } else {
      this.wasTextBoxSelected = false;
    }
  }

  deselectAllTextBoxListener() {
    this.stage.on('pointerup', this.deselectAll.bind(this));
  }

  deselectAll_document(event:MouseEvent) {

    if (event.target instanceof Element && event.target.parentElement.className == 'pixi-canvas') return;

    this.deselectTextboxes();
    if(this.activeMenu) {
      this.activeMenu.destroy();
      this.activeMenu = null;
      this.render();
    }
    
  }

  removeDeselectAllTextBoxListener_document() {
    document.removeEventListener('mouseup',this.deselectAll_document_bind_this);
  }


  textBoxDragListener(cell: PIXI.Graphics, container: PIXI.Graphics, textboxData: ITextboxData) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    cell.cursor = 'grab';
    cell.interactive = true;
    const textboxIndex = textboxData.id

    const rotationPoint = this.singleTextboxes[textboxIndex][textboxHash.ROTATION_POINT];

    let isRotating = false;

    const onRotateStart = (e) => {
      isRotating = true;

      this.stage.on('pointermove', onRotate).on('pointerup', onRotateEnd);
    }
    const onRotateEnd = (e) => {
      isRotating = false;
      const lowestPoint = this.getLowestPoint(cell, this.activeMenu);
      this.activeMenu.position.y = lowestPoint.y + 10;
      this.activeMenu.position.x = lowestPoint.x
      this.activeMenu.alpha = 1;

      this.render();

      this.stage.removeListener('pointermove', onRotate).removeListener('pointerup', onRotateEnd)
    }
    const onRotate = (e: PIXI.InteractionEvent) => {
        if(isRotating) {
          this.activeMenu.alpha = 0;
          const mousePosition = e.data.getLocalPosition(this.stage);
          const rotation = Math.atan2(container.y - mousePosition.y, container.x - mousePosition.x) + Math.PI;

          if(this.getDegrees(rotation) <= 1 || this.getDegrees(rotation) >= 359) {
            cell.rotation = this.getRadians(0);
          } else {
            cell.rotation = rotation;
          }

          this.render();
        }
    }

    rotationPoint.on('pointerdown', onRotateStart)
    
    const onDragStart = (e) => {
      if(!textboxData.isEditing) {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragging = true;
        initialDiffX = mousePosition.x - container.x
        initialDiffY = mousePosition.y - container.y
        cell.cursor = 'grabbing';

        this.stage.on('pointerup', onDragEnd).on('pointermove', onDragMove);
      }
    }
    const onDragEnd = (e) => {
        isDragging = false;
        cell.cursor = 'grab';

        textboxData.points[0].x = container.x;
        textboxData.points[0].y = container.y;

        this.updateState();

        this.stage.removeListener('pointerup', onDragEnd).removeListener('pointermove', onDragMove);
    }
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(isDragging && !isRotating) {
            this.isGlobalDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            container.x = mousePosition.x - initialDiffX; // obj x = 3
            container.y = mousePosition.y - initialDiffY;

            this.render();
        } else if(!isDragging) {
          this.isGlobalDragging = false;
        }
    }

    cell.on('pointerdown', onDragStart)
  }

  selectCharListener(textContainer: PIXI.Text, textboxData: ITextboxData) {
    const selectChar = (e) => {
      if(this.activeTextBoxData != null && textboxData.isActive) {
        const mousePosition = e.data.getLocalPosition(textContainer);

        const text = this.singleTextboxes[textboxData.id][textboxHash.TEXT];
        const cursor = {
          x: mousePosition.x,
          y: mousePosition.y
        }
  
        const caretPos = this.findCursorCaretPosition(textboxData, text, cursor)
        this.singleTextboxes[textboxData.id][textboxHash.CARET].position.set(caretPos.x, caretPos.y)
        textboxData.activeCharIndex = caretPos.index - 1;
  
        this.render();
      }
    }
    textContainer.on('pointerdown', selectChar)
  }

  selectTextListener(textContainer: PIXI.Text, textboxData: ITextboxData) {
    let clicked = false;
    let initialPoint: IPoint;
    let initialPos;

    const highlight = this.singleTextboxes[textboxData.id][textboxHash.HIGHLIGHT]

    const selectChar = (e) => {
      if(this.activeTextBoxData != null && textboxData.isActive) {
        const mousePosition = e.data.getLocalPosition(textContainer);
        clicked = true;
        highlight.clear();
        textboxData.selectedStartIndex = null;
        textboxData.selectedEndIndex = null;

        initialPoint = {
          x: mousePosition.x,
          y: mousePosition.y
        }

        initialPos = this.findCursorCaretPosition(textboxData, textContainer, initialPoint)
  
        this.render();

        this.stage.on('pointerup', pointerUp).on('pointermove', onDrag)
      }
    }
    const onDrag = (e) => {
      if(clicked) {
        highlight.clear();
        textboxData.selectedStartIndex = null;
        textboxData.selectedEndIndex = null;

        const mousePosition = e.data.getLocalPosition(textContainer);
        const currPoint = {
          x: mousePosition.x,
          y: mousePosition.y
        }

        const currPos = this.findCursorCaretPosition(textboxData, textContainer, currPoint)
        this.highlightText(highlight, textContainer, initialPos, currPos);
        this.render();

        textboxData.selectedStartIndex = Math.min(initialPos.index, currPos.index);
        textboxData.selectedEndIndex = Math.max(initialPos.index, currPos.index);
      }
    }

    const pointerUp = () => {
      clicked = false;

      this.stage.removeListener('pointerup', pointerUp).removeListener('pointermove', onDrag)
    }

    textContainer.on('pointerdown', selectChar)
  }

  highlightText(highlight: PIXI.Graphics, textContainer: PIXI.Text, initialPos, currPos) {

    const minIndex = Math.min(initialPos.index, currPos.index);
    let max;
    let min;
    if(initialPos.index == minIndex) {
      min = initialPos;
      max = currPos;
    } else {
      max = initialPos;
      min = currPos;
    }

    highlight.beginFill(0xb5d8ff, 0.8);
    const padding = 2;
    for(let i=min._line; i<=max._line; i++){
      if(i==max._line && max._line == min._line) { // if same line
        highlight.drawRect(min.x, currPos._fontHeight * i, max.x-min.x + padding, currPos._fontHeight)
      } else if(i==max._line) { // if different line and max line
        highlight.drawRect(0, currPos._fontHeight * i, max.x + padding, currPos._fontHeight)
      } else if(i==min._line) { // if different line and min line
        const maxWidth = PIXI.TextMetrics.measureText(currPos._lines[i], textContainer.style).width
        highlight.drawRect(min.x, currPos._fontHeight * i, maxWidth - min.x + padding, currPos._fontHeight)
      } else { // if between line 
        const maxWidth = PIXI.TextMetrics.measureText(currPos._lines[i], textContainer.style).width
        highlight.drawRect(0, currPos._fontHeight * i, maxWidth + padding, currPos._fontHeight)
      }
    }
    highlight.endFill();
  }

  highlightAllText(highlight: PIXI.Graphics, textContainer: PIXI.Text, textboxData: ITextboxData) {
    textboxData.selectedStartIndex = 0;
    textboxData.selectedEndIndex = textboxData.input.length;

    const lines = textboxData.input.split('\n');
    const height = PIXI.TextMetrics.measureText(lines[0], textContainer.style).height;

    highlight.beginFill(0xb5d8ff, 0.8);
    const padding = 2;
    for(let i=0; i<lines.length; i++){
      const maxWidth = PIXI.TextMetrics.measureText(lines[i], textContainer.style).width
      highlight.drawRect(0, height * i, maxWidth + padding, height)
    }
    highlight.endFill();
  }

  // use this logic on the beginning and last point of the drag to find the highlighted lines
  findCursorCaretPosition(textboxData: ITextboxData, text: PIXI.Text, cursor: IPoint) {
    const lines = textboxData.input.split('\n');
    
    const height = PIXI.TextMetrics.measureText(lines[0], text.style).height;
    const index = cursor.y / height;
    let lineIndex = 0;

    for(let i=1; i<=lines.length; i++){
      if(index < i && index > i-1) {
        lineIndex = i-1;
        break;
      }
    }

    const line = lines[lineIndex];
    let measuredWidth = 0;
    let charIndex = 0;
    for(let i=0; i<=line.length; i++) {
      const measure = PIXI.TextMetrics.measureText(line.substring(0, i), text.style);
      const charWidth = PIXI.TextMetrics.measureText(line.substring(i, i > 0 ? i-1 : 0), text.style).width;
      if(cursor.x < measure.width) {
        if(cursor.x < measure.width - (charWidth / 2)) {
          measuredWidth = measure.width - charWidth;
          charIndex = i-1;
        } else {
          measuredWidth = measure.width;
          charIndex = i;
        }
        break;
      } else if(i == line.length && cursor.x > measure.width) {
        measuredWidth = measure.width;
        charIndex = i;
      }
    }

    for(let i = 0; i<lineIndex; i++) {
      charIndex += lines[i].length + 1
    }
    const measuredHeight = lineIndex * height // Additional vertical spacing

    return {x: measuredWidth, y: measuredHeight, index: charIndex, _fontHeight: height, _line: lineIndex, _lines: lines}
  }


  findCaretPosition(textboxData: ITextboxData, text: PIXI.Text): IPoint {
    const lines = textboxData.input.split('\n');
    let lineIndex = 0;
    let charIndex = 0;
    let index = 0;
    for(let i = 0; i<lines.length; i++) {
      for(let v = 0; v<lines[i].length; v++) {
        if(index == textboxData.activeCharIndex) {
          lineIndex = i;
          charIndex = v;
        }
        
        index++;
      }
      if(index == textboxData.activeCharIndex) {
        charIndex = -1;
        lineIndex = i+1;
      }
      index++;
    }
    
    const line = lines[lineIndex]; 

    const measure = PIXI.TextMetrics.measureText(line.substring(0, charIndex+1), text.style);
    const measuredWidth = measure.width;
    const measuredHeight = lineIndex * measure.height // Additional vertical spacing

    if(textboxData.activeCharIndex == -1) {
      return {x: 0, y: measuredHeight}
    }
    return {x: measuredWidth, y: measuredHeight}
  }

  updateTextInput(textboxData: ITextboxData) {
    const text = this.singleTextboxes[textboxData.id][textboxHash.TEXT];
    const selectBorder = this.singleTextboxes[textboxData.id][textboxHash.SELECT_OUTLINE];
    const outlineBorder = this.singleTextboxes[textboxData.id][textboxHash.OUTLINE];
    const rotationPoint = this.singleTextboxes[textboxData.id][textboxHash.ROTATION_POINT];
    const cell = this.singleTextboxes[textboxData.id][textboxHash.CELL];
    const caret = this.singleTextboxes[textboxData.id][textboxHash.CARET];
    selectBorder.clear();

    text.text = textboxData.input;

    // Update caret position based on text width, height, and caret index
    const caretPos = this.findCaretPosition(textboxData, text)
    this.drawCaret(caret, textboxData);
    this.singleTextboxes[textboxData.id][textboxHash.CARET].position.set(caretPos.x, caretPos.y)

    if(textboxData.isActive) {
      this.singleTextboxes[textboxData.id][textboxHash.CARET].alpha = 1;
    }

    const totalMeasure = PIXI.TextMetrics.measureText(textboxData.input, text.style);

    if(textboxData.input.length <= 0) {
      textboxData.width = this.cellWidth;
      outlineBorder.alpha = 1;
      this.createOutline(selectBorder, 0x000000, this.cellWidth, this.cellHeight);
      if(textboxData.isTableCell) {
        outlineBorder.clear();
        this.createOutline(outlineBorder, 0xbbbbbb, this.cellWidth, this.cellHeight);
      }
    } else {
      const width = textboxData.width = totalMeasure.width + (this.padding * 2) > this.cellWidth ? totalMeasure.width  + (this.padding * 2) : this.cellWidth;
      outlineBorder.alpha = textboxData.isTableCell ? 1 : 0;
      this.createOutline(selectBorder, 0x000000, width, totalMeasure.height + (this.padding * 2) + 2);
      rotationPoint.x = width;
      if(textboxData.isTableCell) {
        outlineBorder.clear();
        this.createOutline(outlineBorder, 0xbbbbbb, width, totalMeasure.height + (this.padding * 2) + 2, textboxData.color);
      }
    }

    if(!textboxData.isTableCell) {
      const lowestPoint = this.getLowestPoint(cell, this.activeMenu);
      textboxData.height = totalMeasure.height + (this.padding * 2) + 2;
      this.activeMenu.position.y = lowestPoint.y + 10;
      this.activeMenu.position.x = lowestPoint.x;
    } else {
      this.redrawTable(textboxData.tableId)
    }

    this.wasTextBoxSelected = true;

    this.updateState();
    this.render();
  }

  getLowestPoint(rectangle: PIXI.Graphics, menu: PIXI.Graphics) {
    // Get the four corner points of the rectangle
    const topLeft = new PIXI.Point(rectangle.x, rectangle.y);
    const topRight = new PIXI.Point(rectangle.x + rectangle.width, rectangle.y);
    const bottomLeft = new PIXI.Point(rectangle.x, rectangle.y + rectangle.height);
    const bottomRight = new PIXI.Point(rectangle.x + rectangle.width, rectangle.y + rectangle.height);
    
    // Create a matrix to hold the rotation transformation
    const matrix = new PIXI.Matrix();
    
    // Apply the rotation to each corner point
    matrix.rotate(rectangle.rotation);
    const transformedTopLeft = matrix.apply(topLeft);
    const transformedTopRight = matrix.apply(topRight);
    const transformedBottomLeft = matrix.apply(bottomLeft);
    const transformedBottomRight = matrix.apply(bottomRight);
    
    // Find the lowest y-coordinate among the transformed points
    const lowestY = Math.max(
      transformedTopLeft.y,
      transformedTopRight.y,
      transformedBottomLeft.y,
      transformedBottomRight.y
    );

    let x = [transformedBottomLeft, transformedTopRight, transformedBottomLeft, transformedBottomRight]
      .find(point => point.y == lowestY).x;
    if(x >= 10 || x <= -10) {
      x -= menu.width / 2
    }
    return {x: x, y: lowestY};
  }

  textKeyDown(e: KeyboardEvent) {
    if(this.activeTextBoxData != null) {
      let minIndex = 0;
      let maxIndex = 0;

      const isDragSelected = this.activeTextBoxData.selectedStartIndex != null && this.activeTextBoxData.selectedEndIndex != null;
      if(isDragSelected) {
        minIndex = Math.min(this.activeTextBoxData.selectedStartIndex, this.activeTextBoxData.selectedEndIndex);
        maxIndex = Math.max(this.activeTextBoxData.selectedStartIndex, this.activeTextBoxData.selectedEndIndex);
        minIndex = minIndex > 0 ? minIndex : 0;
      }
      if((
        e.keyCode == SINGLE_QUOTE || 
        e.keyCode == SPACE
      ) && e.target == document.body) {
        e.preventDefault();
      }
      if(e.keyCode == ARROW_LEFT) { // left
        this.activeTextBoxData.activeCharIndex -= this.activeTextBoxData.activeCharIndex >= 0 ? 1 : 0;
      } else if(e.keyCode == ARROW_RIGHT) { // right
        this.activeTextBoxData.activeCharIndex += this.activeTextBoxData.activeCharIndex < this.activeTextBoxData.input.length - 1 ? 1 : 0;
      } else if((e.metaKey || e.ctrlKey)) {
        if(e.keyCode == KEY_C && isDragSelected) {
          this.clipboard = this.activeTextBoxData.input.slice(minIndex, maxIndex)
        } else if(e.keyCode == KEY_V && this.clipboard.length) {
          if(isDragSelected) { 
            this.insertToSelectedIndex(this.clipboard, minIndex, maxIndex, true);

          } else {
            this.activeTextBoxData.input = this.clipboard.length >= 1 ? 
              this.activeTextBoxData.input.slice(0, this.activeTextBoxData.activeCharIndex+1) + this.clipboard + this.activeTextBoxData.input.slice(this.activeTextBoxData.activeCharIndex+1)
              : this.activeTextBoxData.input;
            this.activeTextBoxData.activeCharIndex += this.clipboard.length;
          }
        }
      } else if(e.keyCode == ENTER) { // enter
        if(isDragSelected) {
          this.insertToSelectedIndex('', minIndex, maxIndex);
          this.activeTextBoxData.activeCharIndex = minIndex-1;
        } else {
          this.activeTextBoxData.input = this.activeTextBoxData.input.slice(0, this.activeTextBoxData.activeCharIndex+1) + '\n' + this.activeTextBoxData.input.slice(this.activeTextBoxData.activeCharIndex+1);
          this.activeTextBoxData.activeCharIndex++;
        }
      } else if(e.keyCode == BACKSPACE && this.activeTextBoxData.activeCharIndex >= -1) { // backspace
        if(isDragSelected) {
          this.insertToSelectedIndex('', minIndex, maxIndex);
          this.activeTextBoxData.activeCharIndex = minIndex-1;
        } else {
          this.activeTextBoxData.input = this.activeTextBoxData.input.slice(0, this.activeTextBoxData.activeCharIndex) + this.activeTextBoxData.input.slice(this.activeTextBoxData.activeCharIndex+1);
          this.activeTextBoxData.activeCharIndex -= this.activeTextBoxData.activeCharIndex >= 0 ? 1 : 0;
        }
      } else { // insert
        if(isDragSelected) {
          this.insertToSelectedIndex(e.key, minIndex, maxIndex);
          this.activeTextBoxData.activeCharIndex = minIndex;
        } else {
          this.activeTextBoxData.input = e.key.length <= 1 ? 
            this.activeTextBoxData.input.slice(0, this.activeTextBoxData.activeCharIndex+1) + e.key + this.activeTextBoxData.input.slice(this.activeTextBoxData.activeCharIndex+1)
            : this.activeTextBoxData.input;
          this.activeTextBoxData.activeCharIndex += e.key.length <= 1 ? 1 : 0;
        }
      }

      if(!e.metaKey && !e.ctrlKey) {
        const highlight = this.singleTextboxes[this.activeTextBoxData.id][textboxHash.HIGHLIGHT]
        highlight.clear();
        this.activeTextBoxData.selectedStartIndex = null;
        this.activeTextBoxData.selectedEndIndex = null;
      }

      this.updateTextInput(this.activeTextBoxData);
    }
  }

  textEventListener = this.textKeyDown.bind(this);
  addTextEventListener() {
    window.addEventListener("keydown", this.textEventListener);
  }

  removeTextEventListener() {
    window.removeEventListener("keydown", this.textEventListener);
  }

  insertToSelectedIndex(input: string, minIndex: number, maxIndex: number, isPaste?: boolean) {
    if(isPaste) {
      this.activeTextBoxData.input = this.activeTextBoxData.input.slice(0, minIndex) + input + this.activeTextBoxData.input.slice(maxIndex)
      this.activeTextBoxData.activeCharIndex = input.length <= 1 ? minIndex : this.activeTextBoxData.activeCharIndex;
    } else {
      this.activeTextBoxData.input = input.length <= 1 ? 
        this.activeTextBoxData.input.slice(0, minIndex) + input + this.activeTextBoxData.input.slice(maxIndex)
        : this.activeTextBoxData.input;


      this.activeTextBoxData.activeCharIndex += input.length <= 1 && input.length > 0? 1 : 0;
    }

    const highlight = this.singleTextboxes[this.activeTextBoxData.id][textboxHash.HIGHLIGHT]
    highlight.clear();
    this.activeTextBoxData.selectedStartIndex = null;
    this.activeTextBoxData.selectedEndIndex = null;
  }


  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.DisplayObject, textboxData: ITextboxData, fontText?: PIXI.Text) {
    let func;
    option.cursor = 'pointer';
  
    if(action == ACTIONS.DELETE) {
      func = (e) => {
        textboxData.isRevoked = true;
        obj.destroy();
        this.activeTextBoxData = null;
        this.stopAllBlinks();
        this.updateState();
        
        this.render();
      }
    } else if(action == ACTIONS.SIZE_UP) {
      func = (e) => {
        this.wasTextBoxSelected = true;
        this.singleTextboxes[textboxData.id][textboxHash.TEXT].style.fontSize += 1;
        textboxData.fontSize = this.singleTextboxes[textboxData.id][textboxHash.TEXT].style.fontSize;
        fontText.text = textboxData.fontSize.toString();
        this.updateTextInput(textboxData);
      }
    } else if(action == ACTIONS.SIZE_DOWN) {
      func = (e) => {
        this.wasTextBoxSelected = true;
        this.singleTextboxes[textboxData.id][textboxHash.TEXT].style.fontSize -= this.singleTextboxes[textboxData.id][textboxHash.TEXT].style.fontSize > 1 ? 1 : 0;
        textboxData.fontSize = this.singleTextboxes[textboxData.id][textboxHash.TEXT].style.fontSize;
        fontText.text = textboxData.fontSize.toString();
        this.updateTextInput(textboxData);
      }
    } else if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        this.wasTextBoxSelected = true;
        const defaultData: ITextboxData = {
          id: this.singleTextBoxData.length,
          input: textboxData.input,
          points: [{
            x: textboxData.points[0].x + 20, 
            y: textboxData.points[0].y + 20
          }],
          width: this.cellWidth, 
          height: this.cellHeight, 
          isRevoked: false,
          fontSize: textboxData.fontSize,
          activeCharIndex: textboxData.input.length-1,
          zIndex: this.zIndex
        };
        this.singleTextBoxData.push(JSON.parse(JSON.stringify(defaultData)));
        const clonedTextbox = this.singleTextBoxData[this.singleTextBoxData.length-1];
        this.activeTextBoxData = clonedTextbox;

        const cell = this.createTextBox(clonedTextbox.points[0].x, clonedTextbox.points[0].y, 0xffffff, clonedTextbox, true);
      
        this.selectTextbox(clonedTextbox);

        this.deselectAll();
        this.drawTextboxMenu(cell, clonedTextbox);

        this.updateTextInput(clonedTextbox);
      }
    } else if(action == ACTIONS.NONE) {
      func = (e) => {
        this.wasTextBoxSelected = true;
      }
    }
    option.on('pointerup', func);
  }

  stopAllBlinks() {
    // this.textBoxOutlines.forEach((v, i) => {
    //   this.textBoxOutlines[i][1].stopBlink();
    // })
  }

  loadAssets = () => {
    return this.spriteLoader.loadSprites()
  }

  changeDrawnGraphicColor(){}

  handleNewState() {
    const data = this.getQuestionState(EPixiTools.TEXTBOX);
    if(!data || data.length == 0) {
      return; 
    }

    this.singleTextBoxData = data;
    this.singleTextBoxData.forEach((textbox) => {
      this.createTextBox(textbox.points[0].x, textbox.points[0].y, textbox.color, textbox, true);
    });

    this.render();
  }
  

  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    entry.data[EPixiTools.TEXTBOX] = this.singleTextBoxData.map((data) => {
      const temp = JSON.parse(JSON.stringify(data));
      temp.isActive = false;
      temp.isEditing = false;
      temp.selectedStartIndex = null;
      temp.selectedEndIndex = null;
      temp.activeCharIndex = data.input.length-1;
      temp.isDrawn = false;
      return temp;
    });
    return entry;

  }
}
class Caret extends PIXI.Graphics {
  rerender;
  isBlinkIn = false;
  alphaTracker: number;
  ticker: PIXI.Ticker;
  duration: number;
  constructor(rerender) {
    super();
    this.rerender = rerender;
    this.ticker = PIXI.Ticker.shared;
  }

  onTick = (deltaTime) => {
    const deltaMS = deltaTime / PIXI.settings.TARGET_FPMS;

    // increase the alpha proportionally
    if (this.alphaTracker >= 1) {
      this.isBlinkIn = false;
    } else if(this.alphaTracker <= 0){
      this.isBlinkIn = true;
    }

    if(this.isBlinkIn) {
      this.alphaTracker += deltaMS / this.duration;
    } else {
      this.alphaTracker -= deltaMS / this.duration;
    }

    if(this.alphaTracker > 0.8) {
      this.alpha = 1;
    } else if(this.alphaTracker < 0.2) {
      this.alpha = 0;
    }

    console.log(`delta ${deltaTime}`);

    this.rerender();
  }

  blink(duration: number) {
    this.alphaTracker = 1;
    this.alpha = 1;
    this.duration = duration;

    this.ticker.add(this.onTick)
  }

  stopBlink() {
    this.ticker.remove(this.onTick);

    this.alpha = 0;

    this.rerender();
  }

}