<div 
    [class.enforce-wrap]="element.isSpaceBetweenDisabled"
    [class.is-invisible-blocks]="element.invisibleBlocks"
>
    <ng-container [ngSwitch]="!!element.isParagraphMode"> 
        <ng-container *ngSwitchCase="false">
            <div [ngStyle]="getStyle()">
                <div 
                *ngFor="let block of blocks; let i = index;" 
                class="block-el" 
                [class.is-disabled]="block.isDisabled && !allowBlockPtrEvents()"
                [class.is-selected]="isSelected[i]"
                [class.is-button-style]="element.isButtonStyle && !block.isDisabled"
                (click)="!isLocked ? toggleAnswer(block.index, block.isDisabled) : ''" 
                [ngStyle]="getWordStyle(block, i)"
                >
                <render-highlightable
                  [inputString]="element.texts[block.index].content"
                  [entryId]="element.texts[block.index].entryId"
                  [prop]="'content'"
                  [isWholeHighlight]="true"
                  (click)="highlighter.checkInitWholeHighlight(element.texts[block.index].entryId, 'content', element.texts[block.index].content)"
                >
                    <markdown-inline [input]="getFormattedWord(block.word)"></markdown-inline>
                </render-highlightable>
                <div *ngIf="getVoiceURL(element.texts[block.index])">
                    <render-audio 
                      [url]="getVoiceURL(element.texts[block.index])" 
                      [trigger]="getClickTrigger(element.texts[block.index])" 
                      [isTriggerDisabled]="!isVoiceoverEnabled()"
                    ></render-audio>
                </div>
                </div>
            </div>        
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <ng-container *ngFor="let paragraph of paragraphs; let idx = index">
                <div [ngStyle]="getStyle(idx)">
                    <div 
                    *ngFor="let block of paragraph; let i = index;" 
                    class="block-el" 
                    [class.is-disabled]="block.isDisabled"
                    [class.is-selected]="isSelected[idx]"
                    (click)="!isLocked ? toggleAnswer(block.index, block.isDisabled) : ''" 
                    [ngStyle]="getWordStyle(block, block.wordIdx)"
                    >
                    <render-highlightable
                      [inputString]="element.texts[block.index].content"
                      [entryId]="element.texts[block.index].entryId"
                      [prop]="'content'"
                      [isWholeHighlight]="true"
                      (click)="highlighter.checkInitWholeHighlight(element.texts[block.index].entryId, 'content', element.texts[block.index].content)"
                    >
                        <markdown-inline [input]="getFormattedWord(block.word)"></markdown-inline>
                    </render-highlightable>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
<div *ngIf="this.showMaxSelectedMsg" (click)="!isLocked ? this.showMaxSelectedMsg = false : ''" class="mcq-max-selected-msg">
    {{getMaxMsgText()}}
    <div class="click-dismiss-msg">
        {{getClickToDismissMsg()}}
    </div>
</div>