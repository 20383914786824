<div class="runner-container" >
  <div cdkDrag *ngIf="getQuestionAudioUrl() && isAudioPlayerShowing()" class="dont-print is-not-high-contrast-exception" style="margin-bottom:1.5em; display: flex; align-items: center; z-index: 999;">
    <render-audio
      [defaultPlaybackSpeed]="getTextToSpeechDefaultPlaybackRate()"
      [url]="getQuestionAudioUrl()"
      [mode]="DisplayMode.NORMAL"
      [hasControls]="true"
      [isQuestionAudio]="true"
      (playbackRateChange)="onPlaybackRateChange($event)"
    ></render-audio>
    <i cdkDragHandle style="margin-left: 0.15em; color: #cdcdcd;" class="fa fa-arrows-alt drag-icon"></i>
  </div>
  <div id="ghost" style="visibility: hidden; width: 10em; height:0em"></div>
  <div class="question-container"  
       [style.font-family]="baseFont"
       [class.is-print-mode]="isPrintMode"
  >
    <div class="question-panels" [class.is-print-mode]="isPrintMode">
      <div 
        class="question-panel" 
        [class.is-print-mode]="isPrintMode"
        [ngStyle]="{'font-size.em':questionTextSize.value}"  
        *ngIf="currentQuestion?.content"
      >
        <div 
          *ngFor="let contentElement of currentQuestion.content; let elementIndex = index;" 
          class="content-element"
          [class.is-not-high-contrast-exception]="!isHighContrastException(contentElement)"
          [class.fade-in]="isFadeInEntrance()"
          [ngStyle]="getElementStyle(contentElement, elementIndex)"
          [class.element-width-constrainer]="checkForElementWidthConstraint(contentElement.elementType)"
          [class.is-element-hidden]="!isElementVisible(contentElement)"
        >
          <!-- <div [ngStyle]="getScaleToFitStyle(contentElement)"> -->
          <element-render 
            *ngIf="showElement(contentElement)"
            [contentElement]="contentElement"
            [isLocked]="isInputLocked()"  
            [questionState]="questionState"
            [questionPubSub]="questionPubSub"
            [allowSubtitles]="allowSubtitles"
            [allowTranscripts]="allowTranscripts"
            [allowAudioPlaybackSpeed]="allowAudioPlaybackSpeed"
            [allowVideoPlaybackSpeed]="allowVideoPlaybackSpeed"
            [frameWorkTags]="frameWorkTags"
          ></element-render>
          <!-- </div> -->
        </div>
      </div>
   </div>
  </div>
  <!-- <button (click)="prevQuestion.emit(questionState)" [disabled]="isFirstQuestion" class="button">Back</button> -->
  <!-- <button (click)="nextQuestion.emit(questionState)" class="button is-success">Continue</button> -->
</div>
