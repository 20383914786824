import { LangService } from "src/app/core/lang.service";
import { SelectedWord } from "../widget-dictionary.component";
import { DICTIONARY_LANG, Dictionary } from "./dictionary";
import { RoutesService } from "src/app/api/routes.service";
import { AuthService } from "src/app/api/auth.service";

export class DictionaryFR extends Dictionary {

    selectedWord: SelectedWord;
    constructor(lang: LangService, route: RoutesService, auth: AuthService) {
        super(lang, route, auth);

        this.dictionaryLang = DICTIONARY_LANG.FR;
    }

    getSuggestions(userData: string) {
        
        return this.auth.apiFind(this.route.DICTIONARY_SUGGESTIONS, {
            query: {
                userData,
                lang: this.dictionaryLang
            }
        })
    }

    getWordData(word) {
        return this.auth.apiGet(this.route.DICTIONARY_SUGGESTIONS, word, {query: {lang: this.dictionaryLang}});
    }

    preprocessResponseRawJSON(raw_json: string) {
        let processedJSON = raw_json
        return processedJSON;
    }


    selectedWordDefinitions = [];
    getCurrentWordDefinition() {
        this.selectedWordDefinitions = [];
        this.getEntryDefGroups(this.selectedWord.json['ZONE-TEXTE-NC']['DIVISION-SEMANTIQUE']);

        if (this.selectedWord.json['SOUS-ARTICLE-NC']
            && this.selectedWord.json['SOUS-ARTICLE-NC']['ZONE-TEXTE-NC']
            && this.selectedWord.json['SOUS-ARTICLE-NC']['ZONE-TEXTE-NC']['DIVISION-SEMANTIQUE']){

            this.getEntryDefGroups(this.selectedWord.json['SOUS-ARTICLE-NC']['ZONE-TEXTE-NC']['DIVISION-SEMANTIQUE']);
            
        }

        return this.selectedWordDefinitions;
    }

    /**
     * Process the definitions of the word entry and update the selectedWordDefinitions array
     * @param jsonEntry ['ZONE-TEXTE-NC']['DIVISION-SEMANTIQUE'] of the word json data
     * @returns 
     */
    getEntryDefGroups(jsonEntry) {
        if (Array.isArray(jsonEntry) || (typeof jsonEntry === "object")) {
            for (let key in jsonEntry) {
                if (Array.isArray(jsonEntry[key]) || (typeof jsonEntry[key] === "object")) {
                    if (key === "ZONE-DEFINITION" || key === "DEFINITION") {
                        this.selectedWordDefinitions.push(jsonEntry);
                        return
                    }
                    else { this.getEntryDefGroups(jsonEntry[key])};
                } 
                else if (key === "ZONE-DEFINITION" || key === "DEFINITION"){
                    this.selectedWordDefinitions.push(jsonEntry);
                    return
                }
            }
        }
    }

    /**
     * Converts a single instance (if the param passed in is) into an array holding that instance
     * @param obj 
     * @returns An array containing the single param
     */
    convertToArray(obj){
        if (Array.isArray(obj))
            return obj;
        else
            return [obj];
    }

    /**
     * Get the definition content to display for a def entry
     * @param selectedWordDefinitionEntry 
     * @returns 
     */
    getSelectedWordDefinitionContent(selectedWordDefinitionEntry){
        if (selectedWordDefinitionEntry['DEFINITION']){
            return this.convertToArray(selectedWordDefinitionEntry['DEFINITION']);
        }
        else if (selectedWordDefinitionEntry['ZONE-DEFINITION'])
            return this.convertToArray(selectedWordDefinitionEntry['ZONE-DEFINITION']['DEFINITION']);
    }

    /**
     * Get the examples of a word within a def group
     * @param jsonEntry The definition entry (what the selectedWordDefinitions holds)
     * @returns The list of examples 
     */
    getDefEntryExampleData(jsonEntry) {
        let examples = [];
        
        const extractExampleFromJson = (jsonObj) => {
            if (typeof jsonObj === "object") {
                for (let key in jsonObj) {
                    if (typeof jsonObj[key] === "object" || key === "EXEMPLE") {
                        if (key === "EXEMPLE") {
                            if (Array.isArray(jsonObj[key])){
                                examples = examples.concat(jsonObj[key]);
                            } else {
                                examples.push(jsonObj[key]);
                            }
                            return
                        }
                        else { extractExampleFromJson(jsonObj[key]) };
                    } 
                }
            }
        }

        if (jsonEntry){
            extractExampleFromJson(jsonEntry);
        }
        return examples;
    }

    /**
     * Get the synonyms of a word within a def group
     * @param jsonEntry The definition entry (what the selectedWordDefinitions holds)
     * @returns The list of synonyms 
     */
    getDefEntrySynonymOpposites(jsonEntry){
        let synonymsOpposites = [];
        
        const extractSynonymsFromJson = (jsonObj) => {
            if (typeof jsonObj === "object") {
                for (let key in jsonObj) {
                    if (typeof jsonObj[key] === "object" || key === "RENVOI") {
                        if (key === "RENVOI") {
                            if (Array.isArray(jsonObj[key])){
                                synonymsOpposites = synonymsOpposites.concat(jsonObj[key]);
                            } else {
                                synonymsOpposites.push(jsonObj[key]);
                            }
                            return
                        }
                        else { extractSynonymsFromJson(jsonObj[key]) };
                    } 
                }
            }
        }

        if (jsonEntry){
            extractSynonymsFromJson(jsonEntry);
        }
        return synonymsOpposites;
    }

    getDefEntrySynonymDisplay(jsonEntry){
        const synonymsOpposites = this.getDefEntrySynonymOpposites(jsonEntry);

        if (synonymsOpposites && synonymsOpposites.length > 0){
            const synonyms = synonymsOpposites.filter(entry => entry['@ROLE'] === 'SYN1');
            return synonyms.map(entry => entry['#text']);
        }
        return [];
    }

    getDefEntryOppositeDisplay(jsonEntry){
        const synonymsOpposites = this.getDefEntrySynonymOpposites(jsonEntry);
        
        if (synonymsOpposites && synonymsOpposites.length > 0){
            const opposites = synonymsOpposites.filter(entry => entry['@ROLE'] === 'CONT1');
            return opposites.map(entry => entry['#text']);
        }
        return [];
    }

    /**
     * Add highlight to the target word in the example
     * @param example The example content of the word in a def group
     * @returns Updated example content with target word highlighted
     */
    highlightTargetWord(example: any) {
        let exampleContent = example
        if (typeof example === "object"){
            exampleContent = example['#text'];
        }
        const target = this.selectedWord.word;
        const re = new RegExp(target, "g");
        return exampleContent.replace(re, `<span class="dictionaryExampleTargetWord">${target}</span>`);
    }

    getDefEntryDefData(defEntry: any) {
        throw new Error("Method not implemented.");
    }

    getCatgramDefinition(){
        const catgramDefinitionShort = this.selectedWord.json['ZONE-ENTREE-NC']?.['ZONE-ADRESSE-NC']?.['CATEGORIE-GRAMMATICALE'];
        if (catgramDefinitionShort){
            switch (catgramDefinitionShort){
                case 'n.f.':
                    return 'nom féminin';
                case 'n.m.':
                    return 'nom masculin';
                default:
                    return undefined;
            }
        }
    }
}