import { LangService } from "../../../core/lang.service";

export interface ICkEditorUiLangTransform {
    cssSelector: string;
    textToReplace: string;
    replacementSlug: string; 
    parentNode?: Element 
}

export const ckEditorUiFindAndReplaceFormLangMap: ICkEditorUiLangTransform[] = [
    {
        cssSelector: ".ck .ck-form__header__label",
        textToReplace: "Find and replace",
        replacementSlug: "auth_find_n_replace"
    },
    {
        cssSelector: ".ck .ck-label",
        textToReplace: "Find in text…",
        replacementSlug: "auth_find_in_text"
    },
    {
        cssSelector: ".ck .ck-button__label",
        textToReplace: "Find",
        replacementSlug: "auth_ckeditor_find"
    },
    {
        cssSelector: ".ck .ck-label",
        textToReplace: "Replace with…",
        replacementSlug: "auth_ckeditor_replace_with"
    },
    {
        cssSelector: ".ck .ck-button__label",
        textToReplace: "Replace",
        replacementSlug: "auth_ckeditor_replace"
    },
    {
        cssSelector: ".ck .ck-button__label",
        textToReplace: "Replace all",
        replacementSlug: 'auth_ckeditor_replace_all'
    },
    {
        cssSelector: ".ck .ck-button__label",
        textToReplace: "Match case",
        replacementSlug: "auth_ckeditor_match_case"
    },
    {
        cssSelector: ".ck .ck-button__label",
        textToReplace: "Whole words only",
        replacementSlug: "auth_ckeditor_whole_words_only"
    },
    {
        cssSelector: ".ck .ck-tooltip__text",
        textToReplace: "Previous result (⇧F3)",
        replacementSlug: "auth_ckeditor_previous_result"
    },
    {
        cssSelector: ".ck .ck-tooltip__text",
        textToReplace: "Previous result (Shift+F3)",
        replacementSlug: "auth_ckeditor_previous_result_windows"
    },
    {
        cssSelector: ".ck .ck-tooltip__text",
        textToReplace: "Next result (F3)",
        replacementSlug: "auth_ckeditor_next_result"
    },
    {
        cssSelector: ".ck .ck-tooltip__text",
        textToReplace: "Show options",
        replacementSlug: "auth_ckeditor_show_options"
    },
]


/**
* The "Find and Replace" tool in ckeditor does not have a French UI, 
*/
export const checkFindAndReplaceLanguage = (lang: LangService) => {
  const toolbarTools = Array.from(document.getElementsByClassName("ck ck-button ck-off"));
  toolbarTools.forEach(tool => {
    Array.from(tool.children).forEach(child => {
      if (child.innerHTML.includes("Find and replace")) {
        child.innerHTML = child.innerHTML.replace(/Find and replace/g, lang.tra("auth_find_n_replace"));
      }
    });
  });

  try {
    const findAndReplaceForms = Array.from(document.getElementsByClassName("ck ck-find-and-replace-form"));
    if (findAndReplaceForms.length === 0) return;

    findAndReplaceForms.forEach(findAndReplaceForm => {
      ckEditorUiFindAndReplaceFormLangMap.forEach(({ cssSelector, textToReplace, replacementSlug }) => {
        const replacementText = lang.tra(replacementSlug);
        findAndReplaceDomElementInnerText(cssSelector, textToReplace, replacementText, findAndReplaceForm);
      });
    });
  } catch (e) {
    console.error("Error translating Find and Replace tool UI.");
    return;
  }
};

/**
 * Hepler function to find a element in the dom by the class name, and replace the inner text of that element.
 * If there are multiple matches for the target element with the target CSS style and text to replace, only the first
 * element in the match result is replaced.
 * @param selector the class selector for the target element (CSS selector), should be as specific as possible.
 * @param textToReplace the text to be replaced 
 * @param replacementText the new text to replace 
 * @param parentNode the parent node to find the target element, if not provided, then the whole document will be the search scope
 */
export const findAndReplaceDomElementInnerText = (selector: string, textToReplace: string, replacementText: string, parentNode?: Element) => {
  let targetEl;
  if (parentNode) {
    targetEl = Array.from(parentNode.querySelectorAll(selector)).filter(entry => entry.innerHTML === textToReplace)[0];
  } else {
    targetEl = Array.from(document.querySelectorAll(selector)).filter(entry => entry.innerHTML === textToReplace)[0];
  }

  if (targetEl) 
    targetEl.innerHTML = replacementText;
};




const boldSvgPath = "M16.2,14.8c0,1-0.4,1.7-1.3,2.1c-1.2,0.5-2.6,0.8-4,0.8c-2,0-3.5-0.6-4.6-1.9c-1.1-1.2-1.6-3-1.6-5.3c0-2.2,0.5-3.9,1.6-5.2C7.5,4,9,3.4,10.9,3.4c2.3,0,3.9,0.9,4.8,2.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.5,0-0.8-0.2-1.1-0.6C13,6,12.1,5.4,10.8,5.4c-1.1,0-2,0.4-2.6,1.2c-0.7,0.9-1.1,2.2-1.1,3.9s0.4,3,1.1,3.9c0.7,0.8,1.6,1.2,2.7,1.2c0.9,0,1.7-0.2,2.5-0.5c0.3-0.1,0.5-0.4,0.5-0.7v-2c0-0.3-0.1-0.4-0.4-0.4h-1.4c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7s0.1-0.5,0.3-0.7s0.4-0.3,0.7-0.3h2.3c1.2,0,1.8,0.6,1.8,1.8C16.2,11.8,16.2,14.8,16.2,14.8z";
const underlineSvgPath_line = "M0.5,15.8v-1.5h14v1.5H0.5z";
const underlineSvgPath_letter = "M7.7,11.8c-2.7,0-4.9-1.3-4.9-2.2c0-0.5,0.4-0.9,0.8-0.9c0.2,0,0.4,0.1,0.6,0.3c0.7,0.9,2.4,1.3,3.6,1.3c1.5,0,2.8-0.7,2.8-1.8c0-0.5-0.3-1-0.9-1.3C8.5,6.7,6.2,6.6,4.9,6.2C3.6,5.7,3,4.7,3,3.8c0-1.5,1.8-3,4.7-3c2.3,0,4.4,1,4.4,1.9c0,0.5-0.4,0.9-0.8,0.9c-0.2,0-0.4-0.1-0.6-0.3c-0.6-0.6-2.4-1-3.5-1c-1.6,0-2.5,0.7-2.5,1.5c0,0.4,0.3,0.8,0.9,1.1		c1.2,0.5,3.3,0.5,4.8,1.1c1.4,0.6,2,1.6,2,2.6C12.3,10,10.9,11.8,7.7,11.8z";

const boldButtonInnerHTML_fr = `<path d="${boldSvgPath}"></path>`;
const underlineButtonInnerHTML_fr = `<path class="st0" d="${underlineSvgPath_line}"/><g><path d="${underlineSvgPath_letter}"></path></g>`;


/**
   * Finds the svg element of the Bold and the Underline button icons, replace the default ckeditor
   * icons with custom ones.
   */
export const overwriteCKEditorToolIcons = () => {
    let boldButtons: Element[] = [];
    let underlineButtons: Element[] = [];

    const toolbarTools = Array.from(document.getElementsByClassName('ck ck-button ck-off'))
    toolbarTools.forEach(tool => {
      Array.from(tool.children).forEach(child => {
        if (child.innerHTML == 'Gras') boldButtons.push(tool) ;
        if (child.innerHTML == 'Souligné') underlineButtons.push(tool);
      })
    })
    if (boldButtons.length > 0){
      boldButtons.forEach(button => button.children[0].innerHTML = boldButtonInnerHTML_fr)
    }
    if (underlineButtons.length > 0){
      underlineButtons.forEach(button => {
        button.children[0].setAttribute('viewBox', '0 0 18 15');
        button.children[0].innerHTML = underlineButtonInnerHTML_fr;
      })
    }
}