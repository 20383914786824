import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';

export type FilterToggles = IFilterToggle[]

export interface IFilterToggle {
  id: string,
  caption: string,
  value: boolean,
  is_disabled?: boolean
  is_classroom_assessment?: boolean
  assessmentDefId?: number
}

@Component({
  selector: 'filter-toggles',
  templateUrl: './filter-toggles.component.html',
  styleUrls: ['./filter-toggles.component.scss']
})
export class FilterTogglesComponent implements OnInit {

  @Input() state:FilterToggles;
  @Input() caption = 'lbl_filter_to';
  @Output() id = new EventEmitter();
  @Output() assessmentDefId = new EventEmitter();
  @Output() change = new EventEmitter();

  constructor(private whiteLabel: WhitelabelService) 
  { }


  ngOnInit(): void {
    if (this.state && this.state.length !== 0)
    {
      this.id.emit( this.getActiveToggleId() );
    }
  }

  ngOnChanges(changes: SimpleChanges)
  {
    // console.log(changes);
    try 
    {
      if (changes.state != null && (changes.state.previousValue.length == 0 || 
      changes.state.previousValue == null))
      {
        // console.log(changes.state);
        if (this.whiteLabel.isABED())
        {
          // select 1st toggle by default
          this.selectToggle(this.state[0]);
        }
      }
    }

    catch(e)
    {
    }
  }

  isSelected(toggle:IFilterToggle){
    let activeToggle = this.getActiveToggle();
    if (activeToggle === toggle){
      return true;
    }
    return false;
  }

  getActiveToggleId(){
    let activeToggle = this.getActiveToggle();
    if (activeToggle){
      return activeToggle.id;
    }
  }

  getActiveToggleAssessmentDefId(){
    const activeToggle = this.getActiveToggle();
    if (activeToggle){
      return activeToggle.assessmentDefId;
    }
  }

  getActiveToggle(){
    let activeToggle:IFilterToggle;
    this.state.forEach(toggle => {
      if (toggle.value){
        activeToggle = toggle;
      }
    })
    return activeToggle;
  }

  selectToggle(toggle:IFilterToggle){
    let activeToggle = this.getActiveToggle();
    if (activeToggle === toggle){
      toggle.value = false;
    }
    else{
      if (activeToggle){
        activeToggle.value = false;
      }
      toggle.value = true;
    }
    this.id.emit( this.getActiveToggleId() );
    this.assessmentDefId.emit( this.getActiveToggleAssessmentDefId() );
  }

}
