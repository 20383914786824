import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { EditSelectionService } from '../edit-selection.service';
import { ElementType, QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { TextToSpeechService } from '../text-to-speech.service';
import { IContentElementText, IContentElementTextParagraph, TextParagraphStyle } from './model';
@Component({
  selector: 'element-render-text',
  templateUrl: './element-render-text.component.html',
  styleUrls: ['./element-render-text.component.scss']
})
export class ElementRenderTextComponent implements OnInit {

  @Input() element:IContentElementText;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;
  @ViewChild('textContainer') textContainer: ElementRef
  
  spanElement;
  
  constructor(
    private whitelabel: WhitelabelService,
    private text2Speech: TextToSpeechService,
    public editSelection: EditSelectionService
  ) { }


  ngOnInit() {
    this.isInitDynamicText()
  }

  ngAfterViewInit() {
    this.formatSuperscript()
  }
  
  ngOnChange(){
    this.isInitDynamicText()
  }

  initElementSub(){
    this.questionPubSub.allSub().subscribe(payload => {
      if (payload.entryId !== this.element.fromEntryId || !this.element.isShowDynamicText) return;
      const state = this.questionState[payload.entryId]
      switch (state.type) {
        case ElementType.MCQ:
          if (!state.selections.length) return ;
          const selection = state.selections[0].content
          this.element.caption = selection.toString();
          return;
        default:
          return;
      }
    });
  }

  isInitDynamicText(){
    if(this.element.isShowDynamicText){
      this.element.caption = ''
      this.initElementSub()
    }
  }

  isParagraphs(){
    return this.element.paragraphStyle === TextParagraphStyle.PARAGRAPHS;
  }
  isHeading(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE;
  }
  isHeadingSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL;
  }
  isBody(){
    return this.element.paragraphStyle === TextParagraphStyle.REGULAR;
  }
  isSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.SMALL;
  }
  isBulletList(){
    return this.element.paragraphStyle === TextParagraphStyle.BULLET;
  }
  isNumberedList(){
    return this.element.paragraphStyle === TextParagraphStyle.NUMBERED;
  }

  isSimpleText(){
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.REGULAR){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.SMALL){ return true }
    return false;
  }

  isLink(){
    return this.element.paragraphStyle === TextParagraphStyle.LINK;
  }

  isListText(){
    if (this.element.paragraphStyle === TextParagraphStyle.BULLET){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.NUMBERED){ return true }
    return false;
  }

  isAdvancedInlineText(){
    if (this.element.paragraphStyle === TextParagraphStyle.ADVANCED_INLINE){ return true }
    return false;
  }

  isAnnotation() {
    return this.element.paragraphStyle === TextParagraphStyle.ANNOTATION;
  }

  getFontFamily() {
    if (!this.whitelabel.getSiteFlag('IS_EQAO')){
      return this.element.font;
    }
    return 'inherit';
  }

  getFontSizeLink(element) {
    if (element.elementType==ElementType.BOOKMARK_LINK && element.fontSize) {
      return `${element.fontSize}em`;
    }
    return '';
  }

  getRotation() {
    if (this.element.rotation) return "rotate("+this.element.rotation+"deg)";
    return '';
  }

  getFontSize(){
    if (this.element.fontSize){
      return `${this.element.fontSize}em`;
    }
    return 'inherit';
  }

  getLineHeight() {
    if (this.element.lineHeight){
      return `${this.element.lineHeight}em`;
    }
    return '1.3em';
  }

  renderParaNum(index:number, paragraph:IContentElementTextParagraph){
    if (paragraph.numberingOverride){
      return paragraph.numberingOverride;
    }
    let start = this.element.paragraphNumberingStart || 1;
    return (+start) + index;
  }

  renderParaNumPadding(){
    return (1-(this.element.paragraphNumberingScale || 1)) / 4;
  }

  getTextStyle() {
    const style:any = {}
    style["font-family"]=this.getFontFamily();
    style["text-align"]=this.element.alignment;
    style["font-size"]=this.getFontSize();
    style["transform"]=this.getRotation();
    style["color"]= this.element.colour;
    //if (!this.isAdvancedInlineText()){
    style["line-height"]= this.getLineHeight()
    if (this.element.isNoInvertOnHiContrast && this.text2Speech.isHiContrast) {
      style["filter"]="invert(1)";
    }
    //}
    // style["user-select"]='none';
    return style;
  }

  getChildStyle() {
    const style: any = {}
    if (this.getTextStyle()["line-height"]) {
      style['line-height']='inherit'
    }
    return style;
  }

  minWidth = 10;
  getWidth() {
    if(this.isParagraphs()){
      return this.element.paragraphWidth
    }
    if (this.element.advancedInlineWidth && this.element.advancedInlineWidth >= this.minWidth) {
      return this.element.advancedInlineWidth;
    }
    return 'initial';
  }

  parseTextFormat(text:string) {
    if (!text) {
      text = ''
    }
    let regEx = /{{([0-9]+)\.(.+)}}/
    let match = text.match(regEx);
    if (match) {
      const id = match[1];
      const field = match[2];
      if (id && this.questionState[id] && this.questionState[id][field]) {
        const replaceWith = this.questionState[id][field]
        const newText = text.replace(regEx, replaceWith)
        return newText;
      }
    }
    // support for the <sc> tag
    text = text.replace(/<sc\b[^>]*>(.*?)<\/sc>/g, `<span class="smallCaps">$1</span>`);

    return text;
  }

  formatSuperscript() {
    this.spanElement = this.textContainer
    let target = this.spanElement?.span?.nativeElement.querySelectorAll('sup')
    if (target && target.length) {
      target.forEach((sup: HTMLElement) => {
        sup.style.lineHeight = '1'
      });
    }
  }
}