import { Component, Input, OnInit } from '@angular/core';

export type BarGraphData = {
  name: string,
  value: number,
}

@Component({
  selector: 'bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.scss']
})
export class BarGraphComponent implements OnInit {
  @Input() gradient: boolean = false;
  @Input() showLegend: boolean = false;
  @Input() xAxisLabel: string = 'X Axis';
  @Input() yAxisLabel: string = 'Y Axis';
  @Input() colorScheme: any;
  @Input() width: number | null = null;
  @Input() height: number | null = null;
  @Input() showPercentageBars: boolean = false;
  @Input() data: BarGraphData[];

  constructor() {}

  isLoaded = false;

  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;

  sumValue: number;
  onSelect(event) {
    console.log(event);
  }

  ngOnInit(): void {
    this.isLoaded = false;
    if (this.showPercentageBars) {
      this.sumValue = this.data.reduce((acc, curr) => acc + curr.value, 0)
    }
    this.isLoaded = true;

  }

  formatValue(value: number): string {
    if (this.showPercentageBars) {
      return `${value / this.sumValue * 100}%`
    }
    return value.toString();
  }

}
