import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../api/auth.service';
import { removeDataFromLocalStorage, retriveDataFromLocalStorage, storeDataInLocalStorage } from './services/util';
import { StyleprofileService } from '../core/styleprofile.service';
import { LangService } from '../core/lang.service';

const LOC_STORAGE_TTS_KEY = "tts-playbackspeed";
const TTS_DEFAULT_PLAYBACk_SPEED = 0.75;

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {

  public isActive:boolean = false;
  private _isHiContrast: boolean = false;
  public isHighContrastSub = new BehaviorSubject<boolean>(this._isHiContrast);

  private _defaultPlaybackSpeed: number = TTS_DEFAULT_PLAYBACk_SPEED;
  
  public get defaultPlaybackSpeed() {
    return this._defaultPlaybackSpeed
  }
  
  public set defaultPlaybackSpeed(value) {
    this.storeUserPlaybackSpeed(value);
  }


  public get isHiContrast() : boolean {
    return this._isHiContrast;
  }

  constructor(
    private auth: AuthService,
    private styleProfile: StyleprofileService,
    private lang: LangService
  ) {
    this.initTtsDefaultPlaybackSpeed();
   }

  toggle(){
    this.isActive = !this.isActive;
  }

  hi_contrast_toggle(){
    this._isHiContrast = !this._isHiContrast
    this.isHighContrastSub.next(this._isHiContrast)
  }

  hi_contrast_off() {
    this._isHiContrast = false;
  }


  initTtsDefaultPlaybackSpeed = () => {

    // init based on style profile
    const profile = this.styleProfile?.getStyleProfile()?.[this.lang.c()];
    if (profile) {
      const defaultPlaybackSpeed = profile.configuration?.accessibility?.textToSpeech?.defaultPlaybackSpeed;
      if (+defaultPlaybackSpeed) {
        this._defaultPlaybackSpeed = defaultPlaybackSpeed;  // override the default const from style profile
      }
    }

    // based on if user selected playback speed previously
    this._defaultPlaybackSpeed = this.getUserPlaybackSpeed();
  }

  // playback speed in local storage

  storeUserPlaybackSpeed = (playbackSpeed: number) => {
    
    if(this._defaultPlaybackSpeed === +playbackSpeed) return;
    this._defaultPlaybackSpeed = playbackSpeed;

    const uid = this.auth.getUid();
    if(uid === -1) 
      return; // should retrive from in-memory variable
    
    const data = { uid, playbackSpeed };
    storeDataInLocalStorage(LOC_STORAGE_TTS_KEY, JSON.stringify(data));
  }

  getUserPlaybackSpeed = () => {
    const uid = this.auth.getUid();
    const localPlaybackSpeedStr = retriveDataFromLocalStorage(LOC_STORAGE_TTS_KEY);

    if (uid === -1 || localPlaybackSpeedStr == null) 
      return this._defaultPlaybackSpeed; 
    
    try { 
      const localPlaybackSpeed = JSON.parse(localPlaybackSpeedStr);
      if (localPlaybackSpeed?.uid !== uid) {
        removeDataFromLocalStorage(LOC_STORAGE_TTS_KEY);
        return this._defaultPlaybackSpeed;
      }

      return +localPlaybackSpeed.playbackSpeed;
      
    } catch (error) {
      console.error(LOC_STORAGE_TTS_KEY, "failed to retrive Playback speed for user");
      return this._defaultPlaybackSpeed;
    } 
  }
  

}
