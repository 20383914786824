import { Component, Input, OnInit } from '@angular/core';
import { IContentElementPassage } from './model';
import { DEFAULT_WIDTH_EM, QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { StyleprofileService } from 'src/app/core/styleprofile.service';
import { AuthScopeSettingsService } from 'src/app/ui-item-maker/auth-scope-settings.service';
import { LangService } from 'src/app/core/lang.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BackgroundFillService } from '../background-fill.service';
import { EditSelectionService } from '../edit-selection.service';

const PARARGRAPH_SPACING = 1;
type ILine = {
  // identSpans:{
  //   str: string,
  //   width: number,
  // }[]
}
type ISegment = {
  // lines:ILine[], 
  str:string,
  isComplete?:boolean
  lineCount?: number
  isSkipLineCount?: boolean
}
@Component({
  selector: 'element-render-passage',
  templateUrl: './element-render-passage.component.html',
  styleUrls: ['./element-render-passage.component.scss']
})
export class ElementRenderPassageComponent implements OnInit {

  @Input() element:IContentElementPassage;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    private lang:LangService,
    private styleProfile:StyleprofileService,
    private authScope: AuthScopeSettingsService,
    private sanitizer: DomSanitizer,
    public bgFillService: BackgroundFillService,
    public editSelection: EditSelectionService
  ) { }

  ngOnInit() {
    // this.updateRender();
    // this.profile.getStyleProfileChanges().subscribe((hasStyleProfile) => {
    //   if(hasStyleProfile) {
    //     this.updateRender();
    //   }
    // })
  }

  ngOnDestroy(): void {
    // if(this.styleProfileChangeSub) {
    //   this.styleProfileChangeSub.unsubscribe();
    // }
  }

  getImageRefs(){
    const imageRef:Map<number, {url:string, width_em:number, alignment?:string}> = new Map()
    if (this.element.images){
      for (let image of this.element.images){
        const alignment = image.alignment
        const imageEl = image.el?.images?.default?.image || {}; // todo:bad backwards compatibility
        const url = imageEl.url;
        const width_em = DEFAULT_WIDTH_EM * (imageEl.scale /100)
        imageRef.set(+image.id, { url, alignment, width_em })
      }
    }
    return imageRef
  }
  getTextAlign(){
    return { 'text-align': this.element.textAlignment };
  }

  processPStyle(htmlContent: string): string {
    const htmlContentSplit = htmlContent.split('\n')

    return htmlContentSplit.map(line => {
      let paragraphStyle:{tabs:{sizeEm:number}[]};
      line = line.replace(/<pstyle id="(\d+)"\/?>/g, (match:string, id:string) => {
        for (let ps of this.element.paragraphStyles){
          if (+ps.id == +id){
            paragraphStyle = ps;
          }
        }
        return '';
      });
      if (paragraphStyle){
        const lineIndentSpans = line.split('<t/>');
        line = `${ lineIndentSpans.map((str, i) => {
          paragraphStyle.tabs
          const tab = paragraphStyle.tabs[i]
          const widthStyle = (tab && tab.sizeEm) ? ' width:'+tab.sizeEm+'em' : '';
          return `<span style="display:inline-block;${widthStyle}">${str}</span>`
        }).join('') }`
      }

      return line;
    }).join('\n');
  }

  lastTextSnapshot:string;
  segments:{html?:SafeHtml, isComplete?:boolean, lineCount?:number, skipLineCount?: boolean}[];
  renderTextSegments(){
    const getBookmarkTag = (i, body: string, isParagraph?: boolean): string => {
      return `<div class="bookmark id-${isParagraph ? 'paragraph' : 'line'}_${i+1}">${body}</div>`
    }
    const snapshot = this.element._changeCounter+this.element.text
    if (this.lastTextSnapshot != snapshot){
      this.lastTextSnapshot = snapshot
      let htmlContent = this.element.text || '';
      const imageRef = this.getImageRefs()
      // use custom small caps
      htmlContent = htmlContent.replace(/<sc\b[^>]*>(.*?)<\/sc>/g, '<span class="small-caps">$1</span>');
      // apply bookmark tags (and other style profile transforms)
      htmlContent = this.processPStyle(htmlContent);
      htmlContent = this.processAlignment(htmlContent);
      htmlContent = this.processBookmark(htmlContent);
      htmlContent = this.styleProfile.processBookmark(htmlContent);
      htmlContent = this.styleProfile.processBoldedMarkup(htmlContent);
      htmlContent = this.styleProfile.processItalicsMarkup(htmlContent);
      htmlContent = this.styleProfile.processTooltip(htmlContent);
      // apply image tags
      htmlContent = htmlContent.replace(/<img id="(\d+)">/g, (match:string, id:string) => {
        const imageData = imageRef.get(+id);
        if (imageData) {
          const alignmentClass = imageData.alignment ? ` align-${imageData.alignment} ` : ''
          let replacement = `<div class="img-container ${alignmentClass}"><img id="${id}" style="width:${imageData.width_em}em" src="${imageData.url}" `;
          replacement += '></div>';
          return replacement;
        }
        return match; // Return the original match if no image data is found
      })
      // split the lines
      
      const segments:ISegment[] = [];
      let chunks:string[];
      const resetChunks = () => chunks = [];
      resetChunks();

      const htmlContentSplit = htmlContent.split('\n')
      let isSegmentCountSkip = false

      if (this.element.counterType === 'LINE'){
        let lineCount = 0
        for (let line of htmlContentSplit){
          // strippedLine holds the version of the line without bookmark tags to check for emptyness
          let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
          strippedLine = strippedLine.replace(/<\/div>/g, '');
          // bookmarkTag holds the original bookmark tag before it was replaced.
          const bookmarkTag = line.match(/<div class="bookmark id-[^>]+>/g)

          const isLineFilled = ((strippedLine || ' ').trim() != '');
          isSegmentCountSkip = !(isLineFilled || !this.element.isLineCountSkipBlank);
          line = line.replace(/<skip\/>/g, (match:string, id:string) => {
            isSegmentCountSkip = true
            return '';
          })

          // If the line is empty, an empty space needs to be added to be parsed by the HTML
          if(!isLineFilled && bookmarkTag.length > 0) {
            chunks.push(`${bookmarkTag[0]} </div>`)
          } else {
            chunks.push(line)
          }
          
          lineCount = this.getLineCount(isSegmentCountSkip, undefined, lineCount)

          if (lineCount % this.element.lineCountInterval == 0){
            segments.push({ 
              str: chunks.join('\n'), 
              isComplete:true, 
              isSkipLineCount: isSegmentCountSkip,
              lineCount: lineCount
            })
            resetChunks();
          }
        }
      }
      else if (this.element.counterType === 'PARAGRAPH'){
        for (let line of htmlContentSplit){
          let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
          strippedLine = strippedLine.replace(/<\/div>/g, '');
          
          line = line.replace(/<skip\/>/g, (match:string, id:string) => {
            isSegmentCountSkip = true
            return '';
          })

          const isBlankLine = strippedLine.trim() == ''
          
          if (!isBlankLine){
            chunks.push(line);
          }
          if (isBlankLine && chunks.length > 0){
              segments.push({
                str: chunks.join('\n'), 
                isComplete: true, 
                lineCount: this.getLineCount(isSegmentCountSkip, segments),
                isSkipLineCount: isSegmentCountSkip
              });
              resetChunks();
              isSegmentCountSkip = false
          }
        }
      }
      else if (this.element.counterType === 'NONE'){
        segments.push( {str:htmlContentSplit.join('\n')} )
      }
      // push whatever is left
      if (chunks.length){
        segments.push( {
          str: chunks.join('\n'),
          isComplete: (this.element.counterType === 'PARAGRAPH'), // if paragraph mode, then whereever the text ends is considered the end of the paragraph (dont need another set of spaces afterwards)
          isSkipLineCount: isSegmentCountSkip,
          lineCount: this.getLineCount(isSegmentCountSkip, segments)
        })
      }
      this.segments = segments.map((segment, i) =>{
        const {str, isComplete, lineCount, isSkipLineCount} = segment;
        return {
          html: this.sanitizer.bypassSecurityTrustHtml(str),
          isComplete,
          lineCount,
          isSkipLineCount,
        }
      });
    }
    return this.segments
  }

  paragraphSpacing(){
    if (this.element.counterType == 'PARAGRAPH'){
      return PARARGRAPH_SPACING;
    }
    return 0
  }

  isCounterAlignRight(){
    return (this.element.counterAlignment == 'right')
  }
  isCounterAlignLeft(){
    return ! this.isCounterAlignRight()
  }

  isLinesMode(){
    return (this.element.counterType == 'LINE')
  }
  isParagraphMode(){
    return (this.element.counterType == 'PARAGRAPH')
  }

  isShowCounter(){
    return (this.element.counterType !== 'NONE') && (+this.element.lineCountInterval > 0);
  }
  

  /**
   * 
   * @param isSegmentCountSkip boolean value indicating if we want to skip counting this line/paragraph
   * @param segments Used for paragraph counter type
   * @param lineCount Used for line counter type
   * @returns lineCount to be applied to line/paragraph
   * @description determines the line count that needs to be applied for 
   */
  getLineCount(isSegmentCountSkip:boolean, segments?:ISegment[], lineCount?:number) : number{
    if (this.element.counterType == 'PARAGRAPH'){
      if(isSegmentCountSkip){
        if(segments.length<1) return 0
        
        return segments[segments.length-1].lineCount
      }
      if(segments.length<1) return 1
      return segments[segments.length-1].lineCount + 1
    }
    else if (this.element.counterType == 'LINE'){
      if (!isSegmentCountSkip){
        lineCount += 1
      }
      return lineCount
    }
  }

  processBookmark(html: string): string {
    const passage = html.split('\n')
    let processedPassage = passage.map((line, i) => {
      return `<bookmark id="line_${i+1}">${this.balanceTags(line)}</bookmark>`;
    });
    return processedPassage.join('\n');
  }

  processAlignment(html: string): string {
    const alignmentRegex = /<(alignleft|alignright|aligncenter)\/>/g
    const passage = html.split('\n')
    let processedPassage = passage.map((line, i) => {
      let matches = line.match(alignmentRegex)
      
      if(matches){
        let tempLine = line
        matches.forEach( (match) => {
          let alignment = ''
          if(match == "<alignleft/>"){
            alignment = 'left'
          }else if(match == "<aligncenter/>"){
            alignment = 'center'
          }else if(match == "<alignright/>"){
            alignment = 'right'
          }

          tempLine = `<div style="display:inline-block; width:100%; text-align:${alignment}">${line.replace(match, '')}</div>`;
        })
        return tempLine
      }
      return line
    });
    return processedPassage.join('\n');
  }

  // we represent a stack. We push every opening tag we see onto the 
  // stack and pop the most recent tag when we see a closing tag
  openTags = []
  balanceTags(line: string) : string {

    const regex = /<\/?bookmark[^>]*>/g;
    const matches = line.match(regex) || [];

    let preLine = this.openTags.join("")

    for (const match of matches) {
      if (match.startsWith('</')) {
        // Closing tag
        if (this.openTags.length === 0) {
          // break if there is a closing tag without any open tags. This is invalid bookmark tag structure
          break;
        }
        this.openTags.pop();
      } else {
        this.openTags.push(match);
      }
    }

    // close any unclosed opening tags
    const postLine = Array.from({ length: this.openTags.length }, (_, index) => `</bookmark>`).join("");


    return preLine + line + postLine

  }
}
