import { ScoringTypes, getElementWeight } from "../models"
import { IContentElementMcq } from "./model"

export const getDefaultMcqEntryState = (element: IContentElementMcq, alreadyScrambled) => {
    const entryState  = {
        type: 'mcq',
        isCorrect: false,
        isStarted: false,
        isFilled: false,
        isResponded: false,
        selections: [],
        alreadyScrambled,
        score: 0,
        weight: getElementWeight(element),
        scoring_type: ScoringTypes.AUTO, 
    }

    return entryState
}


export const MCQ_OPTION_LABEL = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');