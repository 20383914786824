import { Component, Input, OnInit } from '@angular/core';
import { ResultValues } from 'src/app/ui-teacher/view-teacher-student-reports/view-teacher-student-reports.component';

export type BarGraphData = {
  name: string,
  value: number,
}

@Component({
  selector: 'grouped-bar-graph',
  templateUrl: './grouped-bar-graph.component.html',
  styleUrls: ['./grouped-bar-graph.component.scss']
})
export class GroupedBarGraphComponent implements OnInit {
  @Input() gradient: boolean = false;
  @Input() showLegend: boolean = false;
  @Input() xAxisLabel: string = 'X Axis';
  @Input() yAxisLabel: string = 'Y Axis';
  @Input() colorScheme: any;
  @Input() width: number | null = null;
  @Input() height: number | null = null;
  @Input() showPercentageBars: boolean = false;
  @Input() data;
  @Input() barPadding: number = 32;

  constructor() {}

  isLoaded = false;

  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;

  sumValue: number;
  // customColors: any[] = [];

  onSelect(event) {
    console.log(event);
  }

  ngOnInit(): void {
    this.isLoaded = false;

    if (this.showPercentageBars) {
      this.sumValue = this.data.reduce((acc, curr) => acc + curr.value, 0)
    }
    this.isLoaded = true;

  }
  // customColors(value: any) {
  //   console.log('value', value)
  //   const customColors = { 
  //     [ResultValues.NO_ATTEMPT]: '#BBBBBB',
  //     [ResultValues.AT_RISK]: '#CADF86',
  //     [ResultValues.NOT_AT_RISK]: '#9CC45C',
  //   }
  //   return customColors[value.name] ||  '#AAAAAA';
  // }

  formatValue(value: number): string {
    if (this.showPercentageBars) {
      return `${value / this.sumValue * 100}%`
    }
    return value.toString();
  }

}
