import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LangService } from 'src/app/core/lang.service';
import { StyleprofileService } from 'src/app/core/styleprofile.service';
import { EditingDisabledService } from 'src/app/ui-item-maker/editing-disabled.service';
import { keyboardSets } from './capture-math-keyboard-layout';

@Component({
  selector: 'capture-math',
  templateUrl: './capture-math.component.html',
  styleUrls: ['./capture-math.component.scss']
})
export class CaptureMathComponent implements OnInit, OnDestroy, OnChanges {

  @Input() obj:any;
  @Input() prop:string;
  @Input() startBlank:boolean;
  @Input() isManualKeyboard:boolean;
  @Input() isSpacesTabs:boolean;
  @Input() isLocked:boolean;
  @Input() latexValue:string;
  @Input() control: FormControl;
  @Output() onChange = new EventEmitter<null>();
  @ViewChild('mathField', { static: true }) mathFieldRef: ElementRef;

  MathLive = null;
  mathField;
  isMathFieldLocked:boolean = false;
  currentProfile: string;

  options = [
    { label: 'Default', value: 'numeric' },
    { label: 'Grade 6', value: 'grade6' },
    { label: 'Grade 9', value: 'grade9' },
    { label: 'CAEC', value: 'CAEC' },
    { label: 'Diploma', value: 'diploma' },
  ];
  selectedOption: string | null = null;

  onOptionSelected(value: string) {
    this.clearPreviousKeyboards()
    this.selectedOption = value
    this.updateMathField()  
  }


  constructor(private editingDisabled: EditingDisabledService,
    private profile: StyleprofileService, 
    private lang: LangService) { }

  ngOnInit() {
    this.clearPreviousKeyboards();
    this.setKeyboardProfile()

    this.profile.getStyleProfileChanges().subscribe((hasStyleProfile) => {
      if(hasStyleProfile) {
        this.clearPreviousKeyboards()
        this.selectedOption = this.getKeyboardProfile()
        this.updateMathField()  
      }
    })
  }

  ngOnDestroy(){
    
  }

  clearPreviousKeyboards(){
    const lingeringKeyboards = document.getElementsByClassName('ML__keyboard');
    if (lingeringKeyboards){
      // console.log('on destroy :: ', lingeringKeyboards.length);
      for (let i=0; i<lingeringKeyboards.length; i++){
        const el = lingeringKeyboards[i];  
        el.parentElement.removeChild(el)
      }
    }
  }

  isChangePending:boolean;
  ngOnChanges(){
    this.processChange()
  }
  processChange(){
    if (!this.isViewInit){
      this.isChangePending = true;
    }
    else {
      this.isChangePending = false;
      if(this.isLocked && !this.isMathFieldLocked){
        this.isMathFieldLocked = true;
        let currentLatex = this.latexValue !== undefined ? this.latexValue : this.obj[this.prop];
        let staticMathFieldContainer = this.mathFieldRef.nativeElement;
        if (!currentLatex) currentLatex='';
        staticMathFieldContainer.innerHTML = '$$'+currentLatex+'$$';
        this.MathLive.renderMathInElement(staticMathFieldContainer);
      }
    }
  }

  isViewInit:boolean

  
  ngAfterViewInit() {
    this.updateMathField()
  }

  updateMathField() {
    const language = this.lang.c();
    const styleProfile = this.profile.getStyleProfile()[language].configuration.math;
    // console.log('mathFieldRef', this.mathFieldRef.nativeElement)
    this.MathLive = (window as any).MathLive;
    if (this.isMathFieldLocked) return;
    this.mathField =  this.MathLive.makeMathField(this.mathFieldRef.nativeElement, {
      smartMode: false,
      smartFence: styleProfile.smartFence,
      ignoreSpacebarInMathMode: false, 
      customVirtualKeyboardLayers: {
        grade6: keyboardSets[language].grade6,
        CAEC: keyboardSets[language].CAEC,
        grade9: keyboardSets[language].grade9,
        diploma: keyboardSets[language].diploma,
      },
      customVirtualKeyboards: {
        grade6: {layer: 'grade6'},
        CAEC: {layer: 'CAEC'},
        grade9: {layer: 'grade9'},
        diploma: {layer: 'diploma'},
      },
      virtualKeyboards: this.selectedOption,
      virtualKeyboardMode: this.isManualKeyboard ? 'manual' : 'onfocus',
      onKeystroke: (mf:any, keystroke:string, ev:{key:string, type:string}) => {
        if (ev.type === 'keydown'){
          // If we do not force these in, they do not appear.
          switch (ev.key){
            case '%':
            case '$':
              mf.$insert('\\'+ev.key);
              // mf.$insert(ev.key);
          }
        }
        return true;
      },
      onContentDidChange: mf => {
        let newLatex:string = this.mathField.$latex();
        let newLatexClean = newLatex.replace('#@', '\\placeholder{}');
        newLatexClean = newLatexClean.replace('\\imaginaryI', 'i')
        newLatexClean = newLatexClean.replace('\\exponentialE', 'e')

        if (newLatex !== newLatexClean){
          newLatex = newLatexClean;
          this.mathField.$latex(newLatexClean);
        }
        if (this.obj){
          if (this.prop && newLatex !== this.obj[this.prop]){
            this.obj[this.prop] = newLatex;
            if (!this.obj._changeCounter){ this.obj._changeCounter = 1; }
            else{ this.obj._changeCounter ++; }
          }
          if (this.latexValue !== undefined) {
            this.control.setValue(newLatex);
            this.latexValue = newLatex;
          }
          this.onChange.emit();
        }
      }
    });
    if (this.prop && this.obj[this.prop]){
      this.obj[this.prop] = this.mathField.$latex(this.obj[this.prop])
    }
    if (this.latexValue !== undefined) {
      this.latexValue = this.mathField.$latex(this.latexValue)
    }
    this.isViewInit = true
    if (this.isChangePending){
      this.processChange()
    }
    
    this.overrideToggleKeyboardTooltip();
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }

  getKeyboardProfile() {
    const styleProfile = this.profile.getStyleProfile()[this.lang.c()].configuration?.math;
    const keyboardProfile = styleProfile?.keyboardProfile

    if (!keyboardProfile) {
      return 'numeric'
    } else {
      return keyboardProfile
    }
  }

  setKeyboardProfile() {
    this.selectedOption = this.getKeyboardProfile()
  }

  /**
   * Method to replace the "Toggle Virtual Keyboard" tooltip for the mathlive input, so that it can 
   * display a French version as well.
   */
  overrideToggleKeyboardTooltip(){
    const mathLiveVirtualKeyboardToggleBtns = Array.from(document.getElementsByClassName('ML__virtual-keyboard-toggle'));
    mathLiveVirtualKeyboardToggleBtns.forEach(element => {
      if (element.getAttribute('data--command') === '"toggleVirtualKeyboard"')
        element.setAttribute('data-tooltip', this.lang.tra('mathlive_keyboard_toggle_tooltip'));
    })
  }
}
