import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgApexchartsModule } from 'ng-apexcharts';

import { PieGraphComponent } from './pie-graph/pie-graph.component';
import { LineGraphComponent } from './line-graph/line-graph.component';
import { BarGraphComponent } from './bar-graph/bar-graph.component';
import { LinearGaugeComponent } from './linear-gauge/linear-gauge.component';
import { GroupedBarGraphComponent } from './grouped-bar-graph/grouped-bar-graph.component';
import { StackedHorizontalBarGraphComponent } from './stacked-horizontal-bar-graph/stacked-horizontal-bar-graph.component';
import { ColumnGraphComponent } from './column-graph/column-graph.component';



@NgModule({
  declarations: [
    PieGraphComponent,
    LineGraphComponent,
    BarGraphComponent,
    LinearGaugeComponent,
    GroupedBarGraphComponent,
    StackedHorizontalBarGraphComponent,
    ColumnGraphComponent,
  ],
  imports: [
    CommonModule,
    NgxChartsModule,
    NgApexchartsModule,
  ],
  exports: [
    PieGraphComponent,
    LineGraphComponent,
    BarGraphComponent,
    LinearGaugeComponent,
    GroupedBarGraphComponent,
    StackedHorizontalBarGraphComponent,
    ColumnGraphComponent,
  ]
})
export class UiChartsModule { }
