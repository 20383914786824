import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'stacked-horizontal-bar-graph',
  templateUrl: './stacked-horizontal-bar-graph.component.html',
  styleUrls: ['./stacked-horizontal-bar-graph.component.scss']
})
export class StackedHorizontalBarGraphComponent implements OnInit {
  @Input() data: any[];
  multi = [
    {
      "name": "Germany",
      "series": [
        {
          "name": "2010",
          "value": 7300000
        },
        {
          "name": "2011",
          "value": 8940000
        }
      ]
    },
  ];
  
  view: any[] = [400, 100];

  // options
  showYAxis: boolean = true;
  gradient: boolean = false;

  colorScheme = {
    domain: ['#555555', '#D9D9D9']
  };

  constructor() {

  }

  ngOnInit(): void {
    
  }
}
