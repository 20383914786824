import { IFilterableListOption } from './model/type';
import { Component, forwardRef, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'filterable-dropdown',
  templateUrl: './filterable-dropdown.component.html',
  styleUrls: ['./filterable-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterableDropdownComponent),
      multi: true
    }
  ]
})
export class FilterableDropdownComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() options: IFilterableListOption[] = [];
  @Input() inputWidth: string = '14em';
  @Input() onLoadValue: IFilterableListOption;
  @Output() change = new EventEmitter<IFilterableListOption>();
  optionControl: FormControl = new FormControl();
  filteredOptions: Observable<IFilterableListOption[]>;
  selectedValue: string;

  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  ngOnInit() {
    this.filteredOptions = this.optionControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? this._filter(value) : this.options.slice())
        // map(value => this._filter(value))
      );
    if(this.onLoadValue){
      this.optionControl.setValue(this.onLoadValue);
    }
  }

  isOptionSelected(){
    const val = this.optionControl.value
    if (val && typeof val !== 'string'){
      return true
    }
    return false;
  }

  writeValue(obj: IFilterableListOption | null): void {
    if(this.onLoadValue == undefined){
      this.optionControl.setValue(obj ? obj.caption : '', { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
    this.optionControl.valueChanges.subscribe(value => {
      if (value && typeof value === 'object' && value.caption) {
        this.change.emit(value)
        fn(value);
      }
      else {
        this.change.emit(null)
        fn(null);
      }
    });
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
    // todo (something missing here)
  }

  displayFn(option: IFilterableListOption): string {
    return (option && option.caption) ? option.caption : '';
  }

  private _filter(value: string): IFilterableListOption[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.caption.toLowerCase().includes(filterValue));
  }
}
