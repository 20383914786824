
import { IQuestionConfig } from "../../../ui-testrunner/models";
import { IQuadrant, ITestletConstraint, TestletConstraintFunction, ITestletConstrain_VARIETY, ITestletConstraintCommon, ITestletConstraint_MATCH, ITestletConstrain_AVG } from "./assessment-framework";

export const clone = (t:any) =>  JSON.parse(JSON.stringify(t)) 

const arrPluck = (arr:any[]) => {
    let i = Math.floor(Math.random()*arr.length);
    return arr.splice(i, 1)[0];
}

export interface IItem {
  id: number,  
  label: string,
  meta: {
      [key: string]: string | number | boolean,
  }
}

export interface IBlock {
    items:IItem[],
    quality: number,
    stats?: {
      [key: string]: number,
    }
}

const removeEl = (el, arr:any[]) => {
  const i = arr.indexOf(el)
  if (i > -1){
    arr.splice(i, 1)
  }
}

export const generateTestlets = (questions:IItem[], quadrantConfig:IQuadrant, numTestlets:number, prevTestletsItems:string[]) => {
  if (numTestlets === 0){
    return [];
  }
  let blocks:IBlock[] = [];
  const blockSize = +quadrantConfig.config.numItems;
  const BLOCK_DISCARD_THRESHHOLD = quadrantConfig.config.discardThreshold/100; // proportion of weighted score before discarding
  const BLOCK_CREATION_PATIENCE = +quadrantConfig.config.triesBeforeFail; // number of consecutive failed block formations before giving up

  let totalRetries = 0;
  let itemsToPluck = clone(questions);
  let blockReattempts = 0;
  
  while (blockReattempts < BLOCK_CREATION_PATIENCE && itemsToPluck.length >= blockSize && blocks.length < numTestlets){
    // pluck items at random into block
    let block:IBlock = {items: [], quality: 0};
    let itemsToPluckForBlock = itemsToPluck.map(r => r);
    let isHardConstraintFailed = false;
    for (let i=0; i<blockSize; i++){
      let itemsToPluckForBlockOptim = itemsToPluckForBlock.map(r => r);
      filterToRequiredItems(itemsToPluckForBlockOptim, block.items, quadrantConfig.config.constraints); // optimization, but might be over-aggressive
      if (itemsToPluckForBlockOptim.length == 0){
        isHardConstraintFailed = true;
      }
      else{
        let item = arrPluck(itemsToPluckForBlockOptim);
        removeEl(item, itemsToPluckForBlock)
        block.items.push(item);
      }
    }
    // compute score
    let intraScore = measureIntraBlockScore(block.items, quadrantConfig.config.constraints);
    const itemIDs = block.items.map(q => +q.id).sort().join();
    if (isHardConstraintFailed){
      intraScore.score = 0;
    }
    if (prevTestletsItems.includes(itemIDs)){
      intraScore.score = 0;
    }
    // if score is above threshhold, keep the block, otherwise, discard and track re-attempts
    // console.log('>>> score', intraScore, BLOCK_DISCARD_THRESHHOLD)

    if (intraScore.score >= BLOCK_DISCARD_THRESHHOLD){
      block.quality = intraScore.score;
      block.stats = intraScore.stats;
      blocks.push(block);
      prevTestletsItems.push(itemIDs)
      // for (let item of block.items){
      //   removeEl(item, itemsToPluck)
      // }
    }
    else {
      totalRetries ++ ;
      blockReattempts ++;        
      if (itemsToPluck.length == blockSize){
        break; // early break if this was the only set of items to pluck from
      }
    }
  }

  return blocks;
}

const numStrToBool = (str) => {
  if (str === undefined){
    return false;
  }
  if (str === null){
    return false;
  }
  if (str === '0'){
    return false;
  }
  if (str === '1'){
    return true;
  }
  return str;
}

const matchCheckItemParam = (item:IItem, constraintConfigMatch:ITestletConstraint_MATCH) => {
  let val = (''+item.meta[constraintConfigMatch.param]).trim();
  if (numStrToBool(val) === numStrToBool(constraintConfigMatch.val)){
    return true;
  }
  return false;
}

const compareMeasure = (measure:number, val:number, constraintConfig:ITestletConstraintCommon) => {
    const sanitizedVal = 1*val;
    // console.log('compare', constraintConfig.isEqual, constraintConfig.isMax, measure, sanitizedVal)
    if (constraintConfig.isEqual){
      return (measure === sanitizedVal);
    }
    else if (constraintConfig.isMax){
        return (measure <= sanitizedVal);
    }
    else if (constraintConfig.isMin){
        return (measure >= sanitizedVal);
    }
    return false
}

const filterToRequiredItems = (itemsToPluckForBlock:IItem[], blockItems:IItem[], constraints:ITestletConstraint[]) => {
  // let itemsToPluckForBlock_cache = clone(itemsToPluckForBlock); // can use this to restore if the algo cuts too deep
  const minMatches = [];
  for (let constraint of constraints){
    switch (constraint.func){
      
      case TestletConstraintFunction.VARIETY:
        // check current instances of the value, if variety is not yet satisfied, exclude other options
        let constraintConfigVariety = <ITestletConstrain_VARIETY> constraint.config;
        const varietyCount = measureVarietyCount(blockItems, constraintConfigVariety);
        const param = constraintConfigVariety.param;
        const currentVarietyValues = [];
        blockItems.forEach(item => {
          let val = ''+item.meta[param];
          currentVarietyValues.push(val);
        });
        if (constraintConfigVariety.isEqual || constraintConfigVariety.isMax){
          if (varietyCount >= +constraintConfigVariety.count){
            removeItems(itemsToPluckForBlock, item => {
              let val = ''+item.meta[param];
              return !currentVarietyValues.includes(val);
            })
          }
        }
        if (constraintConfigVariety.isEqual || constraintConfigVariety.isMin){
          if (varietyCount < +constraintConfigVariety.count){
            removeItems(itemsToPluckForBlock, item => {
              let val = ''+item.meta[param];
              return currentVarietyValues.includes(val);
            })
          }
        }
        break;
      
      case TestletConstraintFunction.AVG:
        // if below the min average or above the max average, cut out all items in the opposite direction
        break;

      case TestletConstraintFunction.MATCH:
        // if eq, exclude items with prop
        const constraintConfigMatch = <ITestletConstraint_MATCH> constraint.config;
        let numMatch = 0;
        let maxMatch = -1;
        if (constraintConfigMatch.isEqual || constraintConfigMatch.isMax){
          maxMatch = +constraintConfigMatch.count
        }
        blockItems.forEach(item => {
          if (matchCheckItemParam(item, constraintConfigMatch)){
            numMatch ++;
          }
        });
        if (maxMatch>-1 && numMatch >= maxMatch){
          removeItems(itemsToPluckForBlock, item => matchCheckItemParam(item, constraintConfigMatch))
        }
        if (constraintConfigMatch.isEqual || constraintConfigMatch.isMin){
          const minMatch = +constraintConfigMatch.count;
          if (numMatch < minMatch){
            minMatches.push(constraintConfigMatch);
          }
        }
        break;
    }
    if (itemsToPluckForBlock.length == 0){
      console.error('Bottleneck', constraint);
      return;
    }
  }
  if (minMatches.length){
    removeItems(itemsToPluckForBlock, item => {
      let isMatch = false;
      minMatches.forEach(constraintConfigMatch => isMatch = isMatch || matchCheckItemParam(item, constraintConfigMatch))
      return !isMatch
    })
  }
}

const measureVarietyCount = (blockItems:IItem[], constraintConfigVariety:ITestletConstrain_VARIETY) => {
  let varietyCount = 0;
  let varietyRef:{[key: string]: boolean} = {};
  blockItems.forEach(item => {
    let val = <string>item.meta[constraintConfigVariety.param];
    if (!varietyRef[val]){
      varietyCount ++;
      if ( (val !== '') && (val !== null) && (val !== undefined)){
        varietyRef[val] = true;
      }
    }
  })
  return varietyCount
}

const removeItems = (items:IItem[], decision:(item:IItem) => boolean ) => {
  let i=0;
  while (i<items.length){
    if (decision(items[i])){
      items.splice(i, 1);
    }
    else {
      i++
    }
  }
}

const measureIntraBlockScore = (blockItems:IItem[], constraints:ITestletConstraint[]) => {
    let score = 0;
    let maxScore = 0;
    const stats:any = {}
    const problems:any = [];
    constraints.forEach(constraint => {
      let proportionEarned = 1;
      constraint.weight = 1;
      // use function to determine measure
      let isEarned = false;
      let currentStat;
      switch (constraint.func){
        case TestletConstraintFunction.VARIETY:
          let constraintConfigVariety = <ITestletConstrain_VARIETY> constraint.config;
          const varietyCount = measureVarietyCount(blockItems, constraintConfigVariety);
          currentStat = stats[constraintConfigVariety.param+'/'+constraint.func] = varietyCount;
          isEarned = compareMeasure(varietyCount, constraintConfigVariety.count, constraintConfigVariety);
          break;
        case TestletConstraintFunction.AVG:
          if (blockItems.length){
            const constraintConfigAvg = <ITestletConstrain_AVG> constraint.config;
            let cumul = 0;
            let tally = 0;
            blockItems.forEach(item => {
              let val = item.meta[constraintConfigAvg.param] || 0;
              let sanitizedVal =  1*(<number>val);
              cumul += 1*sanitizedVal;
              tally ++ ;
            })
            let avg = cumul/tally;
            currentStat = stats[constraintConfigAvg.param+'/'+constraint.func] = avg;
            isEarned = compareMeasure(avg, constraintConfigAvg.val, constraintConfigAvg);
          }
          break;
        case TestletConstraintFunction.MATCH:
          const constraintConfigMatch = <ITestletConstraint_MATCH> constraint.config;
          let numMatch = 0;
          blockItems.forEach(item => {
            if (matchCheckItemParam(item, constraintConfigMatch)){
              numMatch ++;
            }
          })
          currentStat = stats[constraintConfigMatch.param+'/'+constraint.func] = numMatch;
          isEarned = compareMeasure(numMatch, constraintConfigMatch.count, constraintConfigMatch);
          break;
      }
      // console.log('block', isEarned, constraint.config.param, constraint.func)
      // reflect earned score
      if (isEarned){
        score += constraint.weight; // proportionEarned * 
      }
      else{
        problems.push({constraint, currentStat})
      }
      maxScore += constraint.weight
    });
    let aggregateScore = 0;
    if (constraints.length === 0){
      aggregateScore = 1;
    }
    else{
      if (maxScore > 0){
        aggregateScore = score / maxScore
      }
    }
    return {stats, score:aggregateScore};
  }
