import { Component, OnInit, Input } from '@angular/core';
import { PageModalController } from '../page-modal.service';
import { LangService } from '../../core/lang.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { ItemSetPublishingCtrl } from 'src/app/ui-item-maker/item-set-editor/controllers/publishing';
import { IActiveModal } from '../modal/types.ts/model';

@Component({
  selector: 'modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss']
})
export class ModalFooterComponent implements OnInit {

  @Input() pageModal:PageModalController;
  @Input() activeModal?:IActiveModal;
  @Input() confirmationMessage:string = 'alert_kk_confirm_save';
  @Input() isConfirmAlert:boolean = false;
  @Input() confirmButton:boolean = true;
  @Input() closeMessage:string = 'btn_cancel'
  @Input() isEditDisable:boolean;
  // @Input() import:boolean;
  // @Input() export:boolean;


  constructor(
    private lang: LangService,
    private loginGuard: LoginGuardService,
  ) { }

  ngOnInit(): void {
  }

  confirmSubmission(){
    const currentModal = this.pageModal.getCurrentModal()
    if (this.isConfirmAlert){
      this.loginGuard.confirmationReqActivate({
        caption: this.lang.tra(this.confirmationMessage),
        confirm: () => this.pageModal.confirmModal()
      })
    }
    else {
      const isSuccessful = this.pageModal.confirmModal();
      if (!currentModal.isExplicitClose){
        this.pageModal.closeModal();
      }
    }
  }

  isSaving(){
    return this.pageModal.isSaving
  }

}
