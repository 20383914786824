import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IPanelLink } from './main-nav/panels/type';

@Injectable({
  providedIn: 'root'
})
export class SidepanelService {

  public isAccountLoaded:boolean = false;
  public forcedSub:Subject<boolean> = new Subject();
  public forcedPanels:IPanelLink[];
  private isVisible:boolean = false;
  private isMobileSlidein:boolean = false;
  private isExpanded:boolean = true;
  private isAccountDropupActive = false;

  classroomId:string; // temporary

  constructor() { }

  expand(){
    // console.log('expand')
    this.isExpanded = true;
  }
  unexpand(){
    // console.log('unexpand')
    this.isExpanded = false;
    this.collapseDropdown();
  }

  collapseDropdown() {
    this.isAccountDropupActive = false;
  }

  getAccountDropupActive() {
    return this.isAccountDropupActive && this.getExpandedStatus();
  }

  toggleAccountDropdown() {
    if (this.isAccountDropupActive){
      this.collapseDropdown();
    }
    else{
      this.isAccountDropupActive = true;
      this.expand();
    }
  }

  expandDropdown() {
    this.isAccountDropupActive = true;
  }

  activate(forcedPanelSet?:IPanelLink[]){
    this.forcedPanels = forcedPanelSet
    this.forcedSub.next(true)
    this.isVisible = true;
  }
  deactivate(){
    this.isVisible = false;
  }
  activateMobile(){
    this.isMobileSlidein = true;
  }
  deactivateMobile(){
    this.isMobileSlidein = false;
  }
  getExpandedStatus():boolean{
    return this.isVisible && this.isExpanded;
  }
  getVisibilityStatus():boolean{
    return this.isVisible;
  }
  getMobileVisibilityStatus():boolean{
    return this.isMobileSlidein;
  }

}
