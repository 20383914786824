import { Component, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  colors: string[];
};

@Component({
  selector: 'column-graph',
  templateUrl: './column-graph.component.html',
  styleUrls: ['./column-graph.component.scss']
})
export class ColumnGraphComponent implements OnInit {

  @Input() chartData: any;
  @Input() drillDown: any[];

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  isLoaded = false;
  isDrillDown = false;
  category = null;

  constructor() {
   
   }

  ngOnInit(): void {
    this.prepareChart();
  }

  showDefaultChart() {
    this.isDrillDown = false;
    this.category = null;
    this.prepareChart();
  }


  prepareChart(seriesIndex = 0) {
    this.isLoaded = false;
    if (this.isDrillDown) {
      this.chartOptions = {
        series: this.drillDown[seriesIndex][this.category].series,
        chart: {
          type: "bar",
          height: 500,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: this.drillDown[seriesIndex][this.category].categories,
          labels: {
            trim: true,
            rotate: 0
          }
        },
        yaxis: {
          title: {
            text: "% Mark"
          }
        },
        fill: {
          opacity: 1,
          colors: this.drillDown[seriesIndex][this.category].colors,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val}`;
            }
          }
        },
        legend: {
          show: false,
        },
      };
    }
    else {
      this.chartOptions = {
        series: this.chartData.series,
        chart: {
          type: "bar",
          height: 500,
          events: {
            dataPointSelection: (e, chart, opts) => {
              if (this.drillDown.length == 0) {
                return;
              }
              const seriesIndex = opts.seriesIndex;
              const dataIndex = opts.dataPointIndex;
              const series = opts.w.config.series;
              const categories = opts.w.config.xaxis.categories;
              this.category = categories[dataIndex];
              this.isDrillDown = true;
              this.prepareChart(seriesIndex)
            },
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: this.chartData.categories,
          labels: {
            trim: true,
            rotate: 0
          }
        },
        yaxis: {
          title: {
            text: "Number of Students"
          }
        },
        fill: {
          opacity: 1,
          colors: this.chartData.colors,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `${val}`;
            }
          }
        },
        legend: {
          show: false,
        },
      };
    }
    this.isLoaded = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartData) {
      this.isDrillDown = false;
      this.prepareChart();
    }
  }
}
