export enum WordView {
    DEFINITION = 'DEFINITION',
    THESAURUS = 'THESAURUS',
}

export interface ThesaurusEntry{
    searchWord: string[];
    data: ThesaurusDataEntry;
}

export interface ThesaurusDataEntry{
    grammaticalCategories: ThesaurusGramCatEntry[];
    phraseGroups: ThesaurusPhraseEntry[];
}

export interface ThesaurusGramCatEntry{
    speech?: any;                          // pospgrp
    senseCategories: ThesaurusSensCatEntry[]; // "in the sense of..."
    phraseGroups?: ThesaurusPhraseEntry[];
}
export interface ThesaurusSensCatEntry {
    definition: string;
    synonyms: string[];
    examples: string[];
    antonymns: string[];
}

export interface ThesaurusPhraseEntry{
    phrase: string;
    def?: string;
    synonyms: string[];
    examples: string[];
    antonymns: string[];
}