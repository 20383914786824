<ngx-charts-bar-vertical-2d
    *ngIf="isLoaded && colorScheme; else noColorScheme"
    [scheme]="colorScheme"
    [view]="width && height ? [width, height] : undefined"
    [results]="data"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xAxisLabel"
    [yAxisLabel]="yAxisLabel"
    [barPadding]="barPadding"
    (select)="onSelect($event)"
    [roundEdges]="false">
</ngx-charts-bar-vertical-2d>
<ng-template #noColorScheme>
    <ngx-charts-bar-vertical-2d
        *ngIf="isLoaded"
        [view]="width && height ? [width, height] : undefined"
        [results]="data"
        [gradient]="gradient"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        [barPadding]="barPadding"
        (select)="onSelect($event)"
        [roundEdges]="false">
    </ngx-charts-bar-vertical-2d>
</ng-template>