import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'linear-gauge',
  templateUrl: './linear-gauge.component.html',
  styleUrls: ['./linear-gauge.component.scss']
})
export class LinearGaugeComponent implements OnInit {
  @Input() width: number | null = null;
  @Input() height: number | null = null;
  @Input() value: number;
  @Input() benchmark: number;
  colorScheme = {
    domain: ['#9CC45C'],
  };

  constructor() { }

  ngOnInit(): void {
  }

  onSelect(event) {
    console.log(event);
  }
  test() {
    
  }

}
