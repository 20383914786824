<ng-container>    

    <div *ngIf="true" cdkDrag class="widget-display-container">

        <div cdkDragHandle class="dragHandle">
            <img *ngIf="this.lang.getCurrentLanguage() === 'en'" class="sourceLogo" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Collins-logo-black/1701786840490/Collins-logo-black.png">
            <img *ngIf="this.lang.getCurrentLanguage() === 'fr'" class="sourceLogo" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Logo-larousse-seul-blanc-1024x121/1702477671027/Logo-larousse-seul-blanc-1024x121.png">
            <i class="fa fa-bars" style="margin: 0.5em"></i>
        </div>

        <div *ngIf="this.lang.getCurrentLanguage() === 'en'">
            <div class="container">
                <div class="searchInput" [class.active]="showSearchInput">
                    <input type="text" [(ngModel)]="input" (keyup)="onUserInput($event)" placeholder="Enter a word..">
                    <div *ngIf="showSearchInput" class="resultBox">
                        <li *ngIf="isLoadingSuggestions" class="loadingDotsContainer">
                            <div class="dot-pulse"></div>
                        </li>
                        <li *ngIf="suggestions && suggestions.length == 0 && !dictionaryManager.selectedWord" class="loadingDotsContainer">
                            <tra slug="dictionary_no_word_found"></tra>
                        </li>
                        <div *ngIf="suggestions && suggestions.length > 0" class="">
                            <li *ngFor="let suggestion of suggestions" (click)="selectWord(suggestion)"><markdown-inline [input]="suggestion"></markdown-inline></li>
                        </div>
                    </div>
                    <button class="icon" (click)="getSuggestions(currentUserInput)">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                <div class="main-container" *ngIf="dictionaryManager.selectedWord && dictionaryManager.selectedWord.isActive">
                    <main class="dirctionary_content">
                        <div class="tabs">
                            <ul>
                                <li 
                                    *ngFor="let tab of wordViews" 
                                    [class.is-active]="isActiveId(tab.id)"
                                    (click)="selectWordView(tab.id)"
                                >
                                    <a>
                                        <span><tra [slug]="tab.caption"></tra></span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div *ngIf="isViewingDefinition()">
                            <section class="section" *ngFor="let def of currentWordDefinition; let i = index">
                                <dl class="definition">
                                    <dt class="dictionary_panel definition__term">
                                        <h1>
                                            <span class="target-word">
                                                <markdown-inline [input]="dictionaryManager.selectedWord.word"></markdown-inline>
                                                <sup *ngIf="currentWordDefinition.length > 1">{{i + 1}}</sup>
                                            </span>
                                        </h1>
                                    </dt>
                                    <dd class="dictionary_panel definition__description">
                                        <!-- <p class="definition__text">a <a href="#">dictionary</a> of <a href="#">{{selectedWord.word}}</a> and <a href="#">definitions</a> listed in beautiful harmony</p> -->
                                            
                                        <div *ngFor="let defEntry of def; let index = index">
                                            <p class="definition__text" > 
                                                <b>{{index + 1}}: &nbsp;</b>
                                                <markdown-inline [input]="dictionaryManager.getDefEntryDefData(defEntry)"></markdown-inline>
                                            </p>
                                            <br>
                                            <div *ngIf="dictionaryManager.getDefEntryExampleData(defEntry)">
                                                <div *ngFor="let example of dictionaryManager.getDefEntryExampleData(defEntry)">
                                                    <blockquote class="definition__quote">
                                                        <p class="definition__example">
                                                            <markdown-inline [input]="dictionaryManager.highlightTargetWord(example)"></markdown-inline>
                                                        </p>
                                                        <ul class="tags">
                                                        </ul>
                                                    </blockquote>
                                                </div>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                            </section>
                        </div>

                        <div *ngIf="isViewingThesaurus()">
                            <div *ngIf="!currentSelectedWordHasThesaurus()" class="no_data_msg_container">
                                There is no Thesaurus entry for this word.
                            </div>
                            <div *ngIf="currentSelectedWordHasThesaurus()">
                                <div *ngFor="let word_thesaurus_entry of dictionaryManager.selectedWord.thesaurus_json">
                                    <section class="section">
                                        <dl>
                                            <dt class="dictionary_panel definition__term">
                                                <h1>
                                                    <span class="target-word">
                                                        <markdown-inline [input]="dictionaryManager.selectedWord.word"></markdown-inline>
                                                    </span>
                                                </h1>
                                            </dt>
                                            <dd *ngFor="let gramcat of getGrammaticalCategories(word_thesaurus_entry)" 
                                                class="dictionary_panel thesaurus_gramcat_container"
                                            >
                                                <div *ngFor="let senseCat of gramcat.senseCategories; let i = index" 
                                                    class="thesaurus_senscat_container"
                                                    [class.is-not-first]="i > 0">
        
                                                    <div *ngIf="senseCat.phrase" class="sense_cat_phrase_title">
                                                        <h1>
                                                            <span class="target-word">
                                                                <markdown-inline [input]="senseCat.phrase"></markdown-inline>
                                                            </span>
                                                        </h1>
                                                    </div>
    
                                                    <div *ngIf="senseCat.definition || senseCat.def" class="thesaurus_definition_container">
                                                        <div>
                                                            <p class="thesaurus_definition_title">Definition</p>
                                                        </div>
                                                        <div>
                                                            <markdown-inline [input]="senseCat.definition ? senseCat.definition : senseCat.def"></markdown-inline>
                                                        </div>
                                                    </div>
            
                                                    <div *ngFor="let example of senseCat.examples" class="thesaurus_example">
                                                        <markdown-inline [input]="example"></markdown-inline>
                                                    </div>
                                                    <!-- Display synonyms and opposite words if available -->
                                                    <div>
                                                        <div *ngIf="senseCat.synonyms && senseCat.synonyms.length > 0" class="thesaurus_synonymes_container">
                                                            <p class="thesaurus_synonymes_title"><tra slug="dictionary_synonyms"></tra></p>
                                                            <ul class="thesaurus_synonymes_content_list">
                                                                <li *ngFor="let syn of senseCat.synonyms; let i = index" 
                                                                    class="thesaurus_synonymes_content_item"
                                                                    [class.is_syn_first]="i == 0"
                                                                >
                                                                    <markdown-inline [input]="syn"></markdown-inline>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div *ngIf="senseCat.antonymns && senseCat.antonymns.length > 0" class="thesaurus_antonymns_container" >
                                                            <p class="thesaurus_antonymns_title"><tra slug="dictionary_antonyms"></tra></p>
                                                            <ul class="thesaurus_antonymns_content_list">
                                                                <li *ngFor="let ant of senseCat.antonymns; let i = index"
                                                                    class="thesaurus_antonymns_content_item"
                                                                    [class.is_syn_first]="i == 0"
                                                                >
                                                                    <markdown-inline [input]="ant"></markdown-inline>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dd>
        
                                            <div *ngFor="let phraseGroup of getPhraseGroups(word_thesaurus_entry)">
                                                <dt class="dictionary_panel definition__term">
                                                    <h1>
                                                        <span class="target-word">
                                                            <markdown-inline [input]="phraseGroup.phrase"></markdown-inline>
                                                        </span>
                                                    </h1>
                                                </dt>
                                                <dd class="dictionary_panel thesaurus_phrases_container">
                                                    <div class="thesaurus_phrase_container">
                                                        <div *ngIf="phraseGroup.def" class="thesaurus_definition_container">
                                                            <div>
                                                                <p class="thesaurus_definition_title">Definition</p>
                                                            </div>
                                                            <div>
                                                                <span>{{phraseGroup.def}}</span>
                                                            </div>
                                                        </div>
                                                        
                                                        <div *ngFor="let example of phraseGroup.examples" class="thesaurus_example">
                                                            <markdown-inline [input]="example"></markdown-inline>
                                                        </div>
                                                        <!-- Display synonyms and opposite words if available -->
                                                        <div>
                                                            <div *ngIf="phraseGroup.synonyms && phraseGroup.synonyms.length > 0" class="thesaurus_synonymes_container">
                                                                <p class="thesaurus_synonymes_title">Synonymes</p>
                                                                <ul class="thesaurus_synonymes_content_list">
                                                                    <li *ngFor="let syn of phraseGroup.synonyms; let i = index" 
                                                                        class="thesaurus_synonymes_content_item"
                                                                        [class.is_syn_first]="i == 0"
                                                                    >
                                                                        <markdown-inline [input]="syn"></markdown-inline>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div *ngIf="phraseGroup.antonymns && phraseGroup.antonymns.length > 0" class="thesaurus_antonymns_container" >
                                                                <p class="thesaurus_antonymns_title">Contraires</p>
                                                                <ul class="thesaurus_antonymns_content_list">
                                                                    <li *ngFor="let ant of phraseGroup.antonymns; let i = index"
                                                                        class="thesaurus_antonymns_content_item"
                                                                        [class.is_syn_first]="i == 0"
                                                                    >
                                                                        <markdown-inline [input]="ant"></markdown-inline>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </dd>
                                            </div>
                                        </dl>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
        <div *ngIf="this.lang.getCurrentLanguage() === 'fr'">
            <div class="container">
                <div class="searchInput" [class.active]="showSearchInput">
                    <input type="text" [(ngModel)]="input" (keyup)="onUserInput($event)" placeholder="Entrez un mot..">
                    <div *ngIf="showSearchInput" class="resultBox">
                        <li *ngIf="isLoadingSuggestions" class="loadingDotsContainer">
                            <div class="dot-pulse"></div>
                        </li>
                        <li *ngIf="suggestions && suggestions.length == 0 && !dictionaryManager.selectedWord" class="loadingDotsContainer">
                            <tra slug="dictionary_no_word_found"></tra>
                        </li>
                        <div *ngIf="suggestions && suggestions.length > 0">
                            <li *ngFor="let suggestion of suggestions" (click)="selectWord(suggestion)"><markdown-inline [input]="suggestion"></markdown-inline></li>
                        </div>
                    </div>
                    <button class="icon" (click)="getSuggestions(currentUserInput)">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                <div class="main-container" *ngIf="dictionaryManager.selectedWord && dictionaryManager.selectedWord.isActive">
                    <main class="dirctionary_content">
                        <!-- List and display all definition entries for the word -->
                        <section class="section" *ngFor="let def of currentWordDefinition; let i = index">
                            <dl class="definition">
                                <dt class="dictionary_panel definition__term">
                                    <h1>
                                        <span class="target-word" *ngIf="!getDefPhrase_FR(def)">
                                            <markdown-inline [input]="dictionaryManager.selectedWord.word"></markdown-inline>
                                            <sup *ngIf="currentWordDefinition.length > 1">{{i + 1}}</sup>
                                        </span>
                                        <div class="catgram-definition-container" *ngIf="i == 0">
                                            <p class="catgram-definition">{{dictionaryManager.getCatgramDefinition()}}</p>
                                        </div>
                                        <span class="target-word" *ngIf="getDefPhrase_FR(def)">
                                            <markdown-inline [input]="getDefPhrase_FR(def)"></markdown-inline>
                                        </span>
                                    </h1>
                                </dt>
                                <dd class="dictionary_panel definition__description">
                                    <!-- Sometimes there can be multiple definition under the same definition entry -->
                                    <div class="definition_container">
                                        <div *ngFor="let defEntry of dictionaryManager.getSelectedWordDefinitionContent(def); let index = index">
                                            <p class="definition__text"> 
                                                <b>{{index + 1}}: &nbsp;</b>
                                                <markdown-inline [input]="defEntry"></markdown-inline>
                                            </p>
                                        </div>
                                    </div>

                                    <!-- Display example sentence -->
                                    <div *ngIf="dictionaryManager.getDefEntryExampleData(def).length > 0">
                                        <div *ngFor="let example of dictionaryManager.getDefEntryExampleData(def); let i = index">
                                            <blockquote class="definition__quote" 
                                                [class.definition__quote_multi_first]="i == 0" 
                                                [class.definition__quote_multi_last]="i == dictionaryManager.getDefEntryExampleData(def).length - 1"
                                            >
                                                <p class="definition__example">
                                                    <markdown-inline [input]="dictionaryManager.highlightTargetWord(example)"></markdown-inline>
                                                </p>
                                            </blockquote>
                                        </div>
                                    </div>

                                    <!-- Display synonyms and opposite words if available -->
                                    <div *ngIf="isShowSynonyms && dictionaryManager.getDefEntrySynonymOpposites(def).length > 0">
                                        <div *ngIf="dictionaryManager.getDefEntrySynonymDisplay(def).length > 0" class="fr_synonymes_container">
                                            <p class="fr_synonymes_title"><tra slug="dictionary_synonyms"></tra></p>
                                            <ul class="fr_synonymes_content_list">
                                                <li *ngFor="let syn of dictionaryManager.getDefEntrySynonymDisplay(def); let i = index" 
                                                    class="fr_synonymes_content_item"
                                                    [class.is_syn_first]="i == 0"
                                                >
                                                    <markdown-inline [input]="syn"></markdown-inline>
                                                </li>
                                            </ul>
                                        </div>
                                        <div *ngIf="dictionaryManager.getDefEntryOppositeDisplay(def).length > 0" class="fr_synonymes_container">
                                            <p class="fr_synonymes_title"><tra slug="dictionary_antonyms"></tra></p>
                                            <ul class="fr_synonymes_content_list">
                                                <li *ngFor="let syn of dictionaryManager.getDefEntryOppositeDisplay(def); let i = index" 
                                                    class="fr_synonymes_content_item"
                                                    [class.is_syn_first]="i == 0"
                                                >
                                                    <markdown-inline [input]="syn"></markdown-inline>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </dd>
                            </dl>
                        </section>
                    </main>     
                </div>
            </div>
        </div>
    </div>
</ng-container>
